import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../Loader/loader";

const ManageSubscription = () => {
  const [features, setFeatures] = useState();
  const [data, setData] = useState([]);
  const [listPageData, setListPageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  const [status, setStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getWebPages();
  }, []);

  const getWebPages = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/get-subscrpton-plan-cms`,   
     { headers: {
        Authorization: `Bearer ${token}`,
      }}
      );
      const pricing_plan = JSON.parse(JSON.stringify(response.data));
      setData(pricing_plan.data);
      let features = pricing_plan.data.map((i, index) => {
        let data = i.offred_module.map((item) => {
          let key = Object.keys(item);
          let refilter_array = item[key].map((re_item) => {
            return re_item;
          });
          return `${key}:- ${refilter_array}`;
        });
        return data;
      });
      setFeatures(features);
      console.log(features, "features");

      //   dispatch(create_plan({plan:pricing_plan.data.plan_list}));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    setToken(localStorage.getItem("cms_token"));
    loadContent();
  }, []);

  const loadContent = async () => {
    setIsLoading(true);
    await axios
      .get(`${API_BASE_URL}/get-inquiry`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setListPageData(response.data.data.reverse());
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handlePositive = (id) => {
    const result = window.confirm(
      "Do you want to make this enquiry positive ?"
    );

    if (result) {
      setIsLoading(true);

      axios
        .get(`${API_BASE_URL}/pasitive-enquiry/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === "200") {
            toast.success("Enquiry Added To Positive List successfully");
            setIsLoading(false);

            axios
              .get(`${API_BASE_URL}/list-enquiry`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((response) => {
                setListPageData(response.data.result.reverse());
              })
              .catch((error) => {
                setIsLoading(false);
                console.log(error);
              });
          }
        });
    }
  };

  const handleNegative = (id) => {
    const result = window.confirm(
      "Do you want to make this enquiry Negative ?"
    );

    if (result) {
      axios
        .get(`${API_BASE_URL}/negative-enquiry/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === "200") {
            toast.success("Enquiry Added To Negative List successfully");
            axios
              .get(`${API_BASE_URL}/list-enquiry`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((response) => {
                setListPageData(response.data.result.reverse());
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    }
  };

  const handleDelete = (id) => {
    const result = window.confirm("Do you want to delete ?");
let data = {
  id: id,
  delete_for:"subscription_plans"
}
    if (result) {
      axios
        .post(`${API_BASE_URL}/delete-tabuler-data`,data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            toast.success("Delete successully");
          getWebPages();
          }
        });
    }
  };

  const handleActive = async (status,id) => {
    const res = await axios.post(
      `${API_BASE_URL}/manage-subscription-plan-status`,
      { status: status ? 0 : 1, plan_id: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const response = JSON.parse(JSON.stringify(res));
    console.log(response);
    if (response.data.status === 200) {
      getWebPages();
      if (status == 1) {
        toast.success("Active successfully");
      } else {
        toast.success("Inactive successfully");
      }
    }
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Manage Subscription Plan </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item active">Manage Plan</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex text-end mb-2">
              <div className="col-lg-12 mb-2 ">
                <Link
                  to={"/cms/managePlan/add-subscription-plan"}
                  data-repeater-create
                  type="button"
                  className="btn btn-success inner"
                  value="Add Number"
                >
                  Add Subscription Plan
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">
                      Plan List ({data?.length})
                    </h4>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <div className="table-responsive">
                        <table className="table align-middle table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th className="align-middle">Name</th>
                              <th className="align-middle">Monthly Price</th>
                              <th className="align-middle">Annualy Price</th>
                            
                              <th className="align-middle">Status</th>
                              <th className="align-middle">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.map((item) => (
                              <>
                                <tr>
                                  <td>{item.name}</td>
                                  <td>{item.monthly_price}</td>
                                  <td>{item.annual_price}</td>
                                  <td>
                                    {item.status == 1 ? (
                                      <span className="badge badge-pill badge-soft-success font-size-11   ">
                                        Active
                                      </span>
                                    ) : item.status == 0 ? (
                                      <span className="badge badge-pill badge-soft-danger font-size-11">
                                        Inactive
                                      </span>
                                    ) : (
                                      <span className="badge badge-pill badge-soft-warning font-size-11">
                                        Pending
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                  <Link
                                    to={`/cms/managePlan/edit-subscription-plan/${item.id}`}
                                    type="button"
                                    className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                                  >
                                    <i className="fa fa-pen"></i>
                                  </Link>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm btn-rounded waves-effect waves-light ms-2"
                                    onClick={() => {
                                      handleDelete(item.id);
                                    }}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>

                                  <button
                                    type="button"
                                    className="btn btn-warning btn-sm btn-rounded  ms-2"
                                    onClick={() => {
                                      setStatus(item.status === 0 ? 1 : 0);
                                      handleActive(item.status, item.id);
                                    }}
                                  >
                                    <i className="fa fa-ban"></i>
                                  </button>
                                </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageSubscription;
