import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../Loader/loader";

const CompanyInformation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(false);

  const [imagePreview, setImagePreview] = useState(null);
  const [footerPreview, setFooterPreview] = useState(null);
  const [feviconPreview, setFeviconPreview] = useState(null);

  const [image, setImage] = useState();
  const [managePageData, setManagePageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  const [formData, setFormData] = useState({
    company_name: "",
    website: "",
    mobile: "",
    mobile_sec: "",
    email: "",
    alternate_email: "",
    address: "",
    logo_title: "",
    facebook_link: "",
    twitter_link: "",
    linked_In_link: "",
    g_link: "",
    service_tax_no: "",
    cin_no: "",
    gst_no: "",
    sac_no: "",
    account_details: "",
    smtp_email_id: "",
    smtp_password: "",
    logo: "",
    short_description:"",
    id:"",
    footer_logo:"",
    fevicon_icon:"",
  });

  const {
    company_name,
    website,
    mobile,
    mobile_sec,
    email,
    alternate_email,
    address,
    logo_title,
    facebook_link,
    twitter_link,
    linked_In_link,
    g_link,
    service_tax_no,
    cin_no,
    gst_no,
    sac_no,
    account_details,
    smtp_email_id,
    smtp_password,
    short_description,
    logo,
    id,
    footer_logo,
    fevicon_icon,
  } = formData;

  const validateForm = () => {
    let errors = {};
    // check for empty values

    if (!formData.company_name) {
      errors.company_name = " Company Name is required";
    }
    if (!formData.website) {
      errors.website = "Website name is required";
    }
    if (!formData.mobile) {
      errors.mobile = "phone no is required";
    }

    return errors;
  };



  const navigate = useNavigate();


  let hasError = false;

  useEffect(() => {
    setToken(localStorage.getItem("cms_token"));
    ViewCompanyProfile();
  }, []);

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const ViewCompanyProfile = async () => {
    setIsLoading(true)
    const res = await axios.get(`${API_BASE_URL}/get-company-profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }, 
    });
    console.log(res, "res");
    if (res.data.status == 200) {
      setFormData({
        ...formData,
        company_name: res?.data.data.company_name,
        website: res?.data.data.website,
        mobile: res?.data.data.mobile,
        mobile_sec: res?.data.data.mobile_sec,
        email: res?.data.data.email,
        alternate_email: res?.data.data.alternate_email,
        address: res?.data.data.address,
        logo_title: res?.data.data.logo_title,
        facebook_link: res?.data.data.facebook_link,
        twitter_link: res?.data.data.twitter_link,
        linked_In_link: res?.data.data.linked_In_link,
        g_link: res?.data.data.g_link,
        service_tax_no: res?.data.data.service_tax_no,
        cin_no: res?.data.data.cin_no,
        gst_no: res?.data.data.gst_no,
        sac_no: res?.data.data.sac_no,
        account_details: res?.data.data.account_details,
        smtp_email_id: res?.data.data.smtp_email_id,
        smtp_password: res?.data.data.smtp_password,
        short_description:res?.data.data.short_description,
        logo: res?.data.data.logo,
        footer_logo: res?.data.data.footer_logo,
        fevicon_icon: res?.data.data.favicon_icon,
        id:res?.data.data.id
      });
      setImagePreview(res?.data.data.logo);
      setFooterPreview(res?.data.data.footer_logo);
      setFeviconPreview(res?.data.data.favicon_icon);
      setIsLoading(false)
    }
    else{
      setIsLoading(false)
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm();
    console.log(formData, "form");
    console.log(errors, "err");


    if (Object.keys(errors).length === 0) {
      
  const formData = new FormData();
    formData.append("company_name", company_name);
    formData.append("website", website);
    formData.append("mobile", mobile);
    formData.append("mobile_sec", mobile_sec);
    formData.append("email", email);
    formData.append("alternate_email", alternate_email);
    formData.append("address", address);
    formData.append("logo_title", logo_title);
    formData.append("twitter_link", twitter_link);
    formData.append("linked_In_link", linked_In_link);
    formData.append("gpluse_link", g_link);
    formData.append("service_tax_no", service_tax_no);
    formData.append("cin_no", cin_no);
    formData.append("gst_no", gst_no);
    formData.append("sac_no", sac_no);
    formData.append("account_detail", account_details);
    formData.append("smtp_email_id", smtp_email_id);
    formData.append("smtp_password", smtp_password);
    formData.append("short_description", short_description);
    formData.append("footer_logo", footer_logo);
    formData.append("fevicon_icon", fevicon_icon);
    formData.append("logo", logo);
    formData.append("id", id);
    console.log(formData, "formdata");

      axios
        .post(`${API_BASE_URL}/update-company-profile`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status === 200) {
            navigate("/cms/companyinformation");
            toast.success("Updated successfully");     
          } 
        })                                                                                                                                    
        .catch((error) => {
          setErrors(error.response.data.result);
          console.log(error);
        });
    } else {
      setErrors(errors);
      console.log(errors);
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };

  const handleFooterChange = (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setFooterPreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setFooterPreview(objectUrl);
  };

  const handleFeviconChange = (e) => {
    const selectedImage = e.target.files[0];
    console.log(e.target.files[0],"e.target.files[0]")
    if (!selectedImage) {
      setFeviconPreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setFeviconPreview(objectUrl);
  };

  console.log("valid")
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    if (name === "logo") {
      console.log(event.target.files[0],"value")
      setFormData({ ...formData, [name]: event.target.files[0] });
      const selectedImage = event.target.files[0];
      if (!selectedImage) {
        setImagePreview(null);
        return;
      }
      const objectUrl = URL.createObjectURL(selectedImage);
      setImagePreview(objectUrl);
    }
    if (name === "footer_logo") {
      console.log(event.target.files[0],"footer_logo")
      setFormData({ ...formData, [name]: event.target.files[0] });
      const selectedImage = event.target.files[0];
      if (!selectedImage) {
        setFooterPreview(null);
        return;
      }
      const objectUrl = URL.createObjectURL(selectedImage);
      setFooterPreview(objectUrl);
    }
    if (name === "fevicon_icon") {

      console.log(event.target.files[0],"favicon")
      setFormData({ ...formData, [name]: event.target.files[0] });
      const selectedImage = event.target.files[0];
      if (!selectedImage) {
        setFeviconPreview(null);
        return;
      }
      const objectUrl = URL.createObjectURL(selectedImage);
      setFeviconPreview(objectUrl);
    }
  };

  
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Company Information </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/cms/managecompanyprofile"}>
                          Company Information
                        </Link>
                      </li>

                      <li className="breadcrumb-item active">
                        Add Company Information
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between text-center">
                      <h4 className="card-title mb-4">
                        Add Company Information
                      </h4>
                      <button 
                      style={{borderRadius:'6px', height:'30px'}} 
                      class="btn btn-success btn-sm" 
                      type="button" 
                      data-toggle="tooltip" 
                      onClick={()=>setEdit(true)} 
                      data-placement="top" 
                      title="Edit"
                      >
                        <span style={{marginRight:'5px'}}>edit</span>
                        <i class="fa fa-edit"></i>
                        </button>
                      </div>
                      <form onSubmit={handleSubmit}>
                      {isLoading ? (
                      <Loader />
                    ) : (
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="row">
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    Company Name
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="company_name"
                                      name="company_name"
                                      type="text"
                                      value={company_name}
                                      className={`form-control  ${
                                        errors?.company_name && "is-invalid"
                                      }`}
                                      placeholder="Enter Company Name"
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                    {errors?.company_name && (
                                      <div className="invalid-feedback">
                                        {errors?.company_name}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    Website
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="website"
                                      name="website"
                                      type="text"
                                      value={website}
                                      className={`form-control  ${
                                        errors?.website && "is-invalid"
                                      }`}
                                      placeholder="Enter Website Name"
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                    {errors?.website && (
                                      <div className="invalid-feedback">
                                        {errors?.website}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    Phone Number
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="phone_number"
                                      name="mobile"
                                      type="text"
                                      value={mobile}
                                      className={"form-control "}
                                      placeholder="Enter Phone Number"
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    Alternate Number
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="alternate_number"
                                      name="mobile_sec"
                                      type="text"
                                      value={mobile_sec}
                                      className={"form-control "}
                                      placeholder="Enter Alternate Number"
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    Email Address
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="email_address"
                                      name="email"
                                      type="text"
                                      value={email}
                                      className={"form-control "}
                                      placeholder="Enter Email Address"
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    Alternate E-Mail
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="alternate_email"
                                      name="alternate_email"
                                      type="text"
                                      value={alternate_email}
                                      className={"form-control "}
                                      placeholder="Enter Alternate Email"
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    India Address
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="india_address"
                                      name="address"
                                      type="text"
                                      value={address}
                                      className={"form-control "}
                                      placeholder="Enter India Address"
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    Logo Tittle
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="logo_tittle"
                                      name="logo_title"
                                      type="text"
                                      value={logo_title}
                                      className={"form-control "}
                                      placeholder="Enter Logo Tittle"
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    /> 
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label 
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    Facebook Link
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="facebook_link"
                                      name="facebook_link"
                                      type="text"
                                      value={facebook_link}
                                      className={"form-control "}
                                      placeholder="Enter Facebook Link"
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    Twitter Link
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="twitter_link"
                                      name="twitter_link"
                                      type="text"
                                      value={twitter_link}
                                      className={"form-control "}
                                      placeholder="Enter Twitter Link"
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    Linked In Link
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="linkedin_link"
                                      name="linked_In_link"
                                      type="text"
                                      value={linked_In_link}
                                      className={"form-control "}
                                      placeholder="Enter LinkedIn Link"
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    G+ Link
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="g_link"
                                      name="g_link"
                                      type="text"
                                      value={g_link}
                                      className={"form-control "}
                                      placeholder="Enter G+ Link"
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    Service Tax No.
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="service_tax_no"
                                      name="service_tax_no"
                                      type="text"
                                      value={service_tax_no}
                                      className={"form-control "}
                                      placeholder="Enter Service Tax No."
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    CIN No.
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="app_store_link"
                                      name="cin_no"
                                      type="text"
                                      value={cin_no}
                                      className={"form-control "}
                                      placeholder="Enter CIN No."
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    GST No.
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="linkedin_link"
                                      name="gst_no"
                                      type="text"
                                      value={gst_no}
                                      className={"form-control "}
                                      placeholder="Enter GST No."
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    SAC No.
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="g_link"
                                      name="sac_no"
                                      type="text"
                                      value={sac_no}
                                      className={"form-control "}
                                      placeholder="Enter SAC No."
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    A/C Detail
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="service_tax_no"
                                      name="account_details"
                                      type="text"
                                      value={account_details}
                                      className={"form-control "}
                                      placeholder="Enter A/C Detial"
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                              <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-3"
                              >
                                Short Description
                              </label>
                              <div className="col-lg-9">
                                    <input
                                      id="g_link"
                                      name="short_description"
                                      type="text"
                                      value={short_description}
                                      className={"form-control "}
                                      placeholder="Enter ."
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                  </div>
                            </div>
                            </div>
                            {/* <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-3"
                              >
                                Service E-Mail Send
                              </label>
                              <div className="col-lg-9">
                                <input
                                  id="app_store_link"
                                  name="app_store_link"
                                  type="checkbox"
                                  value={app_store_link}
                                  className={
                                    appStoreError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  onChange={handleInputChange}
                                />
                                {appStoreError && (
                                  <div className="text-danger">
                                    {appStoreError}
                                  </div>
                                )}
                              </div>
                            </div> */}
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    SMTP E-Mail ID
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="g_link"
                                      name="smtp_email_id"
                                      type="text"
                                      value={smtp_email_id}
                                      className={"form-control "}
                                      placeholder="Enter Copyright"
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    SMTP Password
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="service_tax_no"
                                      name="smtp_password"
                                      type="text"
                                      value={smtp_password}
                                      className={"form-control "}
                                      placeholder="Enter SMTP Password"
                                      onChange={handleInputChange}
                                      disabled={!edit}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    Logo
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      className={
                                        errors?.logo
                                          ? "form-control is-invalid"
                                          : "form-control "
                                      }
                                      name="logo"
                                      type="file"
                                      accept="image/*"
                                      disabled={!edit}
                                      onChange={(e) => {
                                        handleImageChange(e);
                                        handleInputChange(e);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                              <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    Footer Logo
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      className={
                                        errors?.footer_logo
                                          ? "form-control is-invalid"
                                          : "form-control "
                                      }
                                      name="footer_logo"
                                      type="file"
                                      accept="image/*"
                                      disabled={!edit}
                                      onChange={(e) => {
                                        handleFooterChange(e);
                                        handleInputChange(e);
                                      }}
                                    />
                                  </div>
                                </div>

                              </div>
                              <div className="col-6">
                                <div className="col-md-12">
                                  <div className="mt-4 mt-20 d-flex justify-content-center">
                                    <div>
                                      {imagePreview && (
                                        <img
                                          src={imagePreview}
                                          alt="Preview"
                                          width="200"
                                          height="200"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="col-md-12">
                                  <div className="mt-4 mt-20 d-flex justify-content-center">
                                    <div>
                                      {footerPreview && (
                                        <img
                                          src={footerPreview}
                                          alt="Preview"
                                          width="200"
                                          height="200"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                              <div className="form-group row mb-4">
                                  <label
                                    for="taskname"
                                    className="col-form-label col-lg-3"
                                  >
                                    Fevicon Logo
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      className={
                                        errors?.fevicon_icon
                                          ? "form-control is-invalid"
                                          : "form-control "
                                      }
                                      name="fevicon_icon"
                                      type="file"
                                      accept="image/*"
                                      disabled={!edit}
                                      onChange={(e) => {
                                        handleFeviconChange(e);
                                        handleInputChange(e);
                                      }}
                                    />
                                  </div>
                                </div>

                              </div>
                              <div className="col-6">

                              </div>
                              <div className="col-6">
                                <div className="col-md-12">
                                  <div className="mt-4 mt-20 d-flex justify-content-center">
                                    <div>
                                      {feviconPreview && (
                                        <img
                                          src={feviconPreview}
                                          alt="Preview"
                                          width="200"
                                          height="200"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>)}

                       {edit &&
                       (<div className="row justify-content-end mt-3">
                          <div className="col-lg-10 d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary">
                             Update Information
                            </button>
                          </div>
                        </div>)}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyInformation;
