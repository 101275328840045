import React, { useState, useEffect } from "react";
import api from "../components/cms/api";
import axios from "axios";
import { API_BASE_URL } from "../components/config";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Navbar from "./Layouts/Navbar";
import LeftSideBar from "./Layouts/LeftSideBar";
import Footer from "./Layouts/Footer";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

const Dashboard = () => {
  const navigate = useNavigate();
  const [listPageData, setListPageData] = useState();
  const [mangeSlider, setMangeSlider] = useState();
  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  const [seoPage, setSeoPage] = useState();
  const [faqPage, setFaqPage] = useState();
  const [dashboardData, setDashboardData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [freeUserList, setFreeUserList] = useState([]);
  const [paidUserList, setPaidUserList] = useState([]);
  const [recentApplyTradeList, setRecentApplyTradeList] = useState([]);
  const [recentEnqList, setRecentEnqList] = useState([]);
  const [recentGuestUser, setRecentGuestUser] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // const [filterDate, setFilterDate] = useState({
  //   start_date: "",
  //   end_date: "",
  // });

  // const { start_date, end_date } = filterDate;

  const getDashboardData = async () => {
    setIsLoading(true);
    let param = {
      start_date: startDate
        ? moment(startDate).utc().add(1, "d").format("DD-MM-YYYY")
        : "",
      endDate: endDate
        ? moment(endDate).utc().add(1, "d").format("DD-MM-YYYY")
        : "",
    };
    await axios
      .get(`${API_BASE_URL}/get-dashboard-data`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: param,
      })
      .then((response) => {
        console.log(response, "res");
        setDashboardData(response?.data);
        setFreeUserList(response?.data?.free_trial_user_list);
        setPaidUserList(response?.data?.paid_user_list);
        setRecentApplyTradeList(response?.data?.recent_apply_trademark_list);
        setRecentEnqList(response?.data?.recent_enquiry_list);
        setRecentGuestUser(response?.data?.recent_gust_user);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data.status);
        setIsLoading(false);
        if(error?.response?.data?.status === 401){
          localStorage.clear();
          navigate('/cms/login')
        }
      });
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  // useEffect(() => {
  //   setToken(localStorage.getItem("token"));

  //   axios
  //     .get(`${API_BASE_URL}/list-pages`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "content-type": "multipart/form-data",
  //       },
  //     })
  //     .then((response) => {
  //       setListPageData(response.data.result.reverse());
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  //   axios
  //     .get(`${API_BASE_URL}//list-slider`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "content-type": "multipart/form-data",
  //       },
  //     })
  //     .then((response) => {
  //       setMangeSlider(response.data.result.reverse());
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  //   axios
  //     .get(`${API_BASE_URL}//list-seo`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "content-type": "multipart/form-data",
  //       },
  //     })
  //     .then((response) => {
  //       setSeoPage(response.data.result.reverse());
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   axios
  //     .get(`${API_BASE_URL}/list-faq`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "content-type": "multipart/form-data",
  //       },
  //     })
  //     .then((response) => {
  //       setFaqPage(response.data.result.reverse());
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Dashboard</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Dashboards</a>
                      </li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-body rounded-3"
                    style={{ backgroundColor: "#d3c0e5" }}
                  >
                    <div className="row">
                      <div className="col-4">
                        {/* <span  className="me-2">Start Date</span> */}
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          dateFormat="dd-MM-yyyy"
                          wrapperClassName="datePicker "
                          placeholderText="Start Date"
                          // startDate={new Date()}
                          // endDate={endDate}
                        />
                      </div>
                      <div className="col-4">
                        {/* <span className="me-2">End Date</span> */}
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          dateFormat="dd-MM-yyyy"
                          wrapperClassName="datePicker w-100"
                          placeholderText="End Date"

                          // endDate={endDate}
                          // minDate={startDate}
                        />
                      </div>
                      <div className="col-4 date-submit">
                        <button onClick={() => getDashboardData()}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {isLoading ? (
              <div>
                <Skeleton
                  count={4}
                  width={250}
                  height={100}
                  inline={true}
                  style={{ marginRight: "35px", marginBottom: "35px" }}
                />
                <Skeleton
                  count={4}
                  width={250}
                  height={100}
                  inline={true}
                  style={{ marginRight: "35px", marginBottom: "35px" }}
                />
                <Skeleton
                  count={2}
                  width={500}
                  height={200}
                  inline={true}
                  style={{ marginRight: "75px", marginBottom: "35px" }}
                />
                <Skeleton
                  count={2}
                  width={500}
                  height={200}
                  inline={true}
                  style={{ marginRight: "75px", marginBottom: "35px" }}
                />
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="row">
                      <div className="col-md-3" onClick={()=>navigate('/cms/manage-customer')}>
                        <div className="card dashboard-card mini-stats-wid">
                          <div className="card-body rounded-3 bg-danger bg-gradient ">
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-dark fw-medium">
                                  Total Customers
                                </p>
                                <h4 className="mb-0">
                                  {" "}
                                  {dashboardData?.total_user}{" "}
                                </h4>
                              </div>

                              <div className="flex-shrink-0 align-self-center">
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                  <span className="avatar-title">
                                    <i className="bx bx-user font-size-24"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        <div className="arrow"></div> {/* Arrow container */}
                        </div>

                      </div>
                      <div className="col-md-3" onClick={()=>navigate('/cms/manage-customer?filter=paid')} >
                        <div className="card dashboard-card mini-stats-wid">
                          <div className="card-body rounded-3 bg-info bg-gradient">
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-dark fw-medium">
                                  Total Paid Customer
                                </p>
                                <h4 className="mb-0">
                                  {dashboardData?.total_paid_user}
                                </h4>
                              </div>

                              <div className="flex-shrink-0 align-self-center ">
                                <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                  <span className="avatar-title rounded-circle bg-primary">
                                    <i className="bx bx-user font-size-24"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="arrow"></div> 
                        </div>
                      </div>
                      <div className="col-md-3"  onClick={()=>navigate('/cms/manage-customer?filter=trial')} >
                        <div className="card dashboard-card mini-stats-wid">
                          <div className="card-body rounded-3 bg-success bg-gradient">
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-dark fw-medium">
                                  Total Trial Customers
                                </p>
                                <h4 className="mb-0">
                                  {dashboardData?.total_free_trial_user}
                                </h4>
                              </div>

                              <div className="flex-shrink-0 align-self-center">
                                <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                  <span className="avatar-title rounded-circle bg-primary">
                                    <i className="bx bx-user font-size-24"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="arrow"></div>
                        </div>
                      </div>
                      <div className="col-md-3"  onClick={()=>navigate('/cms/manage-guest-customer')}>
                        <div className="card dashboard-card mini-stats-wid">
                          <div className="card-body rounded-3 bg-secondary bg-gradient">
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-dark fw-medium">
                                  Total Unsubscribed Customers{" "}
                                </p>
                                <h4 className="mb-0">
                                  {dashboardData?.total_web_user}
                                </h4>
                              </div>

                              <div className="flex-shrink-0 align-self-center">
                                <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                  <span className="avatar-title rounded-circle bg-primary">
                                    <i className="bx bx-user font-size-24"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="arrow"></div>
                        </div>
                      </div>
                      <div className="col-md-3" onClick={()=>navigate('/cms/manage-trademark-data/manage-trademark')} >
                        <div className="card dashboard-card mini-stats-wid">
                          <div className="card-body rounded-3 bg-danger bg-gradient rounded-3">
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-dark fw-medium">
                                  Total Trademark Data
                                </p>
                                <h4 className="mb-0">
                                  {dashboardData?.toatl_trademark}
                                </h4>
                              </div>

                              <div className="flex-shrink-0 align-self-center ">
                                <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                  <span className="avatar-title rounded-circle bg-primary">
                                    <i className="bx bx-data font-size-24"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="arrow"></div>
                        </div>
                      </div>
                      <div className="col-md-3" onClick={()=>navigate('/cms/manage-document')}>
                        <div className="card dashboard-card mini-stats-wid">
                          <div className="card-body rounded-3 bg-info bg-gradient">
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-dark fw-medium">
                                  Total Documents
                                </p>
                                <h4 className="mb-0">
                                  {dashboardData?.total_documents}
                                </h4>
                              </div>

                              <div className="flex-shrink-0 align-self-center ">
                                <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                  <span className="avatar-title rounded-circle bg-primary">
                                    <i className="bx bx-file font-size-24"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="arrow"></div>
                        </div>
                      </div>
                      <div className="col-md-3" onClick={()=>navigate('/cms/manage-api')}>
                        <div className="card dashboard-card mini-stats-wid">
                          <div className="card-body rounded-3 bg-success bg-gradient">
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-dark fw-medium">
                                  Total Api Hits
                                </p>
                                <h4 className="mb-0">
                                  {dashboardData?.total_api_hit}
                                </h4>
                              </div>

                              <div className="flex-shrink-0 align-self-center ">
                                <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                  <span className="avatar-title rounded-circle bg-primary">
                                    <i className="bx bx-data font-size-24"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="arrow"></div>
                        </div>
                      </div>
                      <div className="col-md-3"  onClick={()=>navigate('/cms/manageenquiry')} >
                        <div className="card dashboard-card mini-stats-wid">
                          <div className="card-body rounded-3 bg-secondary bg-gradient">
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-dark fw-medium">
                                  Total Enquiry
                                </p>
                                <h4 className="mb-0">
                                  {dashboardData?.total_enquiry}
                                </h4>
                              </div>

                              <div className="flex-shrink-0 align-self-center ">
                                <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                  <span className="avatar-title rounded-circle bg-primary">
                                    <i className="bx bx-question-mark font-size-24"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="arrow"></div>
                        </div>
                      </div>

                      <div className="col-md-3" onClick={()=>navigate('/cms/manage-wallet ')} >
                        <div className="card dashboard-card mini-stats-wid">
                          <div className="card-body rounded-3 bg-danger bg-gradient ">
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-dark fw-medium">
                                 Total Amount Added
                                </p>
                                <h4 className="mb-0 ">
                                &#8377; {dashboardData?.wallet_summary?.total_added_amount}
                                </h4>
                              </div>

                              <div className="flex-shrink-0 align-self-center ">
                                <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                  <span className="avatar-title rounded-circle bg-primary">
                                    <i className="bx bx-money font-size-24"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="arrow"></div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="card dashboard-card mini-stats-wid">
                          <div className="card-body rounded-3 bg-info bg-gradient">
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-dark fw-medium">
                                  Wallet Balance
                                </p>
                                <h4 className="mb-0 ">
                                &#8377; {dashboardData?.wallet_summary?.total_amount}
                                </h4>
                              </div>

                              <div className="flex-shrink-0 align-self-center ">
                                <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                  <span className="avatar-title rounded-circle bg-primary">
                                    <i className="bx bx-wallet font-size-24"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="card ">
                      <div className="card-body rounded-3">
                        <div className=" d-flex justify-content-between">
                          <h4 className="card-title mb-4">Paid User List</h4>
                          <div className=" text-primary"><Link to={'/cms/manage-customer'}>View More</Link></div>
                        </div>
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                <th className="align-middle">Name</th>
                                <th className="align-middle">Email</th>
                                <th className="align-middle">Mobile</th>
                                <th className="align-middle">Plan Type</th>
                                {/* <th className="align-middle">Subscription Type </th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {paidUserList?.map((item) => (
                                <>
                                
                                  <tr onClick={()=>navigate(`/cms/manageUserData/UserData/viewUser/${item.id}`)}>
                                    
                                    <td>{item.name}</td>

                                    <td > <span className="badge badge-pill badge-soft-info font-size-12">{item.email}</span></td>
                                    <td>
                                      {item.mobile ? item.mobile : "N/A"}
                                      {/* <button className="btn btn-sm btn-success ms-2">
                                      <i className="fa fa-phone-alt"></i>
                                    </button> */}
                                    </td>
                                    <td>{item.plan_type}</td>
                                    {/* <td>{item.subscription_type}</td> */}
                                  </tr>
                                  
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-body rounded-3">
                        <div className=" d-flex justify-content-between">
                          <h4 className="card-title mb-4">
                            Recent Guest User List
                          </h4>

                          <div className=" text-primary"> <Link to={'/cms/manage-guest-customer'}>View More</Link></div>
                        </div>
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                <th className="align-middle">Name</th>
                                <th className="align-middle">Email</th>
                                <th className="align-middle">Mobile</th>
                                {/* <th className="align-middle">Plan Type</th> */}
                                {/* <th className="align-middle">Subscription Type </th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {recentGuestUser?.map((item) => (
                                <>
                                  <tr>
                                    <td>{item.name}</td>

                                    <td><span className="badge badge-pill badge-soft-info font-size-12">{item.email}</span></td>
                                    <td>
                                      {item.mobile ? item.mobile : "N/A"}
                                      {/* <button className="btn btn-sm btn-success ms-2">
                                      <i className="fa fa-phone-alt"></i>
                                    </button> */}
                                    </td>
                                    {/* <td>{item.plan_type}</td> */}
                                    {/* <td>{item.subscription_type}</td> */}
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-body rounded-3">
                        <div className=" d-flex justify-content-between" >
                        <h4 className="card-title mb-4">
                          Recent Trial User List
                        </h4>
                        <div className=" text-primary"><Link to={'/cms/manage-customer'}>View More</Link></div>
                        </div>
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                <th className="align-middle">Name</th>
                                <th className="align-middle">Email</th>
                                <th className="align-middle">Mobile</th>
                                <th className="align-middle">Plan Type</th>
                               
                              </tr>
                            </thead>
                            <tbody>
                              {freeUserList?.map((item) => (
                                <>
                                  <tr>
                                    <td>{item?.name}</td>

                                    <td><span className="badge badge-pill badge-soft-info font-size-12">{item.email}</span></td>
                                    <td>
                                      {item?.mobile ? item?.mobile : "N/A"}
                                    </td>
                                    <td>{item?.plan_type}</td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-body rounded-3">
                      <div className=" d-flex justify-content-between" >
                        <h4 className="card-title mb-4">Recent Enquiry List</h4>
                        <div className=" text-primary"><Link to={'/cms/manageenquiry'}>View More</Link></div>
                        </div>
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                <th className="align-middle">Name</th>
                                <th className="align-middle">Email</th>
                                <th className="align-middle">Mobile</th>
                                <th className="align-middle">Message </th>
                                {/* <th className="align-middle">Subscription Type </th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {recentEnqList?.map((item) => (
                                <>
                                  <tr>
                                    <td>{item.name}</td>

                                    <td><span className="badge badge-pill badge-soft-info font-size-12">{item.email}</span></td>
                                    <td>
                                      {item.phone ? item.phone : "N/A"}
                                      {/* <button className="btn btn-sm btn-success ms-2">
                                      <i className="fa fa-phone-alt"></i>
                                    </button> */}
                                    </td>
                                    <td>{item.message}</td>
                                    {/* <td>{item.subscription_type}</td> */}
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
