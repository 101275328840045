import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { toast } from "react-toastify";
import Select from "react-select";


const AddSlider = () => {
  const [formData, setFormData] = useState({
    title: "",
    short_order: "",
    video_link: "",
    description: "",
    image: "",
    parent_link:"",
  });

  const { title, short_order, video_link, description, image, parent_link } = formData;

  const [imageView, setImageView] = useState("");
  const [errors, setErrors] = useState({});

  const [titleError, setTitleError] = useState("");
  const [shortOrderError, setShortOrderError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [pageError, setPageError] = useState("");
  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  const [managePageData, setManagePageData] = useState();


  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setToken(localStorage.getItem("cms_token"));
    if (id) {
      fetchPrefilled(id);
    }
  }, []);

  const fetchPrefilled = async (id) => {
    try {
      setToken(localStorage.getItem("cms_token"));
      // setIsLoading(true);

      const enquiryResponse = await axios.get(
        `${API_BASE_URL}/get-slider-details?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const enquiryRes = JSON.parse(JSON.stringify(enquiryResponse.data));
      console.log(enquiryRes, "res");

      if (enquiryRes.status == 200) {
        // setIsLoading(false);
        setFormData({
          ...formData,
          title: enquiryRes.data.title,
          short_order: enquiryRes.data.short_order,
          video_link: enquiryRes.data.video_link,
          description: enquiryRes.data.description,
          image: enquiryRes.data.image,

          // page_image: enquiryRes.data.page_image,
        });

        setImagePreview(enquiryRes.data.image);
      }
    } catch (error) {}
  };


  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/get-page-and-content`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        setManagePageData(response.data.data.reverse());
      })
      .catch((error) => {
        console.log(error);
      });

    if (id) {
      fetchPrefilled(id);
    }
  }, []);

  const validateForm = () => {
    let errors = {};
    // check for empty values

    if (!formData.title) {
      errors.title = "Please Enter Tittle";
    }
    if (!formData.short_order) {
      errors.short_order = "Please enter this field";
    }
    if (!formData.parent_link) {
      errors.parent_link = "Please enter this field";
    }
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm();

    const form = new FormData();
    form.append("title", formData.title);
    form.append("short_order", formData.short_order);
    form.append("video_link", formData.video_link);
    form.append("description", formData.description);
    form.append("image", imageView);
    form.append("slug", formData.parent_link);


    if (Object.keys(errors).length === 0) {
      if (!id) {
        form.append("action", "add");
        axios
          .post(`${API_BASE_URL}/add-update-slider`, form, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log(response.data.status);
            if (response.data.status === 200) {
              navigate("/cms/manageslider");
              toast.success("Slider added successfully");
            }
          })
          .catch((error) => {
            console.log(error);
            setPageError(error.response.data.result);
          });
      }
      else{
        form.append("action", "update");
        form.append("id", id);
        axios
          .post(`${API_BASE_URL}/add-update-slider`, form, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log(response.data.status);
            if (response.data.status === 200) {
              navigate("/cms/manageslider");
              toast.success("Slider added successfully");
            }
          })
          .catch((error) => {
            console.log(error);
            setPageError(error.response.data.result);
          }); 
      }
    } else {
      setErrors(errors);
      console.log(errors);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });

    if (name === "image") {
      setImageView(event.target.files[0]);
      // setFormData({ ...formData, [name]: event.target.files[0] });
      console.log(event.target.files, "event.target.files[0]");
      const selectedImage = event.target.files[0];
      if (!selectedImage) {
        setImagePreview(null);
        return;
      }
      const objectUrl = URL.createObjectURL(selectedImage);
      setImagePreview(objectUrl);
    }
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const sampledata = [];
  managePageData?.forEach((item) => {
    sampledata?.push({ value: item.page_url, label: item.page_name });
  });

  const handleSelect = (fieldName, selectedOption) => {
    // setSelectedOption(selectedOption);
    console.log(selectedOption, "opton");
    setFormData({ ...formData, [fieldName]: selectedOption.value });
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };

  
  const selectStyles = (errorFieldName) => ({
    control: (provided) => ({
      ...provided,
      borderColor: errors[errorFieldName] ? "#dc3545" : provided.borderColor,
      boxShadow: errors[errorFieldName]
        ? "0 0 0 0.2rem rgba(220, 53, 69, 0.25)"
        : provided.boxShadow,
      minHeight: 22,
      height: 33,
    }),
  });

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">{`${id ? "Edit":" Add"} Slider`} </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/cms/manageslider"}>
                          {" "}
                          Manage Slider Page
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">{`${id ? "Edit":" Add"} Slider`} </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">{`${id ? "Edit":" Add"} Slider`}</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                          <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Parent Link
                              </label>
                              <div className="col-lg-10">
                                <Select
                                  className={`custom-select custom-select-sm`}
                                  options={sampledata}
                                  value={sampledata.filter(
                                    (option) => option.value == parent_link
                                  )}
                                  styles={selectStyles("parent_link")}
                                  name="parent_link"
                                  onChange={(selectedOption) =>
                                    handleSelect("parent_link", selectedOption)
                                  }
                                >
                                  <option value="">Select an option</option>
                                  {managePageData?.map((item) => (
                                    <option value={item.id}>
                                      {item.page_name}
                                    </option>
                                  ))}
                                </Select>
                               
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Title
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="title"
                                  type="text"
                                  value={title}
                                  className={
                                    errors.title
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter title..."
                                  onChange={handleInputChange}
                                />
                                {errors.title && (
                                  <div className="text-danger">
                                    {errors.title}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Short Order
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="short_order"
                                  type="text"
                                  value={short_order}
                                  className={
                                    errors.short_order
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter short order..."
                                  onChange={handleInputChange}
                                />
                                {errors.short_order && (
                                  <div className="text-danger">
                                    {errors.short_order}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Video Link
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="video_link"
                                  type="text"
                                  value={video_link}
                                  className={"form-control"}
                                  placeholder="Enter short order..."
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Description
                              </label>
                              <div className="col-lg-10">
                                <Editor
                                  onEditorChange={handleEditorChangeDescription}
                                  value={description}
                                  init={{
                                    plugins:
                                      "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    table_default_attributes: {
                                      border: "5",
                                    },
                                  }}
                                />
                              </div>
                            </div>

                            <div className="inner-repeater mb-4">
                              <div
                                data-repeater-list="inner-group"
                                className="inner form-group mb-0 row"
                              >
                                <label className="col-form-label col-lg-2">
                                  Image
                                </label>
                                <div
                                  data-repeater-item
                                  className="inner col-lg-10 ms-md-auto"
                                >
                                  <div className="mb-3 row align-items-center">
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-md-0">
                                        <input
                                          className={`custom-select custom-select-sm ${
                                            errors.image && "is-invalid"
                                          }`}
                                          name="image"
                                          type="file"
                                          accept="image/*"
                                          onChange={(e) => {
                                            // handleImageChange(e);
                                            handleInputChange(e);
                                          }}
                                        />
                                        {errors.image && (
                                          <div className="text-danger">
                                            {errors.image}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-20">
                                        <div>
                                          {imagePreview && (
                                            <img
                                              src={imagePreview}
                                              alt="Preview"
                                              width="200"
                                              height="200"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            {pageError && (
                              <div className="alert alert-danger" role="alert">
                                {pageError}
                              </div>
                            )}
                            <button type="submit" className="btn btn-primary">
                            {`${id ? "Update":" Add"} Slider`} Page
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSlider;
