import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL, API_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import Loader from "../../Loader/loader";
import { Pagination } from "@mui/material";

const ViewEnquiry = () => {
  const [formData, setFormData] = useState({
    pageurl: "",
    name: "",
    rating: "",
    metakeywords: "",
    description: "",
    cityandcountry: "",
    image_url: "",
  });
  const [pageError, setPageError] = useState("");
  const [show, setShow] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [pageSize, setPageSize] = useState();
  const [pageApi, setPageApi] = useState(1);
  const [totalApi, setTotalApi] = useState(0);
  const [isLoading1, setIsLoading1] = useState(true);

  const [pageSize2, setPageSize2] = useState();
  const [pageApi2, setPageApi2] = useState(1);
  const [totalApi2, setTotalApi2] = useState(0);
  const [isLoading2, setIsLoading2] = useState(true);

  const navigate = useNavigate();
  const {
    pageurl,
    name,
    rating,
    metakeywords,
    description,
    cityandcountry,
    image_url,
  } = formData;
  const { id } = useParams();

  const [image, setImage] = useState("");
  const [userData, setUserData] = useState();
  const [walletData, setWalletData] = useState();
  const [apiCallsData, setApiCallsData] = useState();

  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    ViewPage();
  }, []);

  const ViewPage = async () => {
    setIsLoading(true);
    const res = await axios
      .get(`${API_BASE_URL}/get-enquiry-details`, {
        params: { id: id },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
        },
      })
      .then((res) => {
        if (res.data.status == 200) {
          console.log(res.data, "data");
          setUserData(res?.data?.data);
          setIsLoading(false)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          {isLoading ? (
            <div className="d-flex justify-content center text-center">
              {" "}
              <Loader />
            </div>
          ) : (
            <div className="container-fluid">
              <section id="aff_prof_sec">
                <div class="container">
                  <div class="mt-5"> 
                      <div class="col-md-10 col-lg-10">
                        <div class="aff_prof_box">
                          <div class="card">
                            <div class="card-body">
                              <div class="bdb mb-2">
                                <h2 class="card-title">Enquiry Information</h2>
                              </div>
                              <div class="table-responsive">
                                <table class="table table-nowrap mb-0 table-baddresserless">
                                  <tbody>
                                    {/* <tr>
                                          <th scope="row">
                                             <h5 class="m-0">Status :</h5>
                                          </th>
                                          <td>
                                             <p class="m-0 font-size-13">
                                                <select >
                                                   <option value="">--- Select Status ---</option>
                                                   <option value="https://docsbizkit.com/customers/status/176">Active</option>
                                                   <option value="https://docsbizkit.com/customers/status/176" selected>Inactive</option>
                                                </select>
                                             </p>
                                          </td>
                                       </tr> */}
                                    <tr>
                                      <th scope="row">
                                        <h5 class="m-0">Name:</h5>
                                      </th>
                                      <td>
                                        <p class="m-0 font-size-13">
                                          {userData?.name ? userData?.name : 'N/A' }
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">
                                        <h5 class="m-0">Phone:</h5>
                                      </th>
                                      <td>
                                        <p class="m-0 font-size-13">
                                          {userData?.phone ? userData?.phone : 'N/A' }
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">
                                        <h5 class="m-0">Address</h5>
                                      </th>
                                      <td>
                                        <p class="m-0 font-size-13">
                                          {userData?.address ? userData?.address :'N/A' }
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">
                                        <h5 class="m-0">Company:</h5>
                                      </th>
                                      <td>
                                        <p class="m-0 font-size-13">
                                          {userData?.company ? userData?.company : 'N/A' }
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">
                                        <h5 class="m-0">Email:</h5>
                                      </th>
                                      <td>
                                        <p class="m-0 font-size-13">
                                          {userData?.email ? userData?.email : 'N/A'}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">
                                        <h5 class="m-0">Source URL</h5>
                                      </th>
                                      <td>
                                        <Link to={userData?.source_url} target="_blank" rel="noreferrer">
                                        <p class="m-0 font-size-13">
                                          {userData?.source_url ? userData?.source_url : 'N/A'}
                                        </p>
                                        </Link>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">
                                        <h5 class="m-0">Message:</h5>
                                      </th>
                                      <td>
                                        <p class="m-0 font-size-13">
                                          {userData?.message ? userData?.message : 'N/A'}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">
                                        <h5 class="m-0">Status:</h5>
                                      </th>
                                      <td>
                                      {userData?.is_positive == 0 ? (
                                      <span className="badge badge-pill badge-soft-primary font-size-11">
                                        Neutral
                                      </span>
                                    ) : userData?.is_positive == 1 ? (
                                      <span className="badge badge-pill badge-soft-success font-size-11">
                                        Positive
                                      </span>
                                    ) : userData?.is_positive == 2 ? (
                                      <span className="badge badge-pill badge-soft-danger font-size-11">
                                        Negative
                                      </span>
                                    ) : (
                                      <span className="badge badge-pill badge-soft-warning font-size-11">
                                        Pending
                                      </span>
                                    )}
                                      </td>
                                    </tr>
                                   
                                    <tr>
                                          <th scope="row">
                                             <h5 class="m-0">UTM Source:</h5>
                                          </th>
                                          <td>
                                             <p class="m-0 font-size-13">{userData?.utm_source  ? userData?.utm_source : 'N/A' }</p>
                                          </td>
                                       </tr> 
                                    <tr class="my-2">
                                          <th scope="row">
                                             <h5 class="m-0">UTM Medium:</h5>
                                          </th>
                                          <td>
                                          <p class="m-0 font-size-13">{userData?.utm_medium ? userData?.utm_medium : 'N/A'  }</p>
                                          </td>
                                       </tr> 
                                       <tr class="my-2">
                                          <th scope="row">
                                             <h5 class="m-0">UTM Campaign:</h5>
                                          </th>
                                          <td>
                                          <p class="m-0 font-size-13">{userData?.utm_campaign  ? userData?.utm_campaign : 'N/A' }</p>
                                          </td>
                                       </tr> 
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewEnquiry;
