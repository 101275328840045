import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";

const EditPlan = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    monthly_price: "",
    annual_price: "",
    offer: "",
    api_calls: "",
    duration: "",
    plan_id: "",
    doc_download:"",
  });
  const {
    plan_id,
    name,
    description,
    monthly_price,
    annual_price,
    offer,
    api_calls,
    duration,
    doc_download,
  } = formData;

  const [statusList, setStatusList] = useState();
  const [classList, setClassList] = useState([]);
  const [tradeMarkList, setTradeMarkList] = useState();
  const [tmCategoryList, setTmCategoryList] = useState();
  const [fillingModeList, setFilingModeList] = useState();
  const [featuresList, setFeaturesList] = useState();
  const [tradeMarkInfo, setTradeMarkInfo] = useState({
    trademark_info: [],
    status: [],
    filling_mode: [],
    tm_category: [],
    search_for:[],
    feature:[],
  });
  const [statusArray, setStatusArray] = useState([]);
  const [trademarkArray, setTrademarkArray] = useState([]);
  const [fillingArray, setfillingArray] = useState([]);
  const [tmArray, settmArray] = useState([]);
  const [classArray, setClassArray] = useState([]);
  const [featuresArray, setFeaturesArray] = useState([]);

  const [date, setDate] = useState();
  const [errors, setErrors] = useState({});

  const [pageError, setPageError] = useState("");

  const [token, setToken] = useState(localStorage.getItem("cms_token"));

  const navigate = useNavigate();
  const { id } = useParams();

  let hasError = false;

  useEffect(() => {
    setToken(localStorage.getItem("cms_token"));
    axios
      .get(`${API_BASE_URL}/update-subscription-plan-view`, {
        params: { subscription_id: id },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data, "res");
        getDynamicList(res.data.data);
        setFormData({
          ...formData,
          plan_id: parseInt(id),
          name: res.data.subscription_data.name,
          description: res.data.subscription_data.description,
          monthly_price: res.data.subscription_data.monthly_price,
          annual_price: res.data.subscription_data.annual_price,
          offer: res.data.subscription_data.offer,
          api_calls: res.data.subscription_data.api_calls,
          duration: res.data.subscription_data.duration,
          action: "update",
          doc_download:res.data.subscription_data.doc_download
          // page_image: enquiryRes.data.page_image,
        });
        // setTradeMarkInfo({
        //     trademark_info: res.data,
        //     status: res.data,
        //     filling_mode: res.data,
        //     tm_category: res.data,
        // })
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData, "form");
    let form = {
      search_for: classArray?.toString().replace(/\,/g, "#"),
      class: statusArray?.toString().replace(/\,/g, "#"),
      filing_mode: fillingArray?.toString().replace(/\,/g, "#"),
      tm_category: tmArray?.toString().replace(/\,/g, "#"),
      tm_type: trademarkArray?.toString().replace(/\,/g, "#"),
      feature: featuresArray?.toString().replace(/\,/g, "#"),
      ...formData,
    };  

    axios
      .post(`${API_BASE_URL}/update-subscription-plan`, form, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data.status);
        if (response.data.status === 200) {
          toast.success("Plan Added successfully");
          navigate("/cms/manage-subscription-plan");
        }
      })
      .catch((error) => {
        console.log(error);
        setPageError(error.response.data.result);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const getDynamicList = (data) => {
    console.log(data);
    setClassList([]);
    setStatusList([]);
    setTmCategoryList([]);
    setTradeMarkList([]);
    setFilingModeList([]);
    setFeaturesList([]);
    setStatusArray([]);
    setfillingArray([]);
    settmArray([]);
    setTrademarkArray([]);
    setClassArray([]);
    setFeaturesArray([]);
    data.map((item, i) => {
      if (item.name === "search_for") {
        console.log(item, i, "index");
        setClassList((prev) => [...prev, item]);
        if (item.status) {
          setClassArray((prev) => [...prev, item.search_field]);
        }
      }
      if (item.name === "Class") {
        console.log(item, i, "status");
        setStatusList((prev) => [...prev, item]);
        if (item.status) {
          setStatusArray((prev) => [...prev, item.search_field]);
        }
      }
      if (item.name === "TM_Category") {
        console.log(item, i, "index");
        setTmCategoryList((prev) => [...prev, item]);
        if (item.status) {
          settmArray((prev) => [...prev, item.search_field]);
        }
      }
      if (item.name === "TM_Type") {
        console.log(item, i, "index");
        setTradeMarkList((prev) => [...prev, item]);
        if (item.status) {
          setTrademarkArray((prev) => [...prev, item.search_field]);
        }
      }
      if (item.name === "Filing_Mode") {
        console.log(item, i, "index");
        setFilingModeList((prev) => [...prev, item]);
        if (item.status) {
          setfillingArray((prev) => [...prev, item.search_field]);
        }
      }
      if (item.name === "Feature") {
        console.log(item, i, "index");
        setFeaturesList((prev) => [...prev, item]);
        if (item.status) {
          setFeaturesArray((prev) => [...prev, item.search_field]);
        }
      }
    });
  };

  console.log(tradeMarkInfo, "statusList");

  const handleClassChange = async (e) => {
    const { value, checked } = e?.target;
    const { search_for } = tradeMarkInfo;

    if (checked) {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        search_for: [...search_for, e.target.value],
      });
      setClassArray([...classArray, value]);
    } else {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        search_for: search_for?.filter((e) => e !== value),
      });
      setClassArray((items) => items.filter((e) => e !== value));
    }
  };

  const handleTradeMarkChange = async (e) => {
    const { value, checked } = e?.target;
    const { trademark_info } = tradeMarkInfo;

    if (checked) {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        trademark_info: [...trademark_info, e.target.value],
      });
      setTrademarkArray([...trademarkArray, value]);
    } else {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        trademark_info: trademark_info?.filter((e) => e !== value),
      });
      setTrademarkArray((items) => items.filter((e) => e !== value));
    }
  };

  const handleStatusChange = (e) => {
    const { value, checked } = e?.target;
    const { status } = tradeMarkInfo;
    console.log(statusList, "lissttttt");
    if (checked) {
      console.log(tradeMarkInfo);
      setTradeMarkInfo({
        ...tradeMarkInfo,
        status: [...status, value],
      });
      setStatusArray([...statusArray, value]);
    } else {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        status: status?.filter((e) => e !== value),
      });
      setStatusArray((items) => items.filter((e) => e !== value));
    }
  };

  const handleFillingChange = (e) => {
    const { value, checked } = e.target;
    const { filling_mode } = tradeMarkInfo;

    if (checked) {
      console.log(tradeMarkInfo);
      setTradeMarkInfo({
        ...tradeMarkInfo,
        filling_mode: [...filling_mode, value],
      });
      setfillingArray([...fillingArray, value]);
    } else {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        filling_mode: filling_mode?.filter((e) => e !== value),
      });
      setfillingArray((items) => items.filter((e) => e !== value));
    }
  };

  const handleTmCategoryChange = (e) => {
    const { value, checked } = e?.target;
    const { tm_category } = tradeMarkInfo;
    console.log(e, "e");
    if (checked) {
      console.log(tradeMarkInfo);
      setTradeMarkInfo({
        ...tradeMarkInfo,
        tm_category: [...tm_category, value],
      });
      settmArray([...tmArray, value]);
    } else {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        tm_category: tm_category?.filter((e) => e !== value),
      });
      settmArray((items) => items.filter((e) => e !== value));
    }
  };

  const handleFeaturesChange = (e) => {
    const { value, checked } = e?.target;
    const { feature } = tradeMarkInfo;
    console.log(e, "e");
    if (checked) {
      console.log(tradeMarkInfo);
      setTradeMarkInfo({
        ...tradeMarkInfo,
        feature: [...feature, value],
      });
      setFeaturesArray([...featuresArray, value]);
    } else {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        feature: feature?.filter((e) => e !== value),
      });
      setFeaturesArray((items) => items.filter((e) => e !== value));
    };
  };

  const onDateChange = (e) => {
    console.log(e.target.value, "etarget");
    if (e.target.name == "start_date") {
      setDate({
        ...date,
        start_date: e.target.value,
      });
    } else {
      setDate({
        ...date,
        end_date: e.target.value,
      });
    }
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Edit Plannnn </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/cms/manageenquiry"}>Manage Plan</Link>
                      </li>

                      <li className="breadcrumb-item active">Edit Plan</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Edit Plan</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer row">
                            <div class="col-md-6 col-xl-9">
                              <div className="form-group row mb-4">
                                <label
                                  for="taskname"
                                  className="col-form-label col-lg-2"
                                >
                                  Name
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    id="taskname"
                                    name="name"
                                    type="text"
                                    value={name}
                                    className={
                                      errors.name
                                        ? "form-control is-invalid"
                                        : "form-control "
                                    }
                                    placeholder="Enter name..."
                                    onChange={handleInputChange}
                                  />
                                  {errors.name && (
                                    <div className="text-danger">
                                      {errors.name}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row mb-4">
                                <label
                                  for="taskname"
                                  className="col-form-label col-lg-2"
                                >
                                  Description
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    id="taskname"
                                    name="description"
                                    type="text"
                                    value={description}
                                    className={
                                      errors.description
                                        ? "form-control is-invalid"
                                        : "form-control "
                                    }
                                    placeholder="Enter Description..."
                                    onChange={handleInputChange}
                                  />
                                  {errors.description && (
                                    <div className="text-danger">
                                      {errors.description}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row mb-4">
                                <label
                                  for="taskname"
                                  className="col-form-label col-lg-2"
                                >
                                  Monthly Price
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    id="taskname"
                                    name="monthly_price"
                                    type="text"
                                    value={monthly_price}
                                    className={
                                      errors.monthly_price
                                        ? "form-control is-invalid"
                                        : "form-control "
                                    }
                                    placeholder="Enter monthly price..."
                                    onChange={handleInputChange}
                                  />
                                  {errors.monthly_price && (
                                    <div className="text-danger">
                                      {errors.monthly_price}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row mb-4">
                                <label
                                  for="taskname"
                                  className="col-form-label col-lg-2"
                                >
                                  Annual Price
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    id="taskname"
                                    name="annual_price"
                                    type="text"
                                    value={annual_price}
                                    className={
                                      errors.annual_price
                                        ? "form-control is-invalid"
                                        : "form-control "
                                    }
                                    placeholder="Enter Annual Price..."
                                    onChange={handleInputChange}
                                  />
                                  {errors.annual_price && (
                                    <div className="text-danger">
                                      {errors.annual_price}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row mb-4">
                                <label
                                  for="taskname"
                                  className="col-form-label col-lg-2"
                                >
                                  Duration
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    id="taskname"
                                    name="duration"
                                    type="text"
                                    value={duration}
                                    className={
                                      errors.duration
                                        ? "form-control is-invalid"
                                        : "form-control "
                                    }
                                    placeholder="Enter Duration..."
                                    onChange={handleInputChange}
                                  />
                                  {errors.duration && (
                                    <div className="text-danger">
                                      {errors.duration}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row mb-4">
                                <label
                                  for="taskname"
                                  className="col-form-label col-lg-2"
                                >
                                  Offer
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    id="taskname"
                                    name="offer"
                                    type="text"
                                    value={offer}
                                    className={
                                      errors.offer
                                        ? "form-control is-invalid"
                                        : "form-control "
                                    }
                                    placeholder="Enter Offer..."
                                    onChange={handleInputChange}
                                  />
                                  {errors.offer && (
                                    <div className="text-danger">
                                      {errors.offer}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row mb-4">
                                <label
                                  for="taskname"
                                  className="col-form-label col-lg-2"
                                >
                                  API Calls
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    id="taskname"
                                    name="api_calls"
                                    type="text"
                                    value={api_calls}
                                    className={
                                      errors.api_calls
                                        ? "form-control is-invalid"
                                        : "form-control "
                                    }
                                    placeholder="Enter ..."
                                    onChange={handleInputChange}
                                  />
                                  {errors.api_calls && (
                                    <div className="text-danger">
                                      {errors.api_calls}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row mb-4">
                                <label
                                  for="taskname"
                                  className="col-form-label col-lg-2"
                                >
                                  Doc Download Limit
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    id="taskname"
                                    name="doc_download"
                                    type="text"
                                    value={doc_download}
                                    className={
                                      errors.doc_download
                                        ? "form-control is-invalid"
                                        : "form-control "
                                    }
                                    placeholder="Enter ..."
                                    onChange={handleInputChange}
                                  />
                                  {errors.doc_download && (
                                    <div className="text-danger">
                                      {errors.doc_download}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-xl-3 col-lg-3">
                              <div class="card">
                                <div class="card-header bg-dark text-white">
                                  <p class="mb-0">Filter by Trade Mark</p>
                                </div>
                                <div class="card-body">
                                  {tradeMarkList?.map((item, index) => {
                                    return (
                                      <>
                                        <div class="form-check">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            name="trademark_info"
                                            value={item?.search_field}
                                            id={`trademark-checkbox-${index}`}
                                            onChange={handleTradeMarkChange}
                                            //   disabled={!item?.status}
                                            checked={trademarkArray.includes(
                                              item.search_field
                                            )}
                                          />
                                          <label
                                            class="form-check-label"
                                            for="flexCheckDefault"
                                          >
                                            {item?.search_field}
                                          </label>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header bg-dark text-white">
                                  <p class="mb-0">Filter by Class Type</p>
                                </div>
                                <div class="card-body">
                                  {classList?.map((item, index) => {
                                    return (
                                      <>
                                        <div class="form-check">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            name="search_for"
                                            value={item?.search_field}
                                            id={`trademark-checkbox-${index}`}
                                            onChange={handleClassChange}
                                            //   disabled={!item?.status}
                                            checked={classArray.includes(
                                              item.search_field
                                            )}
                                          />
                                          <label
                                            class="form-check-label"
                                            for="flexCheckDefault"
                                          >
                                            {item?.search_field}
                                          </label>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header bg-dark text-white">
                                  <p class="mb-0">Filter by Status</p>
                                </div>
                                <div class="card-body">
                                  {statusList?.map((item, index) => {
                                    return (
                                      <>
                                        <div class="form-check">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            value={item?.search_field}
                                            name="status"
                                            id={`trademark-checkbox-${index}`}
                                            onChange={handleStatusChange}
                                            //   disabled={!item.status}
                                            checked={statusArray.includes(
                                              item.search_field
                                            )}
                                          />
                                          <label
                                            class="form-check-label"
                                            for="flexCheckDefault"
                                          >
                                            {item?.search_field}
                                          </label>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                              <div class="card mt-3">
                                <div class="card-header bg-dark text-white">
                                  <p class="mb-0">Filter by Filling Mode</p>
                                </div>
                                <div class="card-body">
                                  {fillingModeList?.map((item, index) => {
                                    return (
                                      <>
                                        <div class="form-check">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            name="filling_mode"
                                            value={item?.search_field}
                                            id={`filling-checkbox-${index}`}
                                            onChange={handleFillingChange}
                                            checked={fillingArray.includes(
                                              item.search_field
                                            )}
                                            status={item.status}
                                          />
                                          <label
                                            class="form-check-label"
                                            for="flexCheckDefault"
                                          >
                                            {item?.search_field}
                                          </label>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                              <div class="card mt-3">
                                <div class="card-header bg-dark text-white">
                                  <p class="mb-0">Filter by Tm Category</p>
                                </div>
                                <div class="card-body">
                                  {tmCategoryList?.map((item, index) => {
                                    return (
                                      <>
                                        <div class="form-check">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            name="tm_category"
                                            value={item?.search_field}
                                            id={`tm_category-checkbox-${index}`}
                                            onChange={handleTmCategoryChange}
                                            checked={tmArray.includes(
                                              item.search_field
                                            )}
                                          />
                                          <label
                                            class="form-check-label"
                                            for="flexCheckDefault"
                                          >
                                            {item?.search_field}
                                          </label>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>



                              </div>
                              <div class="card mt-3">
                                <div class="card-header bg-dark text-white">
                                  <p class="mb-0">Filter by Features</p>
                                </div>
                                <div class="card-body">
                                  {featuresList?.map((item, index) => {
                                    return (
                                      <>
                                        <div class="form-check">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            name="feature"
                                            value={item?.search_field}
                                            id={`tm_category-checkbox-${index}`}
                                            onChange={handleFeaturesChange}
                                            checked={featuresArray.includes(
                                              item.search_field
                                            )}
                                          />
                                          <label
                                            class="form-check-label"
                                            for="flexCheckDefault"
                                          >
                                            {item?.search_field}
                                          </label>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>


                                
                              </div>



                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            {pageError && (
                              <div className="alert alert-danger" role="alert">
                                {pageError}
                              </div>
                            )}
                            <button type="submit" className="btn btn-primary">
                              Update Plan
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPlan;
