import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_BASE_URL, IMG_URl } from "../../../config";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Loader/loader";
import Pagination from "@mui/material/Pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";
import { CSVLink } from "react-csv";


const ManageUserData = () => {
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const [statusList, setStatusList] = useState();
  const [classList, setClassList] = useState([]);
  const [tradeMarkList, setTradeMarkList] = useState();
  const [tmCategoryList, setTmCategoryList] = useState();
  const [fillingModeList, setFilingModeList] = useState();
  const [tradeMarkInfo, setTradeMarkInfo] = useState({
    trademark_info: [],
    status: [],
    filling_mode: [],
    tm_category: [],
  });

  const { trademark_info, status, filling_mode, tm_category } = tradeMarkInfo;
  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  const [userId, setUserId] = useState(
    JSON.parse(localStorage.getItem("trade_user_data"))
  );
  const [load, setLoad] = useState(false);

  const [loading, setLoading] = useState(false);
  const [searchedData, setSearcedData] = useState([]);
  const [pageSize, setPageSize] = useState();
  const [date, setDate] = useState({
    start_date: "",
    end_date: "",
  });
  const [pageApi, setPageApi] = useState(1);
  const [selectedOption, setSelectedOption] = useState("Word Mark");
  const [searchedValue, setSearchedvalue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [tableLoader, setTableLoader] = useState(false);
  const startDateInputRef = useRef(null);
  const endDateInputRef = useRef(null);
  const searchInputRef = useRef(null);

  const wrapperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [excelLink, setExcelLink] = useState();
  const navigate = useNavigate();

  // data declared to be used in table taking data from JSON file
  const data = useMemo(() => searchedData, []);

  // Contains the column headers and table data in the required format for CSV
  const csvData = [
    [
      "Company Name",
      "Status",
      "Mobile",
      "Email",
      "TradeMark Number",
      "Date Of Application",
      "State",
      "Country",
      "Filing Mode",
      "TradeMark Type",
      "Proprietor Name",
      "Attorney Name",
    ],
    ...searchedData.map(
      ({
        name,
        status,
        phone,
        email,
        trade_mark,
        date_of_application,
        state,
        country,
        filing_mode,
        trade_mark_type,
        proprietor_name,
        attorney_name,
      }) => [
        name,
        status,
        phone,
        email,
        trade_mark,
        date_of_application,
        state,
        country,
        filing_mode,
        trade_mark_type,
        proprietor_name,
        attorney_name,
      ]
    ),
  ];



  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    setToken(localStorage.getItem("cms_token"));
    getUserSearchList();
  }, []);

  console.log(localStorage.getItem("trede_user_id"), "user_id");

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      // setIsVisible(false);
      setSuggestions([]);
    }
  };

  const getUserSearchList = async () => {
    // setLoading(true);
    try {
      const { data: response } = await axios
        .get(`${API_BASE_URL}/get-search-module-by-user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
          },
          params: {
            user_id: userId?.id,
          },
        })
        .then((res) => {
          console.log(res.data, "res");
          getDynamicList(res.data.data);
        });
      console.log(response.result, "response");
    } catch (error) {
      console.error(error, "error");
    }
    // setLoading(false);
  };

  const getDynamicList = (data) => {
    console.log(data);
    setClassList([]);
    setStatusList([]);
    setTmCategoryList([]);
    setTradeMarkList([]);
    setFilingModeList([]);
    data.map((item, i) => {
      if (item.name === "search_for") {
        console.log(item, i, "index");
        setClassList((prev) => [...prev, item]);
      }
      if (item.name === "Class") {
        console.log(item, i, "index");
        setStatusList((prev) => [...prev, item]);
      }
      if (item.name === "TM_Category") {
        console.log(item, i, "index");
        setTmCategoryList((prev) => [...prev, item]);
      }
      if (item.name === "TM_Type") {
        console.log(item, i, "index");
        setTradeMarkList((prev) => [...prev, item]);
      }
      if (item.name === "Filing_Mode") {
        console.log(item, i, "index");
        setFilingModeList((prev) => [...prev, item]);
      }
    });
  };

  const sampledata = [];
  classList?.forEach((item) => {
    sampledata?.push({
      value: item.search_field,
      label: item.search_field,
      disabled: item.status,
    });
  });

  const options = [
    { value: "word_mark", label: "Word Mark" },
    { value: "class_type", label: "Class Type" },
    { value: "attorney_name", label: "Attorney Name" },
    { value: "trademark_number", label: "Trademark Number" },
    { value: "proprietor_name", label: "Proprietor Name" },
  ];

  useEffect(() => {
    console.log("effet", trademark_info);
    sendData();
    if (trademark_info.length > 0) {
      sendData();
    } else {
      setSearcedData([]);
    }
  }, [pageApi, tradeMarkInfo, date]);

  useEffect(() => {
    // getStatusList();
    // getClassList();
    // getTradeMarkList();
    // getFillingModeList();
    // getTmCategoryList();
  }, []);

  const getStatusList = async () => {
    // setLoading(true);
    try {
      const { data: response } = await axios
        .get(`${API_BASE_URL}/unique-status`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
          },
        })
        .then((res) => {
          console.log(res.data, "res");
          setStatusList(res?.data?.data);
        });
      console.log(response.result, "response");
    } catch (error) {
      console.error(error.message);
    }
    // setLoading(false);
  };
  // const getClassList = async () => {
  //   // setLoading(true);
  //   try {
  //     const { data: response } = await axios
  //       .get(`${API_URl}/get-unique-class`, {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       })
  //       .then((res) => {
  //         console.log(res.data, "res");
  //         setClassList(res?.data);
  //       });
  //     console.log(response.result, "response");
  //   } catch (error) {
  //     console.error(error.message);
  //   }
  //   // setLoading(false);
  // };

  console.log(classList, "classlist");
  console.log(tradeMarkList, "tradeMarkList");
  console.log(fillingModeList, "fillingModeList");
  console.log(tmCategoryList, "tmCategoryList");
  console.log(statusList, "statusList");

  const getTradeMarkList = async () => {
    // setLoading(true);
    try {
      const { data: response } = await axios
        .get(`${API_BASE_URL}/unique-trade-mark-type`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
          },
        })
        .then((res) => {
          console.log(res.data, "res");
          setTradeMarkList(res?.data?.data);
        });
      console.log(response.result, "response");
    } catch (error) {
      console.error(error.message);
    }
    // setLoading(false);
  };

  const getFillingModeList = async () => {
    try {
      const { data: response } = await axios
        .get(`${API_BASE_URL}/unique-filing-mode`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
          },
        })
        .then((res) => {
          console.log(res.data, "fillingMode");
          setFilingModeList(res?.data?.data);
        });

      console.log(response.result, "response");
    } catch (error) {
      console.error(error.message);
    }
    // setLoading(false);
  };

  const getTmCategoryList = async () => {
    // setLoading(true);
    try {
      const { data: response } = await axios
        .get(`${API_BASE_URL}/unique-tm-category`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
          },
        })
        .then((res) => {
          console.log(res.data, "fillingMode");
          setTmCategoryList(res?.data.data);
        });
      console.log(response.result, "response");
    } catch (error) {
      console.error(error.message);
    }
    // setLoading(false);
  };

  const handleTradeMarkChange = async (e) => {
    setLoading(true);
    const { value, checked } = e?.target;
    const { trademark_info } = tradeMarkInfo;
    setPageApi(1);
    if (checked) {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        trademark_info: [...trademark_info, e.target.value],
      });
    } else {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        trademark_info: trademark_info?.filter((e) => e !== value),
      });
    }
    setLoading(false);
  };

  const handleStatusChange = (e) => {
    setLoading(true);
    const { value, checked } = e?.target;
    const { status } = tradeMarkInfo;
    setPageApi(1);

    if (checked) {
      console.log(tradeMarkInfo);
      setTradeMarkInfo({
        ...tradeMarkInfo,
        status: [...status, value],
      });
    } else {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        status: status?.filter((e) => e !== value),
      });
    }
    setLoading(false);
  };

  const handleFillingChange = (e) => {
    setLoading(true);
    const { value, checked } = e.target;
    const { filling_mode } = tradeMarkInfo;
    setPageApi(1);

    if (checked) {
      console.log(tradeMarkInfo);
      setTradeMarkInfo({
        ...tradeMarkInfo,
        filling_mode: [...filling_mode, value],
      });
    } else {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        filling_mode: filling_mode?.filter((e) => e !== value),
      });
    }
    setLoading(false);
  };

  const handleTmCategoryChange = (e) => {
    setLoading(true);
    const { value, checked } = e?.target;
    const { tm_category } = tradeMarkInfo;
    setPageApi(1);

    if (checked) {
      console.log(tradeMarkInfo);
      setTradeMarkInfo({
        ...tradeMarkInfo,
        tm_category: [...tm_category, value],
      });
    } else {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        tm_category: tm_category?.filter((e) => e !== value),
      });
    }
    setLoading(false);
  };

  const onDateChange = (e) => {
    setLoading(true);
    console.log(e.target.value, "etarget");
    setPageApi(1);

    if (e.target.name == "start_date") {
      setDate({
        ...date,
        start_date: e.target.value,
      });
    } else {
      setDate({
        ...date,
        end_date: e.target.value,
      });
    }
    setLoading(false);
  };

  const getDynamicParam = (selectvalue, e, tradeMarkInfo, date) => {
    console.log(selectvalue, e, "console");
    let params = {
      name: "",
      start_date: date.start_date,
      end_date: date.end_date,
      status: tradeMarkInfo?.status?.toString().replace(/\,/g, "#"),
      filing_mode: tradeMarkInfo?.filling_mode?.toString().replace(/\,/g, "#"),
      tm_category: tradeMarkInfo?.tm_category?.toString().replace(/\,/g, "#"),
      trade_mark_type: tradeMarkInfo?.trademark_info
        ?.toString()
        .replace(/\,/g, "#"),
      class_type: "",
      trademark_number: "",
      proprietor_name: "",
      attorney_name: "",
      page: pageApi,
      limit: "10",
      user_id: localStorage.getItem("trade_user_id"),
    };

    if (selectvalue == "Word Mark") {
      params = {
        name: e,

        start_date: date.start_date,
        end_date: date.end_date,
        status: tradeMarkInfo?.status?.toString().replace(/\,/g, "#"),
        filing_mode: tradeMarkInfo?.filling_mode
          ?.toString()
          .replace(/\,/g, "#"),
        tm_category: tradeMarkInfo?.tm_category?.toString().replace(/\,/g, "#"),
        trade_mark_type: tradeMarkInfo?.trademark_info
          ?.toString()
          .replace(/\,/g, "#"),
        class_type: "",
        trademark_number: "",
        proprietor_name: "",
        attorney_name: "",
        page: pageApi,
        limit: "10",
        user_id: localStorage.getItem("trade_user_id"),
      };
    }
    if (selectvalue == "Class Type") {
      params = {
        name: "",

        start_date: date.start_date,
        end_date: date.end_date,
        status: tradeMarkInfo?.status?.toString().replace(/\,/g, "#"),
        filing_mode: tradeMarkInfo?.filling_mode
          ?.toString()
          .replace(/\,/g, "#"),
        tm_category: tradeMarkInfo?.tm_category?.toString().replace(/\,/g, "#"),
        trade_mark_type: tradeMarkInfo?.trademark_info
          ?.toString()
          .replace(/\,/g, "#"),
        class_type: e,
        trademark_number: "",
        proprietor_name: "",
        attorney_name: "",
        page: pageApi,
        limit: "10",
        user_id: localStorage.getItem("trade_user_id"),
      };
    }
    if (selectvalue == "Proprietor Name") {
      params = {
        name: "",

        start_date: date.start_date,
        end_date: date.end_date,
        status: tradeMarkInfo?.status?.toString().replace(/\,/g, "#"),
        filing_mode: tradeMarkInfo?.filling_mode
          ?.toString()
          .replace(/\,/g, "#"),
        tm_category: tradeMarkInfo?.tm_category?.toString().replace(/\,/g, "#"),
        trade_mark_type: tradeMarkInfo?.trademark_info
          ?.toString()
          .replace(/\,/g, "#"),
        class_type: "",
        trademark_number: "",
        proprietor_name: e,
        attorney_name: "",
        page: pageApi,
        limit: "10",
        user_id: localStorage.getItem("trade_user_id"),
      };
    }
    if (selectvalue == "Attorney Name") {
      params = {
        name: "",

        start_date: date.start_date,
        end_date: date.end_date,
        status: tradeMarkInfo?.status?.toString().replace(/\,/g, "#"),
        filing_mode: tradeMarkInfo?.filling_mode
          ?.toString()
          .replace(/\,/g, "#"),
        tm_category: tradeMarkInfo?.tm_category?.toString().replace(/\,/g, "#"),
        trade_mark_type: tradeMarkInfo?.trademark_info
          ?.toString()
          .replace(/\,/g, "#"),
        class_type: "",
        trademark_number: "",
        proprietor_name: "",
        attorney_name: e,
        page: pageApi,
        limit: "10",
        user_id: localStorage.getItem("trade_user_id"),
      };
    }
    if (selectvalue == "Trademark Number") {
      params = {
        name: "",

        start_date: date.start_date,
        end_date: date.end_date,
        status: tradeMarkInfo?.status?.toString().replace(/\,/g, "#"),
        filing_mode: tradeMarkInfo?.filling_mode
          ?.toString()
          .replace(/\,/g, "#"),
        tm_category: tradeMarkInfo?.tm_category?.toString().replace(/\,/g, "#"),
        trade_mark_type: tradeMarkInfo?.trademark_info
          ?.toString()
          .replace(/\,/g, "#"),
        class_type: "",
        trademark_number: e,
        proprietor_name: "",
        attorney_name: "",
        page: pageApi,
        limit: "10",
        user_id: localStorage.getItem("trade_user_id"),
      };
    }

    return params;
  };

  const sendData = async () => {
    setLoading(true);
    setTableLoader(true)
    setSuggestions([]);

    console.log(
      getDynamicParam(selectedOption, searchedValue, tradeMarkInfo, date),
      "dynamicparams"
    );
    console.log(searchInputRef, "searchInputRef");
    try {
      const params = {
        name: "",
        start_date: date.start_date,
        end_date: date.end_date,
        status: tradeMarkInfo?.status?.toString().replace(/\,/g, "#"),
        filing_mode: tradeMarkInfo?.filling_mode
          ?.toString()
          .replace(/\,/g, "#"),
        tm_category: tradeMarkInfo?.tm_category?.toString().replace(/\,/g, "#"),
        trade_mark_type: tradeMarkInfo?.trademark_info
          ?.toString()
          .replace(/\,/g, "#"),
        class_type: "",
        trademark_number: "",
        proprietor_name: "",
        attorney_name: "",
        page: pageApi,
        limit: "10",
        user_id: localStorage.getItem("trade_user_id"),
      };
      const response = await axios
        .get(`${API_BASE_URL}/get-trademark-list`, {
          params: getDynamicParam(
            selectedOption,
            searchedValue,
            tradeMarkInfo,
            date
          ),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
          },
        })
        .then((res) => {
          setTableLoader(false);
          console.log(res.data, "resiii");
          console.log(API_BASE_URL, "resii");
          setExcelLink(res?.data?.excel_link);
          setSearcedData(res?.data?.result);
          const totalPageCount = Math.ceil(res?.data?.total / 10);
          setPageSize(totalPageCount);
          console.log(totalPageCount, "total");
          
        });
    } catch (error) {
      console.log(error);
      // console.log(tradeMarkInfo.tm_category.toString().replace(/\,/g, '#'), tradeMarkInfo.filling_mode.toString().replace(/\,/g, '#'))
    }
    setLoading(false);
    setTableLoader(false);
  };

  const selectChange = (e) => {
    console.log(e.value, "e");
    setSearchedvalue("");
    setSelectedOption(e.value);
  };

  const onSearchData = async (e) => {
    // console.log(selectedOption,"selecttdopttions")
    setLoad(true);
    setSearchedvalue(e.target.value);
    // setPageApi(1);
    console.log(e);
    var params = {
      word_mark: "",
      class_type: "",
      proprietor_name: "",
      attorney_name: "",
      trademark_number: "",
    };

    if (selectedOption == "Word Mark") {
      console.log(selectedOption, "selectdd");
      params = {
        word_mark: e.target.value,
        class_type: "",
        proprietor_name: "",
        attorney_name: "",
        trademark_number: "",
      };
    }

    if (selectedOption == "Class Type") {
      console.log(selectedOption, "selectdd");
      // console.log(e.target.value)
      params = {
        word_mark: "",
        class_type: e.target.value,
        proprietor_name: "",
        attorney_name: "",
        trademark_number: "",
      };
    }
    if (selectedOption == "Proprietor Name") {
      params = {
        word_mark: "",
        class_type: "",
        proprietor_name: e.target.value,
        attorney_name: "",
        trademark_number: "",
      };
    }
    if (selectedOption == "Attorney Name") {
      params = {
        word_mark: "",
        class_type: "",
        proprietor_name: "",
        attorney_name: e.target.value,
        trademark_number: "",
      };
    }
    if (selectedOption == "Trademark Number") {
      params = {
        word_mark: "",
        class_type: "",
        proprietor_name: "",
        attorney_name: "",
        trademark_number: e.target.value,
      };
    }
    if (e.target.value !== "") {
      try {
        setLoad(true);
        await axios
          .get(`${API_BASE_URL}/auto-suggestion`, {
            params: params,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log(res.data, "resiii");
            setSuggestions(res?.data?.data);
            console.log(params, "paas");
            setLoad(false);
          });
      } catch (error) {
        console.log(error);
        setLoad(false);
        // console.log(tradeMarkInfo.tm_category.toString().replace(/\,/g, '#'), tradeMarkInfo.filling_mode.toString().replace(/\,/g, '#'))
      }
    } else {
      setSuggestions([]);
      setLoad(false);
    }
  };

  const enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      //Do stuff in here
      setPageApi(1);
      sendData();
    }
  };




  const handleDelete = (id) => {
    const result = window.confirm("Do you want to delete ?");

    if (result) {
      axios
        .get(`${API_BASE_URL}/enquiry-delete/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === "200") {
            toast.success("Delete successully");
            axios
              .get(
                `${API_BASE_URL}/list-enquiry`,

                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                // setListPageData(response.data.result.reverse());
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    }
  };

  const handleActive = async (status, id) => {
    let updatedStatus = status == 0 ? 1 : 0;
    const res = await axios.post(
      `${API_BASE_URL}/manage-user-status?status=${updatedStatus}&user_id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const response = JSON.parse(JSON.stringify(res));
    console.log(response);
    if (response.data.status === 200) {
      // getUserList();
      if (status == 1) {
        toast.success("Active successfully");
      } else {
        toast.success("Inactive successfully");
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // setFilterData({ ...filterData, [name]: value });
  };

  const selectStyles = (errorFieldName) => ({
    control: (provided) => ({
      ...provided,
      borderColor: provided.borderColor,
      boxShadow: provided.boxShadow,
      minHeight: 22,
      height: 33,
    }),
  });


  return (
    <>
   <div id="trade-data" className="main-content ">
      <div className="page-content">
        <div className="container-fluid">
          
              <div class="app-main__outer">
                <div class="app-main__inner">
                  <div class="app-page-title">
                    <div class="d-flex">
                      <div class="col-md-2 ps-0 pe-1">
                        <Select
                          placeholder="Word Mark"
                          defaultValue={selectedOption}
                          onChange={(e) => selectChange(e)}
                          options={sampledata}
                          isSearchable={false}
                          isOptionDisabled={(option) => !option.disabled}
                        />
                      </div>
                      <div
                        class="col-md-5 ps-0"
                        style={{ zIndex: 1, position: "relative" }}
                      >
                        <input
                          type="text"
                          onChange={onSearchData}
                          value={searchedValue}
                          onKeyDown={enterPressed}
                          class="form-control"
                          placeholder="Search here....."
                        />
                      </div>
                      <div class="col-md-3 ps-0">
                        <button
                          type="button"
                          onClick={() => {
                            setPageApi(1);
                            sendData();
                          }}
                          class="btn btn-primary search"
                        >
                          {/* {load && <i class="fa fa-spinner fa-spin"></i>} */}
                          Search <i class="fa fa-search ms-1"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-xl-9 mt-3">
                      <div
                        class="card d-flex alingn-center justify-content-center"
                        style={{ minHeight: 400 }}
                      >
                            {tableLoader ? (
                      <Loader />
                    ) : (
                      <div className="table-responsive">
                        <table className="table align-middle table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th className="align-middle">Name</th>
                              <th className="align-middle">Logo</th>
                              <th className="align-middle">class</th>
                              {/* <th className="align-middle">Plan </th>
                              <th className="align-middle">Plan Type</th>
                              <th className="align-middle">Status</th> */}
                              <th className="align-middle">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {searchedData?.map((item) => (
                              <>
                                <tr>
                                  <td>{item.name}</td>

                                  <td>
                                <img
                                  class="card-img-top  rounded "
                                  src={item.logo ? item.logo :'https://trademarksearch.app/assets/img/no_logo.png'}
                                  alt="Card image cap"
                                  width="50"
                                  height="65"
                                />
                              </td>
                              <td>{item.class}</td>
                                  <td>
                                    <Link                               
                                      to={`/cms/manage-trademark-data/view-trademark?id=${item.trade_mark}`}
                                      type="button"
                                      className="btn btn-success btn-sm btn-rounded waves-effect waves-light"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Link>
                                    {/* <Link
                                      to={`/cms/manageUserData/editUser/${item.user_id}`}
                                      type="button"
                                      className="btn btn-primary btn-sm btn-rounded waves-effect waves-light ms-2 "
                                    >
                                      <i className="fa fa-pen"></i>
                                    </Link>
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-sm btn-rounded waves-effect waves-light ms-2"
                                      onClick={() => {
                                        handleDelete(item.id);
                                      }}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button> */}

                                    {/* <button
                                      type="button"
                                      className="btn btn-warning btn-sm btn-rounded  ms-2"
                                      onClick={() => {
                                        handleActive(item.status, item.user_id);
                                      }}
                                    >
                                      <i className="fa fa-ban"></i>
                                    </button> */}
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                   )} 
                    { pageSize !== 1 && (
                      <div className="d-flex justify-content-center mt-2">
                        <Pagination
                          count={pageSize}
                          page={pageApi}
                          onChange={(e, value) => {
                            console.log(value, "vslue");
                            setPageApi(value);
                          }}
                          color="primary"
                        />
                      </div>
                    )}
                      </div>
                    </div>
                    <div class="col-md-6 col-xl-3">
                      <div class="card">
                        <div class="card-header bg-dark text-white">
                          <p class="mb-0">Filter by Trade Mark</p>
                        </div>
                        <div class="card-body">
                          {tradeMarkList?.map((item, index) => {
                            return (
                              <>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name="trademark_info"
                                    value={
                                      item.status ? item?.search_field : null
                                    }
                                    id={`trademark-checkbox-${index}`}
                                    onChange={handleTradeMarkChange}
                                    disabled={!item?.status}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    {item?.search_field}
                                  </label>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                      <div class="card mt-3">
                        <div class="card-header bg-dark text-white">
                          <p class="mb-0">Filter by Date</p>
                        </div>
                        <div class="card-body">
                          <div>
                            <label>Start Date</label>
                            <input
                              className="form-control"
                              style={{ height: "29px" }}
                              type="date"
                              name="start_date"
                              onChange={onDateChange}
                              ref={startDateInputRef}
                            />
                          </div>
                          <div className="mt-2">
                            <label>End Date</label>
                            <input
                              style={{ height: "29px" }}
                              className="form-control"
                              type="date"
                              name="end_date"
                              onChange={onDateChange}
                              ref={endDateInputRef}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="card mt-3">
                        <div class="card-header bg-dark text-white">
                          <p class="mb-0">Filter by Status</p>
                        </div>
                        <div class="card-body">
                          {statusList?.map((item, index) => {
                            return (
                              <>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value={
                                      item.status ? item?.search_field : null
                                    }
                                    name="status"
                                    id={`trademark-checkbox-${index}`}
                                    onChange={handleStatusChange}
                                    disabled={!item.status}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    {item?.search_field}
                                  </label>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                      <div class="card mt-3">
                        <div class="card-header bg-dark text-white">
                          <p class="mb-0">Filter by Filling Mode</p>
                        </div>
                        <div class="card-body">
                          {fillingModeList?.map((item, index) => {
                            return (
                              <>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name="filling_mode"
                                    value={
                                      item?.status ? item?.search_field : null
                                    }
                                    id={`filling-checkbox-${index}`}
                                    onChange={handleFillingChange}
                                    disabled={!item.status}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    {item?.search_field}
                                  </label>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                      <div class="card mt-3">
                        <div class="card-header bg-dark text-white">
                          <p class="mb-0">Filter by Tm Category</p>
                        </div>
                        <div class="card-body">
                          {tmCategoryList?.map((item, index) => {
                            return (
                              <>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name="tm_category"
                                    value={
                                      item?.status ? item?.search_field : null
                                    }
                                    id={`tm_category-checkbox-${index}`}
                                    onChange={handleTmCategoryChange}
                                    disabled={!item.status}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    {item?.search_field}
                                  </label>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ManageUserData;
