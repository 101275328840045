import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const EditPricingPlan = () => {
  const [formData, setFormData] = useState({});
  const [titleError, setTitleError] = useState("");
  const [shortOrderError, setShortOrderError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [imagePreview, setImagePreview] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData, "form");

    if (!formData.title) {
      setTitleError("Please enter title");
    } else {
      setTitleError("");
    }
    if (!formData.shortOrderError) {
      setShortOrderError("Please enter short order");
    } else {
      setShortOrderError("");
    }
    if (!formData.videolink) {
      setVideoLink("Please enter video link ");
    } else {
      setVideoLink("");
    }

    if (!formData.description) {
      setDescriptionError("Please enter the description");
    } else {
      setDescriptionError("");
    }

    if (!formData.image) {
      setImageError("Please select the image");
    } else {
      setImageError("");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Edit Plan </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/managepricingplans"}>
                          Manage Plans Page
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">Edit Plan </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Edit Price</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Title
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="title"
                                  type="text"
                                  className={
                                    titleError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter title..."
                                  onChange={handleInputChange}
                                />
                                {titleError && (
                                  <div className="text-danger">
                                    {titleError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Currency type
                              </label>
                              <div className="col-lg-10">
                                <select
                                  className="form-select"
                                  styles="max-width: 90px;"
                                  name="currencytype"
                                  onChange={handleInputChange}
                                >
                                  <option value="BT">BTC</option>
                                  <option value="ET">ETH</option>
                                  <option value="LT">LTC</option>
                                </select>
                                {/* {parentLinkError && (
                                <div className="text-danger">
                                  {parentLinkError}
                                </div>
                              )} */}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                New Price
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="newprice"
                                  type="text"
                                  className={
                                    shortOrderError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter new price..."
                                  onChange={handleInputChange}
                                />
                                {shortOrderError && (
                                  <div className="text-danger">
                                    {shortOrderError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Old Price
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="oldprice"
                                  type="text"
                                  className={
                                    shortOrderError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter old price ..."
                                  onChange={handleInputChange}
                                />
                                {shortOrderError && (
                                  <div className="text-danger">
                                    {shortOrderError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Color
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="color"
                                  type="text"
                                  className={
                                    shortOrderError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter color ..."
                                  onChange={handleInputChange}
                                />
                                {shortOrderError && (
                                  <div className="text-danger">
                                    {shortOrderError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Duration type
                              </label>
                              <div className="col-lg-10">
                                <select
                                  className="form-select"
                                  styles="max-width: 90px;"
                                  name="durationtype"
                                  onChange={handleInputChange}
                                >
                                  <option value="BT">BTC</option>
                                  <option value="ET">ETH</option>
                                  <option value="LT">LTC</option>
                                </select>
                                {/* {parentLinkError && (
                                <div className="text-danger">
                                  {parentLinkError}
                                </div>
                              )} */}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Duration
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="duration"
                                  type="text"
                                  className={
                                    videoLink
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter duration ..."
                                  onChange={handleInputChange}
                                />
                                {videoLink && (
                                  <div className="text-danger">{videoLink}</div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Description
                              </label>
                              <div className="col-lg-10">
                                <textarea
                                  id="taskdesc-editor"
                                  name="description"
                                  // className="taskdesc"
                                  className={
                                    descriptionError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  onChange={handleInputChange}
                                ></textarea>
                                {descriptionError && (
                                  <div className="text-danger">
                                    {descriptionError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Features
                              </label>
                              <div className="col-lg-10">
                                <textarea
                                  id="taskdesc-editor"
                                  name="description"
                                  // className="taskdesc"
                                  className={
                                    descriptionError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  onChange={handleInputChange}
                                ></textarea>
                                {descriptionError && (
                                  <div className="text-danger">
                                    {descriptionError}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            <button type="submit" className="btn btn-primary">
                              Edit Plans
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPricingPlan;
