import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import Loader from "../../Loader/loader";
import moment from "moment";

const EditUser = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    subscription_status: "",
    is_visible_mobile: "",
    plan_type: "",
    increase_api_count_by: "",
    plan_name: "",
    given_api_count: "",
    subscription_start_date: "",
    subscription_end_date: "",
    used_api_count:"",
    password:"",
    subscription_type:""
  });
  const { id } = useParams();
  const {
    name,
    email,
    mobile,
    subscription_status,
    is_visible_mobile,
    plan_type,
    used_api_count,
    increase_api_count_by,
    plan_name,
    given_api_count,
    subscription_start_date,
    subscription_end_date,
    password,
    subscription_type
  } = formData;
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [nameError, setNameError] = useState("");
  const [pageName, setPageName] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [subscriptionStatusError, setSubscriptionStatusError] = useState("");
  const [isVisibleError, setVisibleError] = useState("");
  const [planTypeError, setPlanTypeError] = useState("");
  const [usedCountError, setUsedCountError] = useState("");

  const [plans, setPlans] = useState([]);

  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  const navigate = useNavigate();

  let hasError = false;

  useEffect(() => {
    setToken(localStorage.getItem("cms_token"));
    // ListPage();
    loadplan2();
    getUserDetail();
  }, []);

  const getUserDetail = async () => {
    setIsLoading(true);
    const res = await axios.get(`${API_BASE_URL}/get-user-details`, {
      params: { user_id: id },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.data.status == 200) {
      console.log(res.data.data, "res.data.result.page_name");
      setFormData({ 
        ...formData,
        name: res.data.data.name,
        email: res.data.data.email,
        mobile: res.data.data.mobile,
        subscription_status: res.data.data.subscription_status == 'Subscribed' ? '1' : '0',
        is_visible_mobile: res.data.data.is_mobile_email_visible,
        plan_type: res.data.data.plan_type,
        password:res.data.data.plain_password,
        plan_name: res.data.data.plan_id,
        given_api_count: res.data.data.given_api_count,
        subscription_start_date: res.data.data.subscription_start_date,
        subscription_end_date:moment(res.data.data.subscription_end_date,"DD/MM/YYYY").format('YYYY-MM-DD'),
        used_api_count:res.data.data.used_api_cont,
        subscription_type:res.data.data.subscription_type,
      });
      console.log( moment(res.data.data.subscription_end_date,"DD/MM/YYYY").format('YYYY-MM-DD')," moment().format(res.data.data.subscription_end_date)")
      setIsLoading(false);
    }
  };


  const loadplan2 = async () => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const plan_data = await axios.get(API_BASE_URL + "/get-subscrpton-plan");
      const pricing_plan = JSON.parse(JSON.stringify(plan_data.data));
      console.log(plan_data.data.data, "plan_data");

      console.log(pricing_plan, "pricing_plan.data");

      if (pricing_plan.status == 200) {
        setPlans(plan_data.data.data);
      };
    } catch (e) {
      return false;
    }
  };

  const subscription_status_Options = [
    { value: '0', label: "Unsubscribed" },
    { value: '1', label: "Subscribed" },
  ];

  const is_visible_Options = [
    { value: 0, label: "Not Apply" },
    { value: 1, label: "Apply" },
  ];

  const in_Menu_Options = [
    { value: 'monthly', label: "Monthly" },
    { value: 'annualy', label: "Annualy" },
    { value: 'Free Trial', label: "Free Trial" },
  ];

  const subscription_type_Options = [
    { value: 'free_trial', label: "Free Trail" },
    { value: 'paid', label: "Paid" },
  ];

  const plan_name_options = [
    { value: 'basic', label: "Basic" },
    { value: 'plus', label: "Plus" },
    { value: 'pro', label: "Pro" },

];

 

  const planOption = [];
 plans?.forEach((item) => {
    planOption.push({ value: item.id, label: item.name });
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData, "form");

    if (!formData.name) {
      setNameError("Please enter name");
      hasError = true;
    } else {
      setNameError("");
    }
    if (!formData.email) {
      setEmailError("Please enter email");
      hasError = true;
    } else {
      setEmailError("");
    }
    if (!formData.mobile) {
      setMobileError("Please enter mobile ");
      hasError = true;
    } else {
      setMobileError("");
    }
    if (!formData.password) {
      setPasswordError("Please enter Password");
      hasError = true;
    } else {
      setPasswordError("");
    }

    if (!formData.subscription_status) {
      setSubscriptionStatusError("Please select");
      hasError = true;
    } else {
      setSubscriptionStatusError("");
    }
    // if (!formData.is_visible_mobile) {
    //   setVisibleError("Please select");
    //   hasError = true;
    // } else {
    //   setVisibleError("");
    // }
    // if (!formData.plan_type) {
    //   setPlanTypeError("Please enter mobile ");
    //   hasError = true;
    // } else {
    //   setPlanTypeError("");
    // }

    if (!formData.subscription_status) {
      setSubscriptionStatusError("Please enter the description");
      hasError = true;
    } else {
      setSubscriptionStatusError("");
    }

    if (!hasError) {
      let form ={
        user_id: id,
        name: name,
        mobile: mobile,
        subscription_status: subscription_status,
        is_visible_mobile:is_visible_mobile,  
        plan_type: plan_type,
        subscription_type: 'paid',
        used_api_cont: used_api_count,
        increase_api_count_by:parseInt(increase_api_count_by),
        plan_id: plan_name,
        subscription_end_date:moment(subscription_end_date,'YYYY-MM-DD').format('DD-MM-YYYY')  ,
        given_api_count:given_api_count,
        password:password
      }
      axios
        .post(
          `${API_BASE_URL}/update-user-details`,form,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status === 200) {
            navigate("/cms/manageUserData/UserData");
            toast.success("Edited successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.msg)
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    console.log(event.target, "mame");
    console.log(name, value, "mame");
    console.log(formData, "valueeeeeeee");

    if (name === "subscription_end_date") {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const selectStyles = (errorFieldName) => ({
    control: (provided) => ({
      ...provided,
      borderColor: errors[errorFieldName] ? "#dc3545" : provided.borderColor,
      boxShadow: errors[errorFieldName]
        ? "0 0 0 0.2rem rgba(220, 53, 69, 0.25)"
        : provided.boxShadow,
      minHeight: 22,
      height: 33,
    }),
  });

  const handleSelect = (fieldName, selectedOption) => {
    // setSelectedOption(selectedOption);
    console.log(selectedOption, "opton");
    setFormData({ ...formData, [fieldName]: selectedOption.value });
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Edit User Detail </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/cms/manageseopage"}>Manage User List</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Edit User Detail
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {isLoading ? (
                      <Loader />
                    ) : (
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Edit User Detail</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Name
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="name"
                                  type="text"
                                  value={name}
                                  className={
                                    nameError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter page url..."
                                  onChange={handleInputChange}
                                />
                                {nameError && (
                                  <div className="text-danger">
                                    {nameError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Email
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="email"
                                  type="text"
                                  value={email}
                                  className={
                                    emailError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Email..."
                                  onChange={handleInputChange}
                                />
                                {emailError && (
                                  <div className="text-danger">
                                    {emailError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Password
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="password"
                                  type="text"
                                  value={password}
                                  className={
                                    emailError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Password..."
                                  onChange={handleInputChange}
                                />
                                {passwordError && (
                                  <div className="text-danger">
                                    {passwordError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Mobile
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="mobile"
                                  type="text"
                                  value={mobile}
                                  className={
                                    mobileError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter meta keywords..."
                                  onChange={handleInputChange}
                                />
                                {mobileError && (
                                  <div className="text-danger">
                                    {mobileError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Subscription Type
                              </label>
                              <div className="col-lg-10">
                                <Select
                                  className={`custom-select custom-select-sm`}
                                  options={subscription_type_Options}
                                  value={subscription_type_Options.filter(
                                    (option) =>
                                      option.value == subscription_type
                                  )}
                                  styles={selectStyles("subscription_type")}
                                  name="subscription_type"
                                  onChange={(selectedOption) =>
                                    handleSelect(
                                      "subscription_type",
                                      selectedOption
                                    )
                                  }
                                >
                                  <option value="">Select an option</option>
                                  {subscription_type_Options?.map((item) => (
                                    <option value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </Select>
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Subscription Status
                              </label>
                              <div className="col-lg-10">
                                <Select
                                  className={`custom-select custom-select-sm`}
                                  options={subscription_status_Options}
                                  value={subscription_status_Options.filter(
                                    (option) =>
                                      option.value == subscription_status
                                  )}
                                  styles={selectStyles("in_menu")}
                                  name="subscription_status"
                                  onChange={(selectedOption) =>
                                    handleSelect(
                                      "subscription_status",
                                      selectedOption
                                    )
                                  }
                                >
                                  <option value="">Select an option</option>
                                  {subscription_status_Options?.map((item) => (
                                    <option value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </Select>
                                {subscriptionStatusError && (
                                  <div className="text-danger">
                                    {subscriptionStatusError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Is Visible Mobile
                              </label>
                              <div className="col-lg-10">
                                <Select
                                  className={`custom-select custom-select-sm`}
                                  options={is_visible_Options}
                                  value={is_visible_Options.filter(
                                    (option) =>
                                      option.value == is_visible_mobile
                                  )}
                                  styles={selectStyles("is_visible_mobile")}
                                  name="is_visible_mobile"
                                  onChange={(selectedOption) =>
                                    handleSelect(
                                      "is_visible_mobile",
                                      selectedOption
                                    )
                                  }
                                >
                                  <option value="">Select an option</option>
                                  {in_Menu_Options?.map((item) => (
                                    <option value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </Select>
                                {/* {isVisibleError && (
                                  <div className="text-danger">
                                    {isVisibleError}
                                  </div>
                                )} */}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Plan Type
                              </label>
                              <div className="col-lg-10">
                                <Select
                                  className={`custom-select custom-select-sm`}
                                  options={in_Menu_Options}
                                  value={in_Menu_Options.filter(
                                    (option) =>
                                      option.value == plan_type
                                  )}
                                  styles={selectStyles("plan_type")}
                                  name="plan_type"
                                  onChange={(selectedOption) =>
                                    handleSelect(
                                      "plan_type",
                                      selectedOption
                                    )
                                  }
                                >
                                  <option value="">Select an option</option>
                                  {in_Menu_Options?.map((item) => (
                                    <option value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </Select>
                                {/* {planTypeError && (
                                  <div className="text-danger">
                                    {planTypeError}
                                  </div>
                                )} */}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Given Api Count
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="given_api_count"
                                  type="text"
                                  value={given_api_count}
                                  className={
                                   "form-control "
                                  }
                                  disabled={true}
                        
                                  onChange={(e)=>handleInputChange(e)}
                                />
                              </div>
                            </div>
                            
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Used Api Count
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="used_api_count"
                                  type="text"
                                  value={used_api_count}
                                  className={
                                   "form-control "
                                  }
                                  placeholder="Enter meta keywords..."
                                  onChange={(e)=>handleInputChange(e)}
                                />
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Increase Api Count By
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="increase_api_count_by"
                                  type="text"
                                  value={increase_api_count_by}
                                  className={
                                     "form-control "
                                  }
                                  placeholder="Enter meta keywords..."
                                  onChange={handleInputChange}
                                />
                               
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Plan Name
                              </label>
                              <div className="col-lg-10">
                                <Select
                                  className={`custom-select custom-select-sm`}
                                  options={planOption}
                                  value={planOption.filter(
                                    (option) =>
                                      option.value == plan_name
                                  )}
                                  styles={selectStyles("plan_name")}
                                  name="plan_name"
                                  onChange={(selectedOption) =>
                                    handleSelect(
                                      "plan_name",
                                      selectedOption
                                    )
                                  }
                                >
                                  <option value="">Select an option</option>
                                  {planOption?.map((item) => (
                                    <option value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </Select>
                                
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Subscription End Date
                              </label>
                              <div className="col-lg-10">
                              <input
                                  id="taskname"
                                  name="subscription_end_date"
                                  type="date"
                                  value={subscription_end_date}
                                  className={
                                   "form-control "
                                  }
                                  placeholder="Enter meta keywords..."
                                  onChange={handleInputChange}
                                />
                               
                              </div>
                            </div>

                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            <button type="submit" className="btn btn-primary">
                              Edit User
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>)}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUser;
