import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../../config";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import { Pagination } from "@mui/material";
import moment from "moment";

const ManageDocuments = () => {
  const [features, setFeatures] = useState();
  const [data, setData] = useState([]);
  const [listPageData, setListPageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  const [status, setStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [pageSize, setPageSize] = useState();
  const [pageApi, setPageApi] = useState(1);
  const [totalUser, setTotalUser] = useState(0);

  const [tradeNo, setTradeNo] = useState(null);

  useEffect(() => {
    setToken(localStorage.getItem("cms_token"));
    getWebPages();
  }, [pageApi]);

  const getWebPages = async () => {
    setIsLoading(true);
    try {
      const response = await axios
        .get(`${API_BASE_URL}/get-total-documents`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            trademark_number: tradeNo ? tradeNo : "all",
            page: pageApi,
            limit: 10,
          },
        })
        .then((response) => {
          setIsLoading(true);
          if (response?.data?.status) {
            const pricing_plan = JSON.parse(JSON.stringify(response.data));
            setData(pricing_plan.documents);
            setTotalUser(response?.data?.total_documents);
            const totalPageCount = Math.ceil(
              response?.data?.total_documents / 10
            );
            setPageSize(totalPageCount);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });

      //   dispatch(create_plan({plan:pricing_plan.data.plan_list}));
    } catch (e) {
      return null;
    }
  };

  const onTrademarkChange = (e) => {
    setTradeNo(e.target.value);
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Manage Documents </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Manage Documents
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center mb-4">
                  <h4 className="mb-sm-0 font-size-16 me-4">
                    Search By Trademark no.{" "}
                  </h4>
                  <input
                    type="text"
                    onChange={onTrademarkChange}
                    className="me-3 p-2"
                  />
                  <button className="date-submit" onClick={getWebPages}>Search</button>
                </div>
              </div>
            </div>
            {/* <div className="row d-flex text-end mb-2">
              <div className="col-lg-12 mb-2 ">
                <Link
                  to={"/cms/managePlan/add-subscription-plan"}
                  data-repeater-create
                  type="button"
                  className="btn btn-success inner"
                  value="Add Number"
                >
                  Add Subscription Plan
                </Link>
              </div>
            </div> */}

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">
                      Documents List ({totalUser})
                    </h4>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <div className="table-responsive">
                        <table className="table align-middle table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th className="align-middle">Company Name</th>
                              <th className="align-middle">Trademark no.</th>
                              <th className="align-middle">Date</th>
                              {/* <th className="align-middle">Document Name</th> */}
                              <th className="align-middle">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.map((item) => (
                              <>
                                <tr>
                                  <td>{item.company_name}</td>
                                  <td>{item.trademark_number}</td>
                                  <td>
                                    {" "}
                                    {moment(item.created_at).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td>
                                    <Link
                                      to={`${item.doc_full_path}`}
                                      target="_blank"
                                      type="button"
                                      className="btn btn-success btn-sm btn-rounded waves-effect waves-light"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Link>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {!isLoading && pageSize !== 1 && (
                      <div className="d-flex justify-content-center mt-2">
                        <Pagination
                          count={pageSize}
                          page={pageApi}
                          onChange={(e, value) => {
                            console.log(value, "vslue");
                            setTradeNo(null)
                            setPageApi(value);
                          }}
                          color="primary"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageDocuments;
