import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import Navbar from "../../Layouts/Navbar";
import Footer from "../../Layouts/Footer";
import Pagination from "@mui/material/Pagination";
import { toast } from "react-toastify";
import Loader from "../../Loader/loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const NegativeEnquiry = () => {
  const [listPageData, setListPageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  const [status, setStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [pageSize, setPageSize] = useState();
  const [pageApi, setPageApi] = useState(1);
  const [totalUser, setTotalUser] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterData, setFilterData] = useState({
    name: "",
    company: "",
    state: "",
    start_date: "",
    end_date: "",
    email: "",
    phone: "",
  });
  const { name, company, state, start_date, end_date, email, phone } =
    filterData;

  useEffect(() => {
    setToken(localStorage.getItem("cms_token"));
    loadContent();
  }, [pageApi]);

  const loadContent = async () => {
    setIsLoading(true);
    let param = {
      ...filterData,
      start_date: startDate
        ? moment(startDate).utc().add(1, "d").format("YYYY-MM-DD")
        : "",
      end_date: endDate
        ? moment(endDate).utc().add(1, "d").format("YYYY-MM-DD")
        : "",
      is_positive: 0,
      page: pageApi,
      limit: 10,
    };
    await axios
      .get(`${API_BASE_URL}/get-trademark-enquiries`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: param,
      })
      .then((response) => {
        setListPageData(response.data.data);
        setTotalUser(response?.data?.total_records);
        const totalPageCount = Math.ceil(response?.data?.total_records / 10);
        setPageSize(totalPageCount);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handlePositive = (id) => {
    const result = window.confirm(
      "Do you want to make this enquiry positive ?"
    );
    if (result) {
      setIsLoading(true);

      let data = {
        id: id,
        status: 1,
      };
      axios
        .post(`${API_BASE_URL}/is-positive-enquiry`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            toast.success("Enquiry Added To Positive List successfully");
            setIsLoading(false);
            loadContent();
          }
        });
    }
  };

  const handleNegative = (id) => {
    const result = window.confirm(
      "Do you want to make this enquiry Negative ?"
    );
    if (result) {
      let data = {
        id: id,
        status: 0,
      };
      axios
        .post(`${API_BASE_URL}/is-positive-enquiry`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            toast.success("Enquiry Added To Negative List successfully");
            loadContent();
          }
        });
    }
  };

  const handleDelete = (id) => {
    const result = window.confirm("Do you want to delete ?");

    if (result) {
      axios
        .get(`${API_BASE_URL}/enquiry-delete/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === "200") {
            toast.success("Delete successully");
            axios
              .get(
                `${API_BASE_URL}/list-enquiry`,

                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                setListPageData(response.data.result.reverse());
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const ClearFilter = () => {
    setFilterData({
      name: "",
      company: "",
      state: "",
      start_date: "",
      end_date: "",
      email: "",
      phone: "",
    });
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Manage Negative Enquiry </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item active">Manage Negative Enquiry</li>
                    </ol>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-body rounded-3"
                    style={{ backgroundColor: "#e57c2ef2" }}
                  >
                    <div className="row">
                      <div className="col-3 mt-2">
                        <div className="form-group row mb-4 px-3">
                          <label
                            for="taskname"
                            className="col-form-label text-white col-lg-8"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Enter Name"
                            value={name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="col-3 mt-2">
                        <div className="form-group row mb-4 px-3">
                          <label
                            for="taskname"
                            className="col-form-label text-white col-lg-8"
                          >
                            Company
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="company"
                            placeholder="Enter Company Name"
                            value={company}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="col-3 mt-2">
                        <div className="form-group row mb-4 px-3">
                          <label
                            for="taskname"
                            className="col-form-label text-white col-lg-8"
                          >
                            State
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Enter State Name"
                            value={state}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="col-3 mt-2">
                        <div className="form-group row mb-4 px-3">
                          <label
                            for="taskname"
                            className="col-form-label text-white col-lg-8"
                          >
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            placeholder="Enter Email"
                            value={email}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-3 mt-2">
                        <div className="form-group row mb-4 px-3">
                          <label
                            for="taskname"
                            className="col-form-label text-white col-lg-8"
                          >
                            Phone
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            name="phone"
                            placeholder="Enter Phone"
                            value={phone}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-3 d-flex align-items-center pt-3">
                        {/* <span  className="me-2">Start Date</span> */}
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          dateFormat="dd-MM-yyyy"
                          wrapperClassName="datePicker "
                          placeholderText="Start Date"
                          // startDate={new Date()}
                          // endDate={endDate}
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center pt-3">
                        {/* <span className="me-2">End Date</span> */}
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          dateFormat="dd-MM-yyyy"
                          wrapperClassName="datePicker w-100"
                          placeholderText="End Date"

                          // endDate={endDate}
                          // minDate={startDate}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between">
                      <div className="col-3 clear-filter d-flex text-start">
                        <button onClick={() => ClearFilter()}>
                          Clear Filter
                        </button>
                      </div>
                      <div className="col-3 date-submit">
                        <button
                          style={{ height: "40px" }}
                          onClick={() => loadContent()}
                        >
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row justify-content-end">
              <div className="col-lg-12 mb-2 ">
                <Link
                  to={"/cms/manageenquiry/addenquiry"}
                  data-repeater-create
                  type="button"
                  className="btn btn-success inner"
                  value="Add Number"
                >
                  Add Enquiry
                </Link>
              </div>
            </div> */}

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">
                      Enquiry List ({totalUser})
                    </h4>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <div className="table-responsive">
                        <table className="table align-middle table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th className="align-middle">Name</th>
                              <th className="align-middle">Email</th>
                              <th className="align-middle">Mobile</th>
                              <th className="align-middle">utm_source</th>
                              <th className="align-middle">utm_medium</th>
                              <th className="align-middle">utm_campaign</th>
                              <th className="align-middle">Message</th>
                              <th className="align-middle">Date</th>
                              <th className="align-middle">Status</th>
                              <th className="align-middle">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listPageData?.map((item) => (
                              <>
                                <tr>
                                  <td>{item?.name}</td>

                                  <td>
                                    <span className="badge badge-pill badge-soft-info font-size-12">
                                      {item?.email}
                                    </span>
                                  </td>
                                  <td>
                                    {item?.phone ? item?.phone : "N/A"}
                                    {/* <button className="btn btn-sm btn-success ms-2">
                                    <i className="fa fa-phone-alt"></i>
                                  </button> */}
                                  </td>
                                  <td>
                                    {item?.utm_source ? item.utm_source : "N/A"}
                                  </td>
                                  <td>
                                    {item?.utm_medium
                                      ? item?.utm_medium
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {item?.utm_campaign
                                      ? item.utm_campaign?.substring(0, 10) +
                                        "..."
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {item.message?.substring(0, 10) + "..."}
                                  </td>
                                  <td>
                                    {moment(item.created_at).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {item?.is_positive == 0 ? (
                                      <span className="badge badge-pill badge-soft-danger font-size-11">
                                        Negative
                                      </span>
                                    ) : item?.is_positive == 1 ? (
                                      <span className="badge badge-pill badge-soft-success font-size-11">
                                        Positive
                                      </span>
                                    ) : (
                                      <span className="badge badge-pill badge-soft-warning font-size-11">
                                        Pending
                                      </span>
                                    )}
                                  </td>

                                  <td>
                                    <Link
                                      to={`/cms/manageEnquiry/viewEnquiry/${item.id}`}
                                      type="button"
                                      className="btn btn-success btn-sm btn-rounded waves-effect waves-light"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Link>
                                    <button
                                      type="button"
                                      className="btn btn-success btn-sm btn-rounded waves-effect waves-light ms-2"
                                      onClick={() => {
                                        handlePositive(item.id);
                                      }}
                                    >
                                      <i className="fa fa-thumbs-up"></i>
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-warning btn-sm btn-rounded waves-effect waves-light ms-2"
                                      onClick={() => {
                                        handleNegative(item.id);
                                      }}
                                    >
                                      <i className="fa fa-thumbs-down"></i>
                                    </button>

                                    <button
                                      type="button"
                                      className="btn btn-danger btn-sm btn-rounded waves-effect waves-light ms-2"
                                      onClick={() => {
                                        handleDelete(item.id);
                                      }}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {!isLoading && pageSize !== 1 && (
                      <div className="d-flex justify-content-center mt-2">
                        <Pagination
                          count={pageSize}
                          page={pageApi}
                          onChange={(e, value) => {
                            console.log(value, "vslue");
                            setPageApi(value);
                          }}
                          color="primary"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NegativeEnquiry;
