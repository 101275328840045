import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL, IMG_URl } from "../../../config"; 
import moment from "moment";
import Loader from "../../Loader";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const Detail = () => {
  const { search } = useLocation();

  let id = new URLSearchParams(search).get("id");

  console.log(id,"iddddd")

  const [companyDetail, setCompanyDetail] = useState();
  const [searchedValue, setSearchedvalue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [noData, setNoData] = useState(false);
  const [isMobileVisible, setIsMobileVisibile] = useState();
  const wrapperRef = useRef(null);
  const [userid, setUserid] = useState(localStorage.getItem("user_id"));

  const [companyId, setCompanyId] = useState(id);
  const [searchedData, setSearchedData] = useState();

  useEffect(() => {
    getCompanyDetail(id);
    setUserid(localStorage.getItem("trade_user_id"));
  }, []);

  const getCompanyDetail = async (companyId) => {
    setLoader(true);
    try {
      const data = {
        trademark_number: companyId,
        user_id: localStorage.getItem("trade_user_id"),
      };

      const response = await axios.get(`${API_BASE_URL}/company-details`, {
        params: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
          "Content-Type": "application/json",
        },
      });
      console.log(response?.data, "dis");
      setCompanyDetail(response?.data?.data);
      setIsMobileVisibile(response?.data?.is_mobile_email_visible);
      setLoader(false);
    } catch (error) {
      toast.error("No Data Found");
      console.log(error);
      setLoader(false);
    }
  };

  const slitName = (name) => {
    const fullFilename = name.split("/").pop(); // Gets "3721919_Certificate.pdf"

    const filename = fullFilename.substring(fullFilename.indexOf("_") + 1);
    return filename;
  };

  const onSearchData = async (e) => {
    // console.log(selectedOption,"selecttdopttions")
    setSearchedvalue(e.target.value);
    console.log(e);
    var params = {
      word_mark: "",
      class_type: "",
      proprietor_name: "",
      attorney_name: "",
      trademark_number: e.target.value,
    };

    if (e.target.value !== "") {
      try {
        const response = await axios
          .get(`${API_BASE_URL}/auto-suggestion`, {
            params: params,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log(res.data, "resiii");
            setSuggestions(res?.data?.data);
            console.log(params, "paas");
          });
      } catch (error) {
        console.log(error);
        // console.log(tradeMarkInfo.tm_category.toString().replace(/\,/g, '#'), tradeMarkInfo.filling_mode.toString().replace(/\,/g, '#'))
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      // setIsVisible(false);
      setSuggestions([]);
    }
  };

  const handleDownloadPdf = async (item, filename) => {
    try {
      const { data: response } = await axios
        .get(`${API_BASE_URL}/document-download-count`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
          },
          params: {
            user_id: userid,
          },
        })
        .then(async (res) => {
          if (res.status) {
            try {
              const link = document.createElement("a");

              // Set link properties
              link.href = `https://api.entermark.in/public/download-pdf?documents=${item}`;
              if (filename) {
                link.download = filename; // This will prompt a download if it's a file URL
              }

              // Append the link to the document body temporarily
              document.body.appendChild(link);

              // Trigger a click on the link
              link.click();

              // Remove the link from the DOM after clicking
              document.body.removeChild(link);
              // toast.success("Download successfull");
            } catch (error) {
              console.error("Error downloading the file:", error);
              toast.error("Error downloading the file:");
            }
          }
        });
      console.log(response.result, "response");
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  console.log("hiiiii");

  return (
    <div id="trade-data" className="main-content ">
    <div className="page-content">
      <div className="container-fluid">
        
            <div class="app-main__outer">
              <div class="app-main__inner">
            <div class="app-page-title  mb-3">
                                               
            </div>
            {loader ? (
              <div className="d-flex align-items-center ">
                <Loader />
              </div>
            ) : (
              <>
                <div class="row">
                  <div class="col-md-6 col-xl-7">
                    <div class="card">
                      <div class="card-body">
                        <div class="detail-inner">
                          <div class="detail-top">
                            <h4 className="mb-0">
                              {companyDetail?.details?.name}
                            </h4>
                          </div>
                          {companyDetail?.logo?.logo_status && (
                            <img
                              src={`${companyDetail?.logo?.link} `}
                              class="img-fluid detail-logo"
                            />
                          )}
                          <div class="detail-text">
                            <span>Application</span>
                            <p>{companyDetail?.details?.trade_mark}</p>
                          </div>
                          <div class="detail-text">
                            <span>Class</span>
                            <p>{companyDetail?.details?.class}</p>
                          </div>
                          <div class="detail-text">
                            <span>Status</span>
                            <p>{companyDetail?.details?.status}</p>
                          </div>
                          {isMobileVisible && (
                            <>
                              {companyDetail?.details?.phone && (
                                <div class="detail-text">
                                  <span>Mobile</span>
                                  <p>{companyDetail?.details?.phone}</p>
                                </div>
                              )}
                              {companyDetail?.details?.email && (
                                <div class="detail-text">
                                  <span>Email</span>
                                  <p>{companyDetail?.details?.email}</p>
                                </div>
                              )}
                            </>
                          )}
                          <div class="detail-text">
                            <span>Application Date</span>
                            <p>
                              {moment
                                .utc(
                                  companyDetail?.details?.date_of_application
                                )
                                .format("DD-MM-YYYY")}
                            </p>
                          </div>
                          <div class="detail-text">
                            <span>Owner</span>
                            <p>
                              {companyDetail?.details?.owner
                                ? companyDetail?.details?.owner
                                : "No Data"}
                            </p>
                          </div>
                          <div class="detail-text">
                            <span>Owner Address</span>
                            <p>
                              {companyDetail?.details?.owner_address
                                ? companyDetail?.details?.owner_address
                                : "No Data"}
                            </p>
                          </div>
                          <div class="detail-text">
                            <span>Attorney</span>
                            <p>
                              {companyDetail?.details?.attorney_name
                                ? companyDetail?.details?.attorney_name
                                : "No Data"}
                            </p>
                          </div>
                          <div class="detail-text">
                            <span>Attorney Address</span>
                            <p>
                              {companyDetail?.details?.attorney_address
                                ? companyDetail?.details?.attorney_address
                                : "No Data"}
                            </p>
                          </div>
                          {!companyDetail?.details?.alert ? null : (
                            <div class="detail-text">
                              <span>Alert</span>
                              <p>
                                {companyDetail?.details?.alert
                                  ? companyDetail?.details?.alert
                                  : "No Data"}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-5">
                    <div class="card">
                      <div class="card-body">
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            marginBottom: "8px",
                          }}
                        >
                          UPLOADED DOCUMENTS
                        </p>
                        <table class="table table-borderless table-sm">
                          <thead>
                            <tr>
                              <th scope="col">SN.</th>
                              <th scope="col">Document Description</th>
                              <th scope="col">Uploaded Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {companyDetail?.documents?.map((item, index) => {
                              return (
                                <>
                                  <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      <a
                                        style={{
                                          cursor: "pointer",
                                          color: "blue !important",
                                        }}
                                        onClick={() => {
                                          handleDownloadPdf(
                                            item?.document_key,
                                            item?.doc_full_path
                                          );
                                        }}
                                        // target="_blank"
                                      >
                                        {/* {`Doc ${index + 1}`} */}
                                        {slitName(item?.doc_full_path)}
                                      </a>
                                      {/* href={`${item}`} */}
                                    </td>
                                    <td>10/05/2023</td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}  
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Detail;
