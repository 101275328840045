import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import Navbar from "../../Layouts/Navbar";
import Footer from "../../Layouts/Footer";
import LeftSideBar from "../../Layouts/LeftSideBar";
import { toast } from "react-toastify";
import Loader from "../../Loader/loader";
import Pagination from "@mui/material/Pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const ManageGuestCustomer = () => {
  const [listPageData, setListPageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  const [status, setStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState();
  const [pageApi, setPageApi] = useState(1);
  const [totalUser, setTotalUser] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');


  useEffect(() => {
    setToken(localStorage.getItem("cms_token"));
    getUserList(pageApi);
  }, [pageApi]);

  const getUserList = async () => {
    setIsLoading(true);
    await axios
      .get(`${API_BASE_URL}/get-gust-customer-list`, {
        params: {
          page: pageApi,
          limit: 10,
          start_date:startDate ?  moment(startDate).utc().add(1, 'd').format('DD-MM-YYYY') : '',
          end_date:endDate ?  moment(endDate).utc().add(1, 'd').format('DD-MM-YYYY') : ''
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setListPageData(response.data.data.reverse());
        setTotalUser(response?.data?.total)
        const totalPageCount = Math.ceil(response?.data?.total / 10);
        setPageSize(totalPageCount);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

 

  const handleDelete = (id) => {
    const result = window.confirm("Do you want to delete ?");
let data = {
  id: id,
  delete_for: "users"
}
    if (result) {
      axios
        .post(`${API_BASE_URL}/delete-tabuler-data`,data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            toast.success("Delete successully");
            getUserList();
          }
        });
    }
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Manage Guest Data </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Manage Guest Data
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body rounded-3" style={{backgroundColor:'#d3c0e5'}}>                                  
                    <div className="row">
                      <div className="col-4">
                        {/* <span  className="me-2">Start Date</span> */}
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          dateFormat="dd-MM-yyyy"
                          wrapperClassName="datePicker "
                          placeholderText="Start Date"
                          // startDate={new Date()}
                          // endDate={endDate}
                        />
                      </div>
                      <div className="col-4">
                        {/* <span className="me-2">End Date</span> */}
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          dateFormat="dd-MM-yyyy"
                          wrapperClassName="datePicker w-100"
                          placeholderText="End Date" 

                          // endDate={endDate}
                          // minDate={startDate}
                        />
                      </div>
                      <div className="col-4 date-submit">
                        <button onClick={()=>getUserList()}>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row justify-content-end">
              <div className="col-lg-12 mb-2 ">
                <Link
                  to={"/cms/manageUserData/addUser"}
                  data-repeater-create
                  type="button"
                  className="btn btn-success inner"
                  value="Add Number"
                >
                  Add Customer
                </Link>
              </div>
            </div> */}

            <div className="row"> 
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">
                    Guest  Customer List ({totalUser})
                    </h4>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <div className="table-responsive">
                        <table className="table align-middle table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th className="align-middle">Name</th>
                              <th className="align-middle">Email</th>
                              <th className="align-middle">Mobile</th>
                              <th className="align-middle">Created On</th>
                              <th className="align-middle">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listPageData?.map((item) => (
                              <>
                                <tr>
                                  <td>{item.name}</td>

                                  <td>{item.email}</td>
                                  <td>
                                    {item.mobile ? item.mobile : 'N/A'}
                                    {/* <button className="btn btn-sm btn-success ms-2">
                                      <i className="fa fa-phone-alt"></i>
                                    </button> */}
                                  </td>
                                  <td>{item.created_at}</td>
                                  <td>
                                  
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-sm btn-rounded waves-effect waves-light ms-2"
                                      onClick={() => {
                                        handleDelete(item.id);
                                      }}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                     {!isLoading && pageSize !== 1 && (
                      <div className="d-flex justify-content-center mt-2">
                          <Pagination
                            count={pageSize}
                            page={pageApi}
                            onChange={(e, value) => {
                              console.log(value, "vslue");
                              setPageApi(value);
                            }}
                            color="primary"
                          />
                          </div>
                        )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageGuestCustomer;
