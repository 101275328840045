import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";

const CreateOrder = () => {
  const [formData, setFormData] = useState({
    user_id: "",
    plan_id: "",
    plan_type: "",
    total_amount: "",
    billing_address: "",
    payment_link: "",
    razor_pay_order_id: "",
  });

  const {
    user_id,
    plan_id,
    total_amount,
    billing_address,
    plan_type,
    payment_link,
    razor_pay_order_id,
  } = formData;

  const [errors, setErrors] = useState({});

  const [planIdError, setPlanIdError] = useState("");
  const [userList, setUserList] = useState([]);
  // const [image, setImage] = useState("");
  const [totalAmtError, setTotalAmtError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [paymentLinkError, setPaymentLinkError] = useState("");
  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  const [tradeUserId, setTradeUserId] = useState(
    localStorage.getItem("trade_user_id")
  );

  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  const validateForm = () => {
    let errors = {};
    // check for empty values
    if (!formData.user_id) {
      errors.user_id = "select";
    }
    if (!formData.plan_id) {
      errors.plan_id = "select";
    }
    if (!formData.plan_type) {
      errors.plan_type = "select";
    }
    if (!formData.total_amount) {
      errors.total_amount = "Please enter amt";
    }
    if (!formData.billing_address) {
      errors.billing_address = "Please enter address";
    }
    if (!formData.payment_link) {
      errors.payment_link = "Please generate Link";
    }
    return errors;
  };

  const validateGenerate = () => {
    let errors = {};
    // check for empty values
    if (!formData.user_id) {
      errors.user_id = "select";
    }
    if (!formData.plan_id) {
      errors.plan_id = "select";
    }
    if (!formData.plan_type) {
      errors.plan_type = "select";
    }
    if (!formData.total_amount) {
      errors.total_amount = "Please enter amt";
    }
    if (!formData.billing_address) {
      errors.billing_address = "Please enter address";
    }
    return errors;
  };

  useEffect(() => {
    setToken(localStorage.getItem("cms_token"));
    setTradeUserId(localStorage.getItem("trade_user_id"));

    loadplan2();
    loadCustomer();
    if (id) {
      fetchPrefilled(id);
    }
  }, []);

  const loadplan2 = async () => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const plan_data = await axios.get(API_BASE_URL + "/get-subscrpton-plan");
      const pricing_plan = JSON.parse(JSON.stringify(plan_data.data));
      console.log(plan_data.data.data, "plan_data");

      console.log(pricing_plan, "pricing_plan.data");

      if (pricing_plan.status == 200) {
        setPlans(plan_data.data.data);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      return false;
    }
  };

  const loadCustomer = async () => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const plan_data = await axios.get(API_BASE_URL + "/get-users-name");
      const pricing_plan = JSON.parse(JSON.stringify(plan_data.data));
      console.log(plan_data.data, "plan_user");

      console.log(pricing_plan, "pricing_plan.data");

      if (pricing_plan.status == 200) {
        // let GuestUser = []
        let GuestUser = plan_data.data.users.filter((item) => {
          return item.subscription_status == 0;
        });
        console.log(GuestUser, "guestttt");
        setUserList(GuestUser);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      return false;
    }
  }; 
                                                 
  const in_Menu_Options = [
    { value: "monthly", label: "Monthly" },
    { value: "annualy", label: "Annualy" },
  ];

  const userOption = [];
  userList?.forEach((item) => {
    userOption.push({ value: item.id, label: item.name });
  });              
  const planOption = [];
  plans?.forEach((item) => {
    planOption.push({ value: item.id, label: item.name });
  });

  const fetchPrefilled = async (id) => {
    // try {
    //   setToken(localStorage.getItem("cms_token"));
    //   // setIsLoading(true);
    //   const enquiryResponse = await axios.get(             
    //     `${API_BASE_URL}/get-testimonial-id-basis?id=${id}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     } 
    //   );
    //   const enquiryRes = JSON.parse(JSON.stringify(enquiryResponse.data));
    //   console.log(enquiryRes, "res");
    //   if (enquiryRes.status == 200) {
    //     // setIsLoading(false);
    //     setFormData({
    //       ...formData,
    //       url: enquiryRes.data.url,
    //       name: enquiryRes.data.name,
    //       rating: enquiryRes.data.rating,
    //       description: enquiryRes.data.description,
    //       city_country: enquiryRes.data.city_country,
    //       image: enquiryRes.data.image,
    //     });
    //     setImagePreview(enquiryRes.data.image);
    //   }
    // } catch (error) {}
  };

  const GenerateLink = (event) => {
    event.preventDefault();
    const errors = validateGenerate();

    if (Object.keys(errors).length === 0) {
      if (!id) {
        let data = {
          id: tradeUserId,
          amount: total_amount,
        };
        axios
          .post(`${API_BASE_URL}/get-order-id`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log(response.data.status, "status");
            if (response.data.status === 200) {
              setFormData({
                ...formData,
                payment_link: `https://trademarksearch.app/payment-link?oid=${response.data.order_id}&uid=${user_id}&amt=${total_amount}&pid=${plan_id}`,
                razor_pay_order_id: response.data.order_id,
              });
              //   payment-link?oid=order_NAyfqE7LYPdBMB&uid=192&amt=1000
              toast.success("Generated successfully");
            }
          })
          .catch((error) => {
            console.log(error);
            setPageError(error.response.data.result);
          });
      }
    } else {
      setErrors(errors);
      console.log(errors);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      if (!id) {
        axios
          .post(`${API_BASE_URL}/create-order`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log(response.data.status, "status");
            if (response.data.status === 200) {
              //   navigate("/cms/managetestimonials");
              toast.success("Order Created successfully");
              navigate("/cms/manage-customer/manage-order");
            }
          })
          .catch((error) => {
            console.log(error);
            setPageError(error.response.data.result);
          });
      }
    } else {
      setErrors(errors);
      console.log(errors);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const selectStyles = (errorFieldName) => ({
    control: (provided) => ({
      ...provided,
      borderColor: errors[errorFieldName] ? "#dc3545" : provided.borderColor,
      boxShadow: errors[errorFieldName]
        ? "0 0 0 0.2rem rgba(220, 53, 69, 0.25)"
        : provided.boxShadow,
      minHeight: 22,
      height: 33,
    }),
  });

  const handleSelect = (fieldName, selectedOption) => {
    // setSelectedOption(selectedOption);

    console.log(selectedOption, "opton");
    setFormData({ ...formData, [fieldName]: selectedOption.value });
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    if (fieldName == "plan_id") {
      const getOnlyArray = (id) => {
        let selectedPlan = plans.filter((s) => s.id === selectedOption.value);
        if (plan_type === "annualy") {
          return selectedPlan[0]?.annual_price;
        }
        if (plan_type === "monthly") {
          return selectedPlan[0]?.monthly_price;
        } else {
          return selectedPlan[0]?.monthly_price;
        }
        // this will return only item which match the provided id
      };
      console.log(getOnlyArray(), "getilll");
      setFormData({
        ...formData,
        [fieldName]: selectedOption.value,
        total_amount: getOnlyArray(),
      });
    }
    if (fieldName == "plan_type") {
      const getOnlyArray = (id) => {
        let selectedPlan = plans.filter((s) => s.id === plan_id);
        if (selectedOption.value === "annualy") {
          return selectedPlan[0]?.annual_price;
        }
        if (selectedOption.value === "monthly") {
          return selectedPlan[0]?.monthly_price;
        } else {
          return selectedPlan[0]?.monthly_price;
        }
        // this will return only item which match the provided id
      };
      console.log(getOnlyArray(), "getilll");
      setFormData({
        ...formData,
        [fieldName]: selectedOption.value,
        total_amount: getOnlyArray(),
      });
    }
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Create Order </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/cms/manageCustomer"}>Manage Customer</Link>
                      </li>
                      <li className="breadcrumb-item active">Create Order</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Create Order</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Customer Name
                              </label>
                              <div className="col-lg-10">
                                <Select
                                  className={`custom-select custom-select-sm`}
                                  options={userOption}
                                  value={userOption.filter(
                                    (option) => option.value == user_id
                                  )}
                                  styles={selectStyles("user_id")}
                                  name="user_id"
                                  onChange={(selectedOption) =>
                                    handleSelect("user_id", selectedOption)
                                  }
                                >
                                  <option value="">Select an option</option>
                                  {userOption?.map((item) => (
                                    <option value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </Select>
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Plan Name
                              </label>
                              <div className="col-lg-10">
                                <Select
                                  className={`custom-select custom-select-sm`}
                                  options={planOption}
                                  value={planOption.filter(
                                    (option) => option.value == plan_id
                                  )}
                                  styles={selectStyles("plan_id")}
                                  name="plan_id"
                                  onChange={(selectedOption) =>
                                    handleSelect("plan_id", selectedOption)
                                  }
                                >
                                  <option value="">Select an option</option>
                                  {planOption?.map((item) => (
                                    <option value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Plan Type
                              </label>
                              <div className="col-lg-10">
                                <Select
                                  className={`custom-select custom-select-sm`}
                                  options={in_Menu_Options}
                                  value={in_Menu_Options.filter(
                                    (option) => option.value == plan_type
                                  )}
                                  styles={selectStyles("plan_type")}
                                  name="plan_type"
                                  onChange={(selectedOption) =>
                                    handleSelect("plan_type", selectedOption)
                                  }
                                >
                                  <option value="">Select an option</option>
                                  {in_Menu_Options?.map((item) => (
                                    <option value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </Select>
                                {/* {planTypeError && (
                                  <div className="text-danger">
                                    {planTypeError}
                                  </div>
                                )} */}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Total Amount
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="city_country"
                                  type="text"
                                  value={total_amount}
                                  className={
                                    errors.total_amount
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="total Amount"
                                  onChange={handleInputChange}
                                />
                                {errors.total_amount && (
                                  <div className="text-danger">
                                    {errors.total_amount}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Billing Address
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="billing_address"
                                  type="text"
                                  value={billing_address}
                                  className={
                                    errors.billing_address
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter billing address"
                                  onChange={handleInputChange}
                                />
                                {errors.billing_address && (
                                  <div className="text-danger">
                                    {errors.billing_address}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Payment Link
                              </label>
                              <div className="col-lg-8">
                                <input
                                  id="taskname"
                                  name="rating"
                                  type="text"
                                  value={payment_link}
                                  disabled
                                  className={
                                    errors.payment_link
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="generate link"
                                  onChange={handleInputChange}
                                />
                                {errors.payment_link && (
                                  <div className="text-danger">
                                    {errors.payment_link}
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-2">
                                <button
                                  onClick={(e) => GenerateLink(e)}
                                  className="btn btn-primary"
                                >
                                  Generate
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            <button type="submit" className="btn btn-primary">
                              Create Order
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOrder;
