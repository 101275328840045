import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const LeftSideBar = () => {
  const [dropdown, setDropDown] = useState(false);
  const [dropdown2, setDropDown2] = useState(false);
  const [dropdown3, setDropDown3] = useState(false);
  const [manageTrademark, setManageTrademark] = useState(false);

  const [active, setActive] = useState("");
  const [userType, setUserType] = useState(
    localStorage.getItem("trade_user_type")
  );

  const handleDropdown = () => {
    setDropDown((prevState) => !prevState);
    setDropDown2(false);
    setDropDown3(false);
    setManageTrademark(false);
  };

  useEffect(() => {
    setUserType(localStorage.getItem("trade_user_type"));
  }, []);

  const handleManageEnquiry = () => {
    setDropDown2((prevState) => !prevState);
    setDropDown(false);
    setDropDown3(false);
    setManageTrademark(false);
  };
  const handleAdminPanel = () => {
    setDropDown3((prevState) => !prevState);
    setDropDown(false);
    setDropDown2(false);
    setManageTrademark(false);
  };

  const handleManageTrademark = () => {
    setManageTrademark((prevState) => !prevState);
    setDropDown(false);
    setDropDown2(false);
    setDropDown3(false);
  };

  return (
    <div className="vertical-menu">
      <div data-simplebar className="h-100">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <li className={active == "/cms/home" ? "active" : ""}>
                <Link to={"/cms/home"} className="waves-effect" type="button">
                  <i className="bx bx-home"></i>
                  <span key="t-dashboards">Dashboard</span>
                </Link>
              </li>
              <li
                className={
                  active == "/cms/companyinformation" ||
                  active == "/cms/companyinformation"
                    ? "active"
                    : ""
                }
              >
                <Link
                  to={"/cms/companyinformation"}
                  className="waves-effect"
                  type="button"
                >
                  <i className="bx bx-home"></i>
                  <span key="t-dashboards">Company Information</span>
                </Link>
              </li>
              <a
                href="javascript: void(0);"
                className="has-arrow waves-effect"
                type="button"
                onClick={() => {
                  handleDropdown();
                }}
              >
                <i className="bx bx-home-circle"></i>
                <span key="t-dashboards">CMS</span>
              </a>
              {dropdown && (
                <ul className="sub-menu" aria-expanded="false">
                  {/* <li className={active == "managelogo" ? "active" : ""}>
                    <Link
                      to={"/cms/managelogo"}
                      key="t-default"
                      type="button"
                      onClick={() => {
                        setActive("managelogo");
                      }}
                    >
                      Manage Logo
                    </Link>
                  </li>
                  <li className={active === "managepage" ? "active" : ""}>
                    <Link
                      to={"/cms/managepage"}
                      key="t-default"
                      type="button"
                      onClick={() => {
                        setActive("managepage");
                      }}
                    >
                      Manage Pages
                    </Link>
                  </li> */}
                  <li className={active == "managecontentpage" ? "active" : ""}>
                    <Link
                      to={"/cms/managecontentpage"}
                      key="t-default"
                      type="button"
                      onClick={() => {
                        setActive("managecontentpage");
                      }}
                    >
                      Manage Content Page
                    </Link>
                  </li>
                  <li className={active == "manageslider" ? "active" : ""}>
                    <Link
                      to={"/cms/manageslider"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("manageslider");
                      }}
                    >
                      Manage Slider
                    </Link>
                  </li>
                  <li className={active == "manageseopage" ? "active" : ""}>
                    <Link
                      to={"/cms/manageseopage"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("manageseopage");
                      }}
                    >
                      Manage SEO page
                    </Link>
                  </li>
                  <li
                    className={active == "managetestimonials" ? "active" : ""}
                  >
                    <Link
                      to={"/cms/managetestimonials"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("managetestimonials");
                      }}
                    >
                      Manage Testimonials
                    </Link>
                  </li>
                  {/* <li className={active == "mangeadsbanner" ? "active" : ""}>
                    <Link
                      to={"/cms/mangeadsbanner"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("mangeadsbanner");
                      }}
                    >
                      Manage Ads Banner
                    </Link>
                  </li>
                  <li className={active == "managefaq" ? "active" : ""}>
                    <Link
                      to={"/cms/managefaq"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("managefaq");
                      }}
                    >
                      Manage Faq
                    </Link>
                  </li>
                  <li className={active == "managesocialmedia" ? "active" : ""}>
                    <Link
                      to={"/cms/managesocialmedia"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("managesocialmedia");
                      }}
                    >
                      Manage Social Media
                    </Link>
                  </li>
                  <li className={active == "manageindustry" ? "active" : ""}>
                    <Link
                      to={"/cms/manageindustry"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("manageindustry");
                      }}
                    >
                      Manage Industry
                    </Link>
                  </li> */}
                  <li className={active == "managesubscribe" ? "active" : ""}>
                    <Link
                      to={"/cms/managesubscribe"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("managesubscribe");
                      }}
                    >
                      Manage Subscribe
                    </Link>
                  </li>
                  {/* <li
                    className={active == "managecompanyprofile" ? "active" : ""}
                  >
                    <Link
                      to={"/cms/managecompanyprofile"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("managecompanyprofile");
                      }}
                    >
                      Manage Company Profile
                    </Link>
                  </li> */}
                  <li className={active == "manageSponser" ? "active" : ""}>
                    <Link
                      to={"/cms/manageSponser"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("manageSponser");
                      }}
                    >
                      Manage Sponser
                    </Link>
                  </li>
                  {/* <li
                    className={
                      active == "manageSubscriptionPlan" ? "active" : ""
                    }
                  >
                    <Link
                      to={"/cms/managePlan/add-subscription-plan"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("manageSubscriptionPlan");
                      }}
                    >
                      Manage Subscription Plan
                    </Link>
                  </li> */}
                </ul>
              )}
            </li>
            <li>
              <a
                href="javascript: void(0);"
                className="has-arrow waves-effect"
                type="button"
                onClick={() => {
                  handleManageTrademark();
                }}
              >
                <i className="bx bx-layout"></i>

                <span key="t-dashboards">Manage Trademark Data</span>
              </a>

              {manageTrademark && (
                <ul className="sub-menu" aria-expanded="false">
                  <li className={active == "manageTrademark" ? "active" : ""}>
                    <Link
                      to={"/cms/manage-trademark-data/manage-trademark"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("manageTrademark");
                      }}
                    >
                      Manage Trademark
                    </Link>
                  </li>
                  {/* <li className={active == "manageDocuments" ? "active" : ""}>
                    <Link
                      to={"/cms/manage-trademark-data/manage-documents"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("manageDocuments");
                      }}
                    >
                      Manage Documents
                    </Link>
                  </li> */}
                </ul>
              )}
            </li>

            <li
              className={
                active == "/cms/manage-subscription-plan" || active == "/cms/"
                  ? "active"
                  : ""
              }
            >
              <Link
                to={"/cms/manage-subscription-plan"}
                className="waves-effect"
                type="button"
              >
                <i className="bx bx-layout"></i>
                <span key="t-dashboards">Manage Subscription Plan</span>
              </Link>
            </li>
            {console.log(userType, "usertype")}
            {userType == "admin" ? (
              <li>
                <a
                  href="javascript: void(0);"
                  className="has-arrow waves-effect"
                  type="button"
                  onClick={() => {
                    handleAdminPanel();
                  }}
                >
                  <i className="bx bx-layout"></i>

                  <span key="t-dashboards">Manage Customer / Plans</span>
                </a>

                {dropdown3 && (
                  <ul className="sub-menu" aria-expanded="false">
                    <li className={active == "manageCustomer" ? "active" : ""}>
                      <Link
                        to={"/cms/manage-customer"}
                        key="t-saas"
                        type="button"
                        onClick={() => {
                          setActive("manageCustomer");
                        }}
                      >
                        Customer / Plan Data
                      </Link>
                    </li>
                    <li
                      className={
                        active == "manageGuestCustomer" ? "active" : ""
                      }
                    >
                      <Link
                        to={"/cms/manage-guest-customer"}
                        key="t-saas"
                        type="button"
                        onClick={() => {
                          setActive("manageGuestCustomer");
                        }}
                      >
                        Guest Customers
                      </Link>
                    </li>
                    <li className={active == "manageOrder" ? "active" : ""}>
                      <Link
                        to={"/cms/manage-customer/manage-order"}
                        key="t-saas"
                        type="button"
                        onClick={() => {
                          setActive("manageOrder");
                        }}
                      >
                        Manage Order
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            ) : null}

            <li>
              <a
                href="javascript: void(0);"
                className="has-arrow waves-effect"
                type="button"
                onClick={() => {
                  handleManageEnquiry();
                }}
              >
                <i className="bx bx-layout"></i>

                <span key="t-dashboards">Manage Enquiry</span>
              </a>

              {dropdown2 && (
                <ul className="sub-menu" aria-expanded="false">
                  <li className={active == "manageenquiry" ? "active" : ""}>
                    <Link
                      to={"/cms/manageenquiry"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("manageenquiry");
                      }}
                    >
                      Manage Customer Enquiry
                    </Link>
                  </li>

                  <li className={active == "positive-enquiry" ? "active" : ""}>
                    <Link
                      to={"/cms/manageenquiry/positive-enquiry"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("positive-enquiry");
                      }}
                    >
                      Positive Enquiry
                    </Link>
                  </li>

                  <li className={active == "negative-enquiry" ? "active" : ""}>
                    <Link
                      to={"/cms/manageenquiry/negative-enquiry"}
                      key="t-saas"
                      type="button"
                      onClick={() => {
                        setActive("negative-enquiry");
                      }}
                    >
                      Negative Enquiry
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <li
              className={
                active == "/cms/manage-search" || active == "/cms/"
                  ? "active"
                  : ""
              }
            >
              <Link
                to={"/cms/manage-search"}
                className="waves-effect"
                type="button"
              >
                <i className="bx bx-search"></i>
                <span key="t-dashboards">Search Trademark</span>
              </Link>
            </li>
         
            <li
              className={
                active == "/cms/logout" || active == "/cms/logout"
                  ? "active"
                  : ""
              }
            >
              <Link to={"/cms/logout"} className="waves-effect" type="button">
                <i className="bx bx-layout"></i>
                <span key="t-dashboards">Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LeftSideBar;
