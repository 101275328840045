import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL, API_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../Loader/loader";

const ManageSlider = () => {
  const [sliderData, setSliderData] = useState();
  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(0);

  useEffect(() => {
    setToken(localStorage.getItem("cms_token"));
    loadContent();
    
  }, []);

  const loadContent = async () => {
    setIsLoading(true)
    await axios
    .get(`${API_BASE_URL}/get-slider`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      setSliderData(response.data.data.reverse());
    setIsLoading(false)
    })
    .catch((error) => {
    setIsLoading(false)
      console.log(error);
    });
  }

  const handleDelete = (id) => {
    const result = window.confirm("Do you want to delete ?");

    if (result) {
      let body ={
        id : id,
        delete_for : 'slider'
      }
      axios
        .post(`${API_BASE_URL}/delete-tabuler-data`,body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            toast.success("Deleted successfully");
            axios
              .get(`${API_BASE_URL}/get-slider`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "content-type": "multipart/form-data",
                },
              })
              .then((response) => {
                setSliderData(response.data.data.reverse());
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    }
  };

  const handleActive = async (status, id) => {
    let updatedStatus = status == 0 ? 1 : 0;
    setIsLoading(true) 
    const res = await axios.post(
      `${API_BASE_URL}/update-slider-status`,
      { status: updatedStatus, id: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const response = JSON.parse(JSON.stringify(res));
    console.log(response);
    if (response.data.status === 200) {
    setIsLoading(false)
      loadContent();
      if (status == 1) {
        toast.success("Inactive successfully");
      } else {
        toast.success("Active successfully");
      }
    }
    else{
    setIsLoading(false)

    }
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Manage Slider </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item active">Manage Slider</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-end">
              <div className="col-lg-12 mb-2 ">
                <Link
                  to={"/cms/manageslider/addnewslider"}
                  data-repeater-create
                  type="button"
                  className="btn btn-success inner"
                  value="Add Number"
                >
                  Add a new Slider
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">
                      Slider List ({sliderData?.length})
                    </h4>
                    {isLoading ? (
                      <Loader />
                    ) : (
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            {/* <th styles="width: 20px;">
                              <div className="form-check font-size-16 align-middle">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="transactionCheck01"
                                />
                                <label
                                  className="form-check-label"
                                  for="transactionCheck01"
                                ></label>
                              </div>
                            </th> */}
                            <th className="align-middle">Title</th>
                            <th className="align-middle">Short Order</th>
                            <th className="align-middle">Slug</th>
                            <th className="align-middle">Image</th>
                            <th className="align-middle">Status</th>
                            <th className="align-middle">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sliderData?.map((item) => (
                            <tr>
                              {/* <td>
                                <div className="form-check font-size-16">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="transactionCheck02"
                                  />
                                  <label
                                    className="form-check-label"
                                    for="transactionCheck02"
                                  ></label>
                                </div>
                              </td> */}
                              {/* <td>
                              <a
                                href="javascript: void(0);"
                                className="text-body fw-bold"
                              >
                                {item.id}
                              </a>
                            </td> */}
                              <td>{item.title}</td>
                              <td>{item.short_order}</td>
                              <td>{item.slug}</td>

                              <td>
                                <img
                                  class="card-img-top  rounded "
                                  src={item.image}
                                  alt="Card image cap"
                                  width="50"
                                  height="65"
                                />
                              </td>

                              <td>
                                {item.status == 1 ? (
                                  <span className="badge badge-pill badge-soft-success font-size-11">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge badge-pill badge-soft-danger font-size-11">
                                    Inactive
                                  </span>
                                )}
                              </td>

                              <td>
                                <Link
                                  to={`/cms/manageslider/edit-slider/${item.id}`}
                                  type="button"
                                  className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                                >
                                  <i className="fa fa-pen"></i>
                                </Link>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm btn-rounded waves-effect waves-light ms-2"
                                  onClick={() => {
                                    handleDelete(item.id);
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-warning btn-sm btn-rounded  ms-2"
                                  onClick={() => {
                                    setStatus(
                                      item.status === 0 ? 1 : 0
                                    );
                                    handleActive(
                                      item.status,
                                      item.id
                                    );
                                  }}
                                >
                                  <i className="fa fa-ban"></i>
                                </button>
                              </td>
                             
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageSlider;
