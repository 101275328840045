import React from "react";
import { Link } from "react-router-dom";

const ManagePricingPlans = () => {
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">
                    Manage Pricing Plans{" "}
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Manage Pricing Plans
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-end">
              <div className="col-lg-12 mb-2 ">
                <Link
                  to={"/cms/managepricingplans/addpricingplans"}
                  data-repeater-create
                  type="button"
                  className="btn btn-success inner"
                  value="Add Number"
                >
                  Add Pricing Plans
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Plans List ()</h4>
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <th styles="width: 20px;">
                              <div className="form-check font-size-16 align-middle">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="transactionCheck01"
                                />
                                <label
                                  className="form-check-label"
                                  for="transactionCheck01"
                                ></label>
                              </div>
                            </th>
                            <th className="align-middle">Title</th>
                            <th className="align-middle">Currency Type</th>
                            <th className="align-middle">New Price</th>
                            <th className="align-middle">Old Price</th>
                            <th className="align-middle">Color</th>
                            <th className="align-middle">Duration Type</th>
                            <th className="align-middle">Duration </th>
                            <th className="align-middle">Description </th>
                            <th className="align-middle">Features </th>
                            <th className="align-middle">Status </th>
                            <th className="align-middle">Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="form-check font-size-16">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="transactionCheck02"
                                />
                                <label
                                  className="form-check-label"
                                  for="transactionCheck02"
                                ></label>
                              </div>
                            </td>
                            <td>
                              <a
                                href="javascript: void(0);"
                                className="text-body fw-bold"
                              >
                                1
                              </a>
                            </td>
                            <td>Demo Page</td>
                            <td>www.example.com</td>
                            <td>enterslice</td>
                            <td>
                              <span className="badge badge-pill badge-soft-success font-size-11">
                                Active
                              </span>
                            </td>

                            <td>
                              <Link
                                to={"/cms/managepricingplans/editpricingplans"}
                                type="button"
                                className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                              >
                                <i className="fa fa-pen"></i>
                              </Link>
                              <button
                                type="button"
                                className="btn btn-danger btn-sm btn-rounded waves-effect waves-light ms-2"
                                data-bs-toggle="modal"
                                data-bs-target=".transaction-detailModal"
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagePricingPlans;
