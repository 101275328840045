import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
const EditPage = () => {
  const { id } = useParams();

  const [managePageData, setManagePageData] = useState();
  const [pageError, setPageError] = useState("");
  const [token, setToken] = useState(localStorage.getItem("cms_token"));

  const [formData, setFormData] = useState({
    pageSlug: "",
    pagename: "",
    parentlink: "0",
    header_footer: "",
    shortOrder: 0,
  });

  const { pageSlug, pagename, parentlink, header_footer, shortOrder } =
    formData;

  const [pageSlugError, setPageurlError] = useState("");
  const [pageNameError, setPageNameError] = useState("");
  const [parentPageError, setParentPageError] = useState("");
  const [shortOrderError, setShortOrderError] = useState("");
  const [headerFooterError, setHeaderFooterError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem("cms_token"));
    fetchList();
    ViewPage();
  }, []);

  const fetchList = async () => {
    await axios
      .get(`${API_BASE_URL}/get-page`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setManagePageData(response.data.pages.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ViewPage = async () => {
    const res = await axios.get(`${API_BASE_URL}/get-page-details?id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.data.status == 200) {
      console.log()
      setFormData({
        ...formData,
        pageSlug: res.data.data.page_slug,
        pagename: res.data.data.page_name,
        parentlink: res.data.data.parent_page,
        header_footer: res.data.data.header_footer,
        shortOrder: res.data.data.short_order,
      });
    }
  };

  let hasError = false;
  const handleSubmit = (event) => {
    event.preventDefault();
    // if (!formData.pageSlug) {
    //   setPageurlError("Please enter page slug");
    //   hasError = true;
    // } else {
    //   setPageurlError("");
    // }

    if (!formData.pagename) {
      setPageNameError("Please enter pagename");
      hasError = true;
    } else {
      setPageNameError("");
    }
    // if (!formData.parentlink) {
    //   setParentPageError("Please select parent page ");
    //   hasError = true;
    // } else {
    //   setParentPageError();
    // }
    if (!formData.header_footer) {
      setHeaderFooterError("please select");
      hasError = true;
    } else setHeaderFooterError("");

    if (!hasError) {
      axios
        .post(
          `${API_BASE_URL}/update-page`,
          {
            page_name: pagename,
            page_slug: pageSlug,
            parent_page: parentlink,
            header_footer: header_footer,
            short_order: shortOrder,
            id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status === 200) {
            navigate("/cms/managepage");
            toast.success("Page Edited successfully");
          }
        })
        .catch((error) => {
          console.log(error.response);
          setPageError(error.response.data.result);
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Edit Page </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/cms/managepage"}>Manage Pages</Link>
                      </li>
                      <li className="breadcrumb-item active">Add Page </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Add Page </h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Page Name
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="pagename"
                                  type="text"
                                  value={pagename}
                                  className={
                                    pageNameError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter pagename..."
                                  onChange={handleInputChange}
                                />
                                {pageNameError && (
                                  <div className="text-danger">
                                    {pageNameError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Page slug
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="pageSlug"
                                  type="text"
                                  value={pageSlug}
                                  className={
                                    pageSlugError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter pageslug..."
                                  onChange={handleInputChange}
                                />
                                {pageSlugError && (
                                  <div className="text-danger">
                                    {pageSlugError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Parent Link
                              </label>
                              <div className="col-lg-10">
                                <select
                                  className={
                                    parentPageError
                                      ? " form-select is-invalid"
                                      : "form-control "
                                  }
                                  styles="max-width: 90px;"
                                  value={parentlink}
                                  name="parentlink"
                                  onChange={handleInputChange}
                                >
                                  <option value="0">None of options</option>
                                  {managePageData?.map((item) => (
                                    <option value={item.id}>
                                      {item.page_name}
                                    </option>
                                  ))}
                                </select>
                                {parentPageError && (
                                  <div className="text-danger">
                                    {parentPageError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Header / Footer
                              </label>
                              <div className="col-lg-10">
                                <select
                                  className={
                                    headerFooterError
                                      ? " form-select is-invalid"
                                      : "form-control "
                                  }
                                  styles="max-width: 90px;"
                                  value={header_footer}
                                  name="header_footer"
                                  onChange={handleInputChange}
                                >
                                  <option value="0">None of options</option>
                                  <option value="2">Footer</option>
                                  <option value="1">Header</option>
                                </select>
                                {headerFooterError && (
                                  <div className="text-danger">
                                    {headerFooterError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Short Order
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="shortOrder"
                                  type="number"
                                  className={
                                    shortOrderError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter shhort order..."
                                  value={shortOrder}
                                  onChange={handleInputChange}
                                />
                                {shortOrderError && (
                                  <div className="text-danger">
                                    {shortOrderError}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            {pageError && (
                              <div className="alert alert-danger" role="alert">
                                {pageError}
                              </div>
                            )}
                            <button type="submit" className="btn btn-primary">
                              Edit Page
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPage;
