import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import { encode } from "html-entities";
import Select from "react-select";

const AddContentPage = () => {
  const [formData, setFormData] = useState({
    parent_link: "",
    page_name: "",
    page_title: "",
    page_url: "",
    short_description: "",
    description: "",
    page_slider_description: "",
    page_image: "",
    in_menu: "",
    menu_order: "",
  });

  const {
    parent_link,
    page_name,
    page_title,
    page_url,
    short_description,
    description,
    page_slider_description,
    page_image,
    in_menu,
    menu_order,
  } = formData;

  const [image, setImage] = useState();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const [urlValue, setUrlValue] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [managePageData, setManagePageData] = useState();
  const [pageError, setPageError] = useState("");
  const [token, setToken] = useState(localStorage.getItem("cms_token"));

  const { id } = useParams();

  console.log(id, "id");

  useEffect(() => {
    setToken(localStorage.getItem("cms_token"));
    axios
      .get(`${API_BASE_URL}/get-page-and-content`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        setManagePageData(response.data.data.reverse());
      })
      .catch((error) => {
        console.log(error);
      });

    if (id) {
      fetchPrefilled(id);
    }
  }, []);

  const fetchPrefilled = async (id) => {
    try {
      setToken(localStorage.getItem("cms_token"));
      // setIsLoading(true);

      const enquiryResponse = await axios.get(
        `${API_BASE_URL}/get-manage-content-details?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const enquiryRes = JSON.parse(JSON.stringify(enquiryResponse.data));
      console.log(enquiryRes, "res");

      if (enquiryRes.status == 200) {
        // setIsLoading(false);
        setFormData({
          ...formData,
          parent_link: enquiryRes.data.parent_link,
          page_name: enquiryRes.data.page_name,
          page_title: enquiryRes.data.page_title,
          page_url: enquiryRes.data.page_url,
          short_description: enquiryRes.data.short_description,
          description: enquiryRes.data.description,
          page_slider_description: enquiryRes.data.page_slider_description,
          in_menu: enquiryRes.data.in_menu,
          menu_order: enquiryRes.data.menu_order,
          // page_image: enquiryRes.data.page_image,
        });

        setImagePreview(enquiryRes.data.page_image);
      }
    } catch (error) {}
  };

  const getContentDetail = async (item) => {
    let id = {
      id: item,
    };
    axios
      .get(`${API_BASE_URL}/get-manage-content-details`, id, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        setManagePageData(response.data.data.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEdiorChangeShortDescription = (content, editor) => {
    setFormData({ ...formData, short_description: content });
  };

  const handleEditorChangePageSlider = (content, editor) => {
    setFormData({ ...formData, page_slider_description: content });
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const sampledata = [{value:"null", label:"Select"}]
  managePageData?.forEach((item) => {
    sampledata?.push({ value: item.id, label: item.page_name });
  });

  const validateForm = () => {
    let errors = {};
    // check for empty values

    // if (!formData.parent_link) {
    //   errors.parent_link = "Please select parent link";
    // }
    if (!formData.page_name) {
      errors.page_name = "Please enter Page Name";
    }
    // if (!formData.page_title) {
    //   errors.page_title = "Please enter the title";
    // }
    // if (!formData.page_url) {
    //   errors.page_url = "Please add page url";
    // }
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();

    // formData.page_url = urlValue;
    const form = new FormData();
    form.append("parent_link", parent_link);
    form.append("page_name", page_name);
    form.append("page_title", page_title);
    form.append("page_url", page_url);
    form.append("in_menu", in_menu);
    form.append("menu_order", menu_order);
    form.append("short_description", short_description);
    form.append("description", description);
    form.append("page_slider_description", page_slider_description);
    form.append("page_image", image);

    console.log(form, "formdata");
    console.log(image, "IMAGE");

    if (Object.keys(errors).length === 0) {
      if (!id) {
        axios
          .post(`${API_BASE_URL}/add-page-and-content`, form, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data.status);
            if (response.data.status === 200) {
              navigate("/cms/managecontentpage");
              toast.success("Added successfully");
            }
          })
          .catch((error) => {
            console.log(error);
            setPageError(error.response.data.result);
          });
      } else {
        form.append("id", id);
        form.append("status", "update");

        axios
          .post(`${API_BASE_URL}/add-page-and-content`, form, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data.status);
            if (response.data.status === 200) {
              navigate("/cms/managecontentpage");
              toast.success("Added successfully");
            }
          })
          .catch((error) => {
            console.log(error);
            setPageError(error.response.data.result);
          });
      }
    } else {
      setErrors(errors);
      console.log(errors);
    }
    console.log(formData, "form");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    if (name === "page_image") {
      setImage(event.target.files[0]);
      // setFormData({ ...formData, [name]: event.target.files[0] });
      console.log(event.target.files, "event.target.files[0]");
      const selectedImage = event.target.files[0];
      if (!selectedImage) {
        setImagePreview(null);
        return;
      }
      const objectUrl = URL.createObjectURL(selectedImage);
      setImagePreview(objectUrl);
    }

    if (name == "page_name") {
      const url = value
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .replace(/\s+/g, "-");
      setUrlValue(url);

      setFormData({ ...formData, [name]: value, page_url: url });
      setErrors({ ...errors, [name]: "", page_url: "" });
    }
  };

  const selectStyles = (errorFieldName) => ({
    control: (provided) => ({
      ...provided,
      borderColor: errors[errorFieldName] ? "#dc3545" : provided.borderColor,
      boxShadow: errors[errorFieldName]
        ? "0 0 0 0.2rem rgba(220, 53, 69, 0.25)"
        : provided.boxShadow,
      minHeight: 22,
      height: 33,
    }),
  });

  const handleSelect = (fieldName, selectedOption) => {
    // setSelectedOption(selectedOption);
    console.log(selectedOption, "opton");
    setFormData({ ...formData, [fieldName]: selectedOption.value });
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };

  const in_Menu_Options = [
    { value: 0, label: "Not Apply" },
    { value: 1, label: "Header" },
    { value: 2, label: "Fotter" },
  ];

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Add Content Page </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/cms/managecontentpage"}>
                          Manage Content Page
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Add Content Page
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Add Content Page</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                          <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                In Menu
                              </label>
                              <div className="col-lg-10">
                                <Select
                                  className={`custom-select custom-select-sm`}
                                  options={in_Menu_Options}
                                  value={in_Menu_Options.filter(
                                    (option) => option.value == in_menu
                                  )}
                                  styles={selectStyles("in_menu")}
                                  name="in_menu"
                                  onChange={(selectedOption) =>
                                    handleSelect("in_menu", selectedOption)
                                  }
                                >
                                  <option value="">Select an option</option>
                                  {in_Menu_Options?.map((item) => (
                                    <option value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Parent Link
                              </label>
                              <div className="col-lg-10">
                                <Select
                                  className={`custom-select custom-select-sm`}
                                  options={sampledata}
                                  value={sampledata.filter(
                                    (option) => option.value == parent_link
                                  )}
                                  styles={selectStyles("parent_link")}
                                  name="parent_link"
                                  onChange={(selectedOption) =>
                                    handleSelect("parent_link", selectedOption)
                                  }
                                >
                                  <option value="">Select an option</option>
                                  {managePageData?.map((item) => (
                                    <option value={item.id}>
                                      {item.page_name}
                                    </option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Page Name
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="page_name"
                                  type="text"
                                  value={page_name}
                                  className={
                                    errors.page_name
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Page Name..."
                                  onChange={(e) => {
                                    handleInputChange(e);
                                  }}
                                />
                                {errors.page_name && (
                                  <div className="text-danger">
                                    {errors.page_name}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Page Title
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="page_title"
                                  type="text"
                                  value={page_title}
                                  className={"form-control "}
                                  placeholder="Enter Page Title..."
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Page Url
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="page_url"
                                  value={page_url}
                                  placeholder="Enter PageUrl"
                                  onChange={handleInputChange}
                                  type="text"
                                  className={
                                    errors.page_url
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                />
                                {errors.page_url && (
                                  <div className="text-danger">
                                    {errors.page_url}
                                  </div>
                                )}
                              </div>
                            </div>

                           

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Menu Order
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="menu_order"
                                  type="number"
                                  value={menu_order}
                                  className={"form-control "}
                                  placeholder="Enter Menu Order..."
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Short Description
                              </label>
                              <div className="col-lg-10">
                                <Editor
                                  onEditorChange={
                                    handleEdiorChangeShortDescription
                                  }
                                  value={short_description}
                                  init={{
                                    plugins:
                                      "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    table_default_attributes: {
                                      border: "5",
                                    },
                                    valid_elements: '*[*]', // Allow all elements and attributes
                                    // extended_valid_elements: 'div[itemtype|itemscope|itemprop], span[itemtype|itemscope|itemprop],h3[itemtype|itemscope|itemprop]',
                                  }}
                                />
                                {/* {shortDescriptionError && (
                                <div className="text-danger">
                                  {shortDescriptionError}
                                </div>
                              )} */}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Description
                              </label>
                              <div className="col-lg-10">
                                <Editor
                                  onEditorChange={handleEditorChangeDescription}
                                  value={description}
                                  init={{
                                    plugins:
                                      "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    table_default_attributes: {
                                      border: "5",
                                    },
                                    valid_elements: '*[*]',
                                  }}
                                />
                                {/* {descriptionError && (
                                  <div className="text-danger">
                                    {descriptionError}
                                  </div>
                                )} */}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Page Slider Description
                              </label>
                              <div className="col-lg-10">
                                <Editor
                                  onEditorChange={handleEditorChangePageSlider}
                                  value={page_slider_description}
                                  init={{
                                    plugins:
                                      "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    table_default_attributes: {
                                      border: "5",
                                    },
                                    valid_elements: '*[*]',
                                  }}
                                />
                                {/* {pageSliderError && (
                                <div className="text-danger">
                                  {pageSliderError}
                                </div>
                              )} */}
                              </div>
                            </div>

                            <div className="inner-repeater mb-4">
                              <div
                                data-repeater-list="inner-group"
                                className="inner form-group mb-0 row"
                              >
                                <label className="col-form-label col-lg-2">
                                  Image
                                </label>
                                <div
                                  data-repeater-item
                                  className="inner col-lg-10 ms-md-auto"
                                >
                                  <div className="mb-3 row align-items-center">
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-md-0">
                                        <input
                                          className={"form-control "}
                                          name="page_image"
                                          value={page_image}
                                          type="file"
                                          accept="image/*"
                                          onChange={(e) => {
                                            handleImageChange(e);
                                            handleInputChange(e);
                                          }}
                                        />
                                        {/* { errors.page_image && (
                                          <div className="text-danger">
                                            { errors.page_image}
                                          </div>
                                        )} */}
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-20">
                                        <div>
                                          {imagePreview && (
                                            <img
                                              src={imagePreview}
                                              alt="Preview"
                                              width="200"
                                              height="200"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            {pageError && (
                              <div className="alert alert-danger" role="alert">
                                {pageError}
                              </div>
                            )}

                            <button type="submit" className="btn btn-primary">
                              Add Content Page
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddContentPage;
