import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL, API_URL } from "../../config";
import { toast } from "react-toastify";

const EditSocials = () => {
  const [formData, setFormData] = useState({
    name: "",
    link: "",
    image: "",
  });
  const [show, setShow] = useState(true);
  const [image, setImage] = useState("");
  const [nameError, setNameError] = useState("");
  const [imageError, setImageError] = useState("");
  const [link, setLink] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [pageError, setPageError] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));

  const navigate = useNavigate();
  let hasError = false;
  const { id } = useParams();
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    ViewPage();
  }, []);

  const ViewPage = async () => {
    const res = await axios.get(`${API_BASE_URL}/view-social/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.data.status == 200) {
      setFormData({
        ...formData,
        name: res.data.result.title,
        link: res.data.result.link,
        image: res.data.result.icon,
      });
      setImage(res.data.result.icon);
      setImagePreview(res.data.result.icon);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData, "form");

    if (!formData.name) {
      setNameError("Please enter name");
      hasError = true;
    } else {
      setNameError("");
    }

    if (!formData.link) {
      setLink("Please enter video link ");
      hasError = true;
    } else {
      setLink("");
    }

    if (!formData.image) {
      setImageError("Please select the image");
      hasError = true;
    } else {
      setImageError("");
    }

    const form = new FormData();
    form.append("title", formData.name);
    form.append("link", formData.link);
    form.append("social", image);
    form.append("id", id);

    if (!hasError) {
      axios
        .post(`${API_BASE_URL}/update-social`, form, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status === "200") {
            navigate("/cms/managesocialmedia");
            toast.success("Socail Edited  successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          setPageError(error.response.data.result);
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (name === "image") {
      setShow(false);
      setImage(event.target.files[0]);
    }
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const handleImageChange = (e) => {
    setShow(false);
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-name-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Edit Socials </h4>

                  <div className="page-name-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/cms/managesocialmedia"}>
                          Manage SocialMedia
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">Edit Socials </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-name mb-4">Edit Socials</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Name
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="name"
                                  type="text"
                                  value={formData.name}
                                  className={
                                    nameError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter name..."
                                  onChange={handleInputChange}
                                />
                                {nameError && (
                                  <div className="text-danger">{nameError}</div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Link
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="link"
                                  type="text"
                                  value={formData.link}
                                  className={
                                    link
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter short order..."
                                  onChange={handleInputChange}
                                />
                                {link && (
                                  <div className="text-danger">{link}</div>
                                )}
                              </div>
                            </div>

                            <div className="inner-repeater mb-4">
                              <div
                                data-repeater-list="inner-group"
                                className="inner form-group mb-0 row"
                              >
                                <label className="col-form-label col-lg-2">
                                  Logo
                                </label>
                                <div
                                  data-repeater-item
                                  className="inner col-lg-10 ms-md-auto"
                                >
                                  <div className="mb-3 row align-items-center">
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-md-0">
                                        <input
                                          className={
                                            imageError
                                              ? "form-control is-invalid"
                                              : "form-control "
                                          }
                                          name="image"
                                          type="file"
                                          accept="image/*"
                                          onChange={(e) => {
                                            handleImageChange(e);
                                            handleInputChange(e);
                                          }}
                                        />
                                        {imageError && (
                                          <div className="text-danger">
                                            {imageError}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="mt-4 mt-20">
                                        <div>
                                          {imagePreview && (
                                            <>
                                              {show === true ? (
                                                <img
                                                  src={`${API_URL}${imagePreview}`}
                                                  alt="Preview"
                                                  width="200"
                                                  height="200"
                                                />
                                              ) : (
                                                <img
                                                  src={imagePreview}
                                                  alt="Preview"
                                                  width="200"
                                                  height="200"
                                                />
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            {pageError && (
                              <div className="alert alert-danger" role="alert">
                                {pageError}
                              </div>
                            )}
                            <button type="submit" className="btn btn-primary">
                              Edit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSocials;
