import React from "react";

const NotFoundPage = () => {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid"></div>
        <h2> Page Not found</h2>
      </div>
    </div>
  );
};

export default NotFoundPage;
