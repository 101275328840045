import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { API_BASE_URL } from "../config";

import {
  loginFailure,
  loginRequest,
  loginSuccess,
} from "../store/auth/authSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [pageError, setPageError] = useState("");
  const [logo, setLogo] = useState("");
  const [fav, setFav] = useState("");
  const [name, setName] = useState("")

  useEffect(() => {
    websitelogo();
  }, []);

  const websitelogo = async () => {
    try {
      const logo = await axios.get(API_BASE_URL + "/get-web-company-profile");
      const weblogo = JSON.parse(JSON.stringify(logo.data));
      if (weblogo.status == 200) {
        console.log(weblogo, "weblogo");
        setLogo(weblogo.data.logo);
        setFav(weblogo.data.favicon_icon);
        setName(weblogo.data.company_name)
        localStorage.setItem("logo_url", weblogo.data.logo);
      }
    } catch (e) {
      return false;
    }
  };

  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    dispatch(loginRequest());
    event.preventDefault();
    if (!username) {
      setUsernameError("Please enter your username");
    } else {
      setUsernameError("");
    }
    if (!password) {
      setPasswordError("please enter your password");
    } else {
      setPasswordError("");
    }

    await axios
      .post(`${API_BASE_URL}/cms-login`, {
        email: username,
        password: password,
      })
      .then(function (response) {
        console.log(response.data);
        if (response.status === 200) {
          localStorage.setItem("cms_token", response.data.token);
          localStorage.setItem(
            "trade_user_type",
            response.data.user_data.user_type
          );
          localStorage.setItem(
            "trade_user_data",
            JSON.stringify(response?.data?.user_data)
          );
          localStorage.setItem(
            "trade_user_id",
            JSON.stringify(response?.data?.user_data?.id)
          );

          dispatch(loginSuccess(response.data));
          navigate("/cms/home");
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error?.response?.data?.error);
        dispatch(loginFailure("An error occurred"));
        setPageError(error.response.data.result);
      });
  };

  return (
    <>
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card overflow-hidden">
                <div className="bg-primary bg-soft">
                  <div className="row">
                    <div className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue {name}.</p>
                      </div>
                    </div>
                    <div className="col-5 align-self-center ">
                      <img src={`${logo}`} alt="" className="img-fluid px-3" />
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="auth-logo">
                    <a href="index.html" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={`${fav}`}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </a>

                    <a href="index.html" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={`${fav}`}
                            alt=""
                            
                            height="34"
                          />
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="p-2">
                    <form className="form-horizontal" onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="username" className="form-label">
                          Username
                        </label>
                        <input
                          type="text"
                          className={
                            usernameError
                              ? "form-control is-invalid"
                              : "form-control "
                          }
                          id="username"
                          name="name"
                          placeholder="Enter username"
                          value={username}
                          onChange={(event) => setUsername(event.target.value)}
                        />
                        {usernameError && (
                          <div className="text-danger">{usernameError}</div>
                        )}
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input
                          type="password"
                          className={
                            passwordError
                              ? "form-control is-invalid"
                              : "form-control "
                          }
                          placeholder="Enter password"
                          aria-label="Password"
                          aria-describedby="password-addon"
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                        />
                        {passwordError && (
                          <div className="text-danger">{passwordError}</div>
                        )}

                        {/* <button
                          className="btn btn-light "
                          type="button"
                          name="password"
                          id="password-addon"
                        >
                          <i className="mdi mdi-eye-outline"></i>
                        </button> */}
                      </div>

                      {/* <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="remember-check"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="remember-check"
                        >
                          Remember me
                        </label>
                      </div> */}

                      <div className="mt-3 d-grid">
                        {pageError && (
                          <div className="alert alert-danger" role="alert">
                            {pageError}
                          </div>
                        )}
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
