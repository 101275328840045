import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

const AddPricingPlan = () => {
  const [formData, setFormData] = useState({
    title: "",
    currencytype: "",
    newprice: "",
    oldprice: "",
    color: "",
    durationtype: "",
    duration: "",
    description: "",
    features: "",
  });
  const [titleError, setTitleError] = useState("");
  const [currencyType, setCurrencyType] = useState("");
  const [newPriceError, setNewPriceError] = useState("");
  const [oldPriceError, setOldPriceError] = useState("");
  const [colorError, setColorError] = useState("");
  const [durationTypeError, setDurationTypeError] = useState("");
  const [durationError, SetDurationError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [featuresError, setFeaturesError] = useState("");

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };
  const handleEditorChangeFeatures = (content, editor) => {
    setFormData({ ...formData, features: content });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData, "form");

    if (!formData.title) {
      setTitleError("Please enter title");
    } else {
      setTitleError("");
    }
    if (!formData.currencytype) {
      setCurrencyType("please Currency Type");
    } else {
      setCurrencyType("");
    }
    if (!formData.newprice) {
      setNewPriceError("Please enter new price");
    } else {
      setNewPriceError("");
    }
    if (!formData.oldprice) {
      setOldPriceError("please enter oldprice ");
    } else {
      setOldPriceError("");
    }
    if (!formData.color) {
      setColorError("Please enter the color");
    } else {
      setColorError("");
    }
    if (!formData.durationtype) {
      setDurationTypeError("Please enter the duration type ");
    } else {
      setDurationTypeError("");
    }
    if (!formData.duration) {
      SetDurationError("Please enter the duration ");
    } else {
      SetDurationError("");
    }

    if (!formData.description) {
      setDescriptionError("Please enter the description");
    } else {
      setDescriptionError("");
    }

    if (!formData.features) {
      setFeaturesError("Please enter the features ");
    } else {
      setFeaturesError("");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Add Plan </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/managepricingplans"}>
                          Manage Plans Page
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">Add Plan </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Add Price</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Title
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="title"
                                  type="text"
                                  className={
                                    titleError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter title..."
                                  onChange={handleInputChange}
                                />
                                {titleError && (
                                  <div className="text-danger">
                                    {titleError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Currency type
                              </label>
                              <div className="col-lg-10">
                                <select
                                  className="form-select"
                                  styles="max-width: 90px;"
                                  name="currencytype"
                                  onChange={handleInputChange}
                                >
                                  <option value="BT">BTC</option>
                                  <option value="ET">ETH</option>
                                  <option value="LT">LTC</option>
                                </select>
                                {currencyType && (
                                  <div className="text-danger">
                                    {currencyType}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                New Price
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="newprice"
                                  type="text"
                                  className={
                                    newPriceError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter new price..."
                                  onChange={handleInputChange}
                                />
                                {newPriceError && (
                                  <div className="text-danger">
                                    {newPriceError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Old Price
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="oldprice"
                                  type="text"
                                  className={
                                    oldPriceError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter old price ..."
                                  onChange={handleInputChange}
                                />
                                {oldPriceError && (
                                  <div className="text-danger">
                                    {oldPriceError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Color
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="color"
                                  type="text"
                                  className={
                                    colorError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter color ..."
                                  onChange={handleInputChange}
                                />
                                {colorError && (
                                  <div className="text-danger">
                                    {colorError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Duration type
                              </label>
                              <div className="col-lg-10">
                                <select
                                  className="form-select"
                                  styles="max-width: 90px;"
                                  name="durationtype"
                                  onChange={handleInputChange}
                                >
                                  <option value="BT">BTC</option>
                                  <option value="ET">ETH</option>
                                  <option value="LT">LTC</option>
                                </select>
                                {durationTypeError && (
                                  <div className="text-danger">
                                    {durationTypeError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Duration
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="duration"
                                  type="text"
                                  className={
                                    durationError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter duration ..."
                                  onChange={handleInputChange}
                                />
                                {durationError && (
                                  <div className="text-danger">
                                    {durationError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Description
                              </label>
                              <div className="col-lg-10">
                                <Editor
                                  onEditorChange={handleEditorChangeDescription}
                                  init={{
                                    plugins: "table",
                                    table_default_attributes: {
                                      border: "5",
                                    },
                                  }}
                                />
                                {descriptionError && (
                                  <div className="text-danger">
                                    {descriptionError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label className="col-form-label col-lg-2">
                                Features
                              </label>
                              <div className="col-lg-10">
                                <Editor
                                  onEditorChange={handleEditorChangeFeatures}
                                  init={{
                                    plugins: "table",
                                    table_default_attributes: {
                                      border: "5",
                                    },
                                  }}
                                />
                                {featuresError && (
                                  <div className="text-danger">
                                    {featuresError}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            <button type="submit" className="btn btn-primary">
                              Add Plans
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPricingPlan;
