import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../Loader/loader";
import Pagination from "@mui/material/Pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";


const ManageUserData = () => {
  const { search } = useLocation();
  let filter_param = new URLSearchParams(search).get("filter");

  const [filter, setFilter] = useState(filter_param != "" ? filter_param : "");
  const [listPageData, setListPageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  const [filterStatus, setFilterStatus] = useState(false);
  const [status, setStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState();
  const [pageApi, setPageApi] = useState(1);
  const [totalUser, setTotalUser] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [plans, setPlans] = useState([]);

  const [filterData, setFilterData] = useState({
    name: "",
    user_type: "",
    subscription_type: "",
    start_date: "",
    end_date: "",
    plan_type: "",
    plan_id: "",
  });
  const {
    name,
    user_type,
    subscription_type,
    start_date,
    end_date,
    plan_type,
    plan_id,
  } = filterData;

  useEffect(() => {
    setToken(localStorage.getItem("cms_token"));
    getUserList(pageApi);
    loadplan2();
  }, [pageApi, filterStatus]);

  useEffect(() => {
    console.log(filter, "filter");
    if (filter) {
      if (filter === "trial") {
        setFilterData({ ...filterData, subscription_type: "free_trial" });
        setFilterStatus(!filterStatus);
      } else if (filter === "paid")
        setFilterData({ ...filterData, subscription_type: "paid" });
      setFilterStatus(!filterStatus);
    }
  }, [filter]);

  const getUserList = async () => { 
    setIsLoading(true);
    await axios
      .get(`${API_BASE_URL}/get-user-list`, {
        params: {
          page: pageApi,
          limit: 10,
          ...filterData,                     
        }, 
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setListPageData(response.data.data.reverse());
        setTotalUser(response?.data?.total);
        const totalPageCount = Math.ceil(response?.data?.total / 10);
        setPageSize(totalPageCount);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const loadplan2 = async () => {
    setIsLoading(true);
    try {    
      setIsLoading(true);
      const plan_data = await axios.get(API_BASE_URL + "/get-subscrpton-plan");
      const pricing_plan = JSON.parse(JSON.stringify(plan_data.data));
      console.log(plan_data.data.data, "plan_data");

      console.log(pricing_plan, "pricing_plan.data");

      if (pricing_plan.status == 200) {
        setPlans(plan_data.data.data);
      }
    } catch (e) {
      return false;
    }
  };

  const ClearFilter = () => {
    setFilterData({
      name: "", 
      user_type: "",
      subscription_type: "",
      start_date: "",
      end_date: "",
      plan_type: "",
      plan_id: "",
    }); 
  }; 

  const handlePositive = (id) => {
    const result = window.confirm(
      "Do you want to make this enquiry positive ?"
    );

    if (result) {
      setIsLoading(true);

      axios
        .get(`${API_BASE_URL}/pasitive-enquiry/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === "200") {
            toast.success("Enquiry Added To Positive List successfully");
            setIsLoading(false);

            axios
              .get(`${API_BASE_URL}/list-enquiry`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((response) => {
                setListPageData(response.data.result.reverse());
              })
              .catch((error) => {
                setIsLoading(false);
                console.log(error);
              });
          }
        });
    }
  };

  const handleNegative = (id) => {
    const result = window.confirm(
      "Do you want to make this enquiry Negative ?"
    );

    if (result) {
      axios
        .get(`${API_BASE_URL}/negative-enquiry/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status === "200") {
            toast.success("Enquiry Added To Negative List successfully");
            axios
              .get(`${API_BASE_URL}/list-enquiry`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((response) => {
                setListPageData(response.data.result.reverse());
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    }
  };

  const subscription_status_Options = [
    { value: "0", label: "Unsubscribed" },
    { value: "1", label: "Subscribed" },
  ];

  const user_type_Options = [
    { value: "admin", label: "Admin" },
    { value: "customer", label: "Customer" },
  ];

  const in_Menu_Options = [
    { value: "monthly", label: "Monthly" },
    { value: "annualy", label: "Annualy" },
    { value: "Free Trial", label: "Free Trial" },
  ];

  const subscription_type_Options = [
    { value: "free_trial", label: "Free Trail" },
    { value: "paid", label: "Paid" },
  ];

  const plan_name_options = [
    { value: "basic", label: "Basic" },
    { value: "plus", label: "Plus" },
    { value: "pro", label: "Pro" },
  ];

  const handleSelect = (fieldName, selectedOption) => {
    console.log(selectedOption, "opton");
    setFilterData({ ...filterData, [fieldName]: selectedOption.value });
  };

  const handleDelete = (id) => {
    const result = window.confirm("Do you want to delete ?");
    
    if (result) {
      axios
        .post(`${API_BASE_URL}/delete-subscribed-user?user_id=${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // params:{
          //   user_id : id,
          // }
        })
        .then((response) => {
          console.log(response,'delete')
          if (response.data.status === 200) {
            toast.success("Delete successully");
            getUserList();
          }
        });
    }
  };

  const handleActive = async (status, id) => {
    let updatedStatus = status == 0 ? 1 : 0;
    const res = await axios.post(
      `${API_BASE_URL}/manage-user-status?status=${updatedStatus}&user_id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const response = JSON.parse(JSON.stringify(res));
    console.log(response);
    if (response.data.status === 200) {
      getUserList();
      if (status == 1) {
        toast.success("Active successfully");
      } else {
        toast.success("Inactive successfully");
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const selectStyles = (errorFieldName) => ({
    control: (provided) => ({
      ...provided,
      borderColor: provided.borderColor,
      boxShadow: provided.boxShadow,
      minHeight: 22,
      height: 33,
    }),
  });

  const planOption = [];
  plans?.forEach((item) => {
    planOption.push({ value: item.id, label: item.name });
  });

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Manage User Data </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Manage User Data
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-body rounded-3"
                    style={{ backgroundColor: "#e57c2ef2" }}
                  >
                    <div className="row">
                      <div className="col-3 mt-2">
                        <div className="form-group row mb-4 px-3">
                          <label
                            for="taskname"
                            className="col-form-label text-white col-lg-8"
                          >
                            Plan
                          </label>
                          <Select
                            className={`custom-select custom-select-sm`}
                            options={planOption}
                            value={planOption.filter(
                              (option) => option.value == plan_id
                            )}
                            styles={selectStyles("plan_id")}
                            name="plan_id"
                            onChange={(selectedOption) =>
                              handleSelect("plan_id", selectedOption)
                            }
                          >
                            <option value="">Select an option</option>
                            {planOption?.map((item) => (
                              <option value={item.value}>{item.label}</option>
                            ))}
                          </Select>
                        </div>
                      </div>

                      <div className="col-3 mt-2">
                        <div className="form-group row mb-4 px-3">
                          <label
                            for="taskname"
                            className="col-form-label text-white col-lg-8"
                          >
                            User Type
                          </label>
                          <Select
                            className={`custom-select custom-select-sm`}
                            options={user_type_Options}
                            value={user_type_Options.filter(
                              (option) => option.value == user_type
                            )}
                            placeholder="Select Plan"
                            styles={selectStyles("user_type")}
                            name="user_type"
                            onChange={(selectedOption) =>
                              handleSelect("user_type", selectedOption)
                            }
                          >
                            <option value="">Select an option</option>
                            {user_type_Options?.map((item) => (
                              <option value={item.value}>{item.label}</option>
                            ))}
                          </Select>
                        </div>
                      </div>

                      <div className="col-3 mt-2">
                        <div className="form-group row mb-4 px-3">
                          <label
                            for="taskname"
                            className="col-form-label text-white col-lg-8"
                          >
                            Subscription Type
                          </label>
                          <Select
                            className={`custom-select custom-select-sm`}
                            options={subscription_type_Options}
                            value={subscription_type_Options.filter(
                              (option) => option.value == subscription_type
                            )}
                            placeholder="Select Plan"
                            styles={selectStyles("subscription_type")}
                            name="subscription_type"
                            onChange={(selectedOption) =>
                              handleSelect("subscription_type", selectedOption)
                            }
                          >
                            <option value="">Select an option</option>
                            {subscription_type_Options?.map((item) => (
                              <option value={item.value}>{item.label}</option>
                            ))}
                          </Select>
                        </div>
                      </div>

                      <div className="col-3 mt-2">
                        <div className="form-group row mb-4 px-3">
                          <label
                            for="taskname"
                            className="col-form-label text-white col-lg-8"
                          >
                            Plan Type
                          </label>
                          <Select
                            className={`custom-select custom-select-sm`}
                            options={in_Menu_Options}
                            value={in_Menu_Options.filter(
                              (option) => option.value == plan_type
                            )}
                            placeholder="Select Plan"
                            styles={selectStyles("plan_type")}
                            name="plan_type"
                            onChange={(selectedOption) =>
                              handleSelect("plan_type", selectedOption)
                            }
                          >
                            <option value="">Select an option</option>
                            {in_Menu_Options?.map((item) => (
                              <option value={item.value}>{item.label}</option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className="col-3 mt-2">
                        <div className="form-group row mb-4 px-3">
                          <label
                            for="taskname"
                            className="col-form-label text-white col-lg-8"
                          >
                            Name
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Enter Name"
                            value={name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-3 d-flex align-items-center pt-3" >
                        {/* <span  className="me-2">Start Date</span> */}
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          dateFormat="dd-MM-yyyy"
                          wrapperClassName="datePicker "
                          placeholderText="Start Date"
                          // startDate={new Date()}
                          // endDate={endDate}
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center pt-3">
                        {/* <span className="me-2">End Date</span> */}
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          dateFormat="dd-MM-yyyy"
                          wrapperClassName="datePicker w-100"
                          placeholderText="End Date"

                          // endDate={endDate}
                          // minDate={startDate}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between">
                      <div className="col-3 clear-filter d-flex text-start">
                        <button onClick={() => ClearFilter()}>
                          Clear Filter
                        </button>
                      </div>
                      <div className="col-3 date-submit">
                        <button
                          style={{ height: "40px" }}
                          onClick={() => getUserList()}
                        >
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-end">
              <div className="col-lg-12 mb-2 ">
                <Link
                  to={"/cms/manageUserData/addUser"}
                  data-repeater-create
                  type="button"
                  className="btn btn-success inner"
                  value="Add Number"
                >
                  Add Customer
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">
                      Customer List ({totalUser})
                    </h4>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <div className="table-responsive">
                        <table className="table align-middle table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th className="align-middle">Name</th>
                              <th className="align-middle">Email</th>
                              <th className="align-middle">Mobile</th>
                              <th className="align-middle">Plan </th>
                              <th className="align-middle">Plan Type</th>
                              <th className="align-middle">Start Date</th>
                              <th className="align-middle">End Date</th>
                              <th className="align-middle">Status</th>
                              <th className="align-middle">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listPageData?.map((item) => (
                              <>
                                <tr>
                                  <td>{item.name}</td>

                                  <td>{item.email}</td>
                                  <td>
                                    {item.mobile ? item.mobile : "N/A"}
                                    {/* <button className="btn btn-sm btn-success ms-2">
                                      <i className="fa fa-phone-alt"></i>
                                    </button> */}
                                  </td>
                                  <td>
                                    {item.plan_name ? item?.plan_name : "N/A"}
                                  </td>
                                  <td>
                                    {item.plan_type ? item?.plan_type : "N/A"}
                                  </td>
                                  <td>
                                    {item.subscription_start_date ? moment(item?.subscription_start_date).format("DD MMM YYYY") : "N/A"}
                                  </td>
                                  <td>
                                    {item.subscription_end_date ? moment(item?.subscription_end_date).format("DD MMM YYYY") : "N/A"}
                                  </td>
                                  <td>
                                    {item.is_active == 1 ? (
                                      <span className="badge badge-pill badge-soft-success  font-size-11">
                                        Active
                                      </span>
                                    ) : item.is_active == 0 ? (
                                      <span className="badge badge-pill badge-soft-danger font-size-11">
                                        Inactive
                                      </span>
                                    ) : (
                                      <span className="badge badge-pill badge-soft-warning font-size-11">
                                        Pending
                                      </span>
                                    )}
                                  </td>

                                  <td>
                                    <Link
                                      to={`/cms/manageUserData/UserData/viewUser/${item.user_id}`}
                                      type="button"
                                      className="btn btn-success btn-sm btn-rounded waves-effect waves-light"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Link>
                                    <Link
                                      to={`/cms/manageUserData/editUser/${item.user_id}`}
                                      type="button"
                                      className="btn btn-primary btn-sm btn-rounded waves-effect waves-light ms-2 "
                                    >
                                      <i className="fa fa-pen"></i>
                                    </Link>
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-sm btn-rounded waves-effect waves-light ms-2"
                                      onClick={() => {
                                        handleDelete(item.user_id);
                                      }}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>

                                    <button
                                      type="button"
                                      className="btn btn-warning btn-sm btn-rounded  ms-2"
                                      onClick={() => {
                                        handleActive(item.is_active, item.user_id);
                                      }}
                                    >
                                      <i className="fa fa-ban"></i>
                                    </button>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {!isLoading    && (
                      <div className="d-flex justify-content-center mt-2">
                        <Pagination
                          count={pageSize}
                          page={pageApi}
                          onChange={(e, value) => {
                            console.log(value, "vslue");
                            setPageApi(value);
                          }}
                          color="primary"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageUserData;
