import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { API_URl, BASE_URL, IMG_URl, API_BASE_URL } from "../config";
import { API_BASE_URL, IMG_URl, BASE_URL } from "../../config";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import Select from "react-select";
import Loader from "../Loader";
import { CSVLink } from "react-csv";
// import '../../../../assets/css/search.css'

const Manage_Search = () => {
  const [statusList, setStatusList] = useState();
  const [classList, setClassList] = useState([]);
  const [tradeMarkList, setTradeMarkList] = useState();
  const [tmCategoryList, setTmCategoryList] = useState();
  const [fillingModeList, setFilingModeList] = useState();
  const [tradeMarkInfo, setTradeMarkInfo] = useState({
    trademark_info: [],
    status: [],
    filling_mode: [],
    tm_category: [],
  });

  const { trademark_info, status, filling_mode, tm_category } = tradeMarkInfo;
  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  const [userId, setUserId] = useState(
    JSON.parse(localStorage.getItem("trade_user_data"))
  );
  const [load, setLoad] = useState(false);

  const [loading, setLoading] = useState(false);
  const [searchedData, setSearcedData] = useState([]);
  const [pageSize, setPageSize] = useState();
  const [date, setDate] = useState({
    start_date: "",
    end_date: "",
  });
  const [pageApi, setPageApi] = useState(1);
  const [selectedOption, setSelectedOption] = useState("Word Mark");
  const [searchedValue, setSearchedvalue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const startDateInputRef = useRef(null);
  const endDateInputRef = useRef(null);
  const searchInputRef = useRef(null);

  const wrapperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [excelLink, setExcelLink] = useState();
  const navigate = useNavigate();

  // data declared to be used in table taking data from JSON file
  const data = useMemo(() => searchedData, []);

  // Contains the column headers and table data in the required format for CSV
  const csvData = [
    [
      "Company Name",
      "Status",
      "Mobile",
      "Email",
      "TradeMark Number",
      "Date Of Application",
      "State",
      "Country",
      "Filing Mode",
      "TradeMark Type",
      "Proprietor Name",
      "Attorney Name",
    ],
    ...searchedData.map(
      ({
        name,
        status,
        phone,
        email,
        trade_mark,
        date_of_application,
        state,
        country,
        filing_mode,
        trade_mark_type,
        proprietor_name,
        attorney_name,
      }) => [
        name,
        status,
        phone,
        email,
        trade_mark,
        date_of_application,
        state,
        country,
        filing_mode,
        trade_mark_type,
        proprietor_name,
        attorney_name,
      ]
    ),
  ];

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    setToken(localStorage.getItem("cms_token"));
    getUserSearchList();
  }, []);

  console.log(localStorage.getItem("trede_user_id"), "user_id");

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      // setIsVisible(false);
      setSuggestions([]);
    }
  };

  const getUserSearchList = async () => {
    // setLoading(true);
    try {
      const { data: response } = await axios
        .get(`${API_BASE_URL}/get-search-module-by-user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
          },
          params: {
            user_id: userId?.id,
          },
        })
        .then((res) => {
          console.log(res.data, "res");
          getDynamicList(res.data.data);
        });
      console.log(response.result, "response");
    } catch (error) {
      console.error(error, "error");
    }
    // setLoading(false);
  };

  const getDynamicList = (data) => {
    console.log(data);
    setClassList([]);
    setStatusList([]);
    setTmCategoryList([]);
    setTradeMarkList([]);
    setFilingModeList([]);
    data.map((item, i) => {
      if (item.name === "search_for") {
        console.log(item, i, "index");
        setClassList((prev) => [...prev, item]);
      }
      if (item.name === "Class") {
        console.log(item, i, "index");
        setStatusList((prev) => [...prev, item]);
      }
      if (item.name === "TM_Category") {
        console.log(item, i, "index");
        setTmCategoryList((prev) => [...prev, item]);
      }
      if (item.name === "TM_Type") {
        console.log(item, i, "index");
        setTradeMarkList((prev) => [...prev, item]);
      }
      if (item.name === "Filing_Mode") {
        console.log(item, i, "index");
        setFilingModeList((prev) => [...prev, item]);
      }
    });
  };

  const sampledata = [];
  classList?.forEach((item) => {
    sampledata?.push({
      value: item.search_field,
      label: item.search_field,
      disabled: item.status,
    });
  });

  const options = [
    { value: "word_mark", label: "Word Mark" },
    { value: "class_type", label: "Class Type" },
    { value: "attorney_name", label: "Attorney Name" },
    { value: "trademark_number", label: "Trademark Number" },
    { value: "proprietor_name", label: "Proprietor Name" },
  ];

  useEffect(() => {
    console.log("effet", trademark_info);
    sendData();
    if (trademark_info.length > 0) {
      sendData();
    } else {
      setSearcedData([]);
    }
  }, [pageApi, tradeMarkInfo, date]);

  useEffect(() => {
    // getStatusList();
    // getClassList();
    // getTradeMarkList();
    // getFillingModeList();
    // getTmCategoryList();
  }, []);

  const getStatusList = async () => {
    // setLoading(true);
    try {
      const { data: response } = await axios
        .get(`${API_BASE_URL}/unique-status`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
          },
        })
        .then((res) => {
          console.log(res.data, "res");
          setStatusList(res?.data?.data);
        });
      console.log(response.result, "response");
    } catch (error) {
      console.error(error.message);
    }
    // setLoading(false);
  };
  // const getClassList = async () => {
  //   // setLoading(true);
  //   try {
  //     const { data: response } = await axios
  //       .get(`${API_URl}/get-unique-class`, {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       })
  //       .then((res) => {
  //         console.log(res.data, "res");
  //         setClassList(res?.data);
  //       });
  //     console.log(response.result, "response");
  //   } catch (error) {
  //     console.error(error.message);
  //   }
  //   // setLoading(false);
  // };

  console.log(classList, "classlist");
  console.log(tradeMarkList, "tradeMarkList");
  console.log(fillingModeList, "fillingModeList");
  console.log(tmCategoryList, "tmCategoryList");
  console.log(statusList, "statusList");

  const getTradeMarkList = async () => {
    // setLoading(true);
    try {
      const { data: response } = await axios
        .get(`${API_BASE_URL}/unique-trade-mark-type`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
          },
        })
        .then((res) => {
          console.log(res.data, "res");
          setTradeMarkList(res?.data?.data);
        });
      console.log(response.result, "response");
    } catch (error) {
      console.error(error.message);
    }
    // setLoading(false);
  };

  const getFillingModeList = async () => {
    try {
      const { data: response } = await axios
        .get(`${API_BASE_URL}/unique-filing-mode`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
          },
        })
        .then((res) => {
          console.log(res.data, "fillingMode");
          setFilingModeList(res?.data?.data);
        });

      console.log(response.result, "response");
    } catch (error) {
      console.error(error.message);
    }
    // setLoading(false);
  };

  const getTmCategoryList = async () => {
    // setLoading(true);
    try {
      const { data: response } = await axios
        .get(`${API_BASE_URL}/unique-tm-category`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
          },
        })
        .then((res) => {
          console.log(res.data, "fillingMode");
          setTmCategoryList(res?.data.data);
        });
      console.log(response.result, "response");
    } catch (error) {
      console.error(error.message);
    }
    // setLoading(false);
  };

  const handleTradeMarkChange = async (e) => {
    setLoading(true);
    const { value, checked } = e?.target;
    const { trademark_info } = tradeMarkInfo;
    setPageApi(1);
    if (checked) {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        trademark_info: [...trademark_info, e.target.value],
      });
    } else {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        trademark_info: trademark_info?.filter((e) => e !== value),
      });
    }
    setLoading(false);
  };

  const handleStatusChange = (e) => {
    setLoading(true);
    const { value, checked } = e?.target;
    const { status } = tradeMarkInfo;
    setPageApi(1);

    if (checked) {
      console.log(tradeMarkInfo);
      setTradeMarkInfo({
        ...tradeMarkInfo,
        status: [...status, value],
      });
    } else {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        status: status?.filter((e) => e !== value),
      });
    }
    setLoading(false);
  };

  const handleFillingChange = (e) => {
    setLoading(true);
    const { value, checked } = e.target;
    const { filling_mode } = tradeMarkInfo;
    setPageApi(1);

    if (checked) {
      console.log(tradeMarkInfo);
      setTradeMarkInfo({
        ...tradeMarkInfo,
        filling_mode: [...filling_mode, value],
      });
    } else {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        filling_mode: filling_mode?.filter((e) => e !== value),
      });
    }
    setLoading(false);
  };

  const handleTmCategoryChange = (e) => {
    setLoading(true);
    const { value, checked } = e?.target;
    const { tm_category } = tradeMarkInfo;
    setPageApi(1);

    if (checked) {
      console.log(tradeMarkInfo);
      setTradeMarkInfo({
        ...tradeMarkInfo,
        tm_category: [...tm_category, value],
      });
    } else {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        tm_category: tm_category?.filter((e) => e !== value),
      });
    }
    setLoading(false);
  };

  const onDateChange = (e) => {
    setLoading(true);
    console.log(e.target.value, "etarget");
    setPageApi(1);

    if (e.target.name == "start_date") {
      setDate({
        ...date,
        start_date: e.target.value,
      });
    } else {
      setDate({
        ...date,
        end_date: e.target.value,
      });
    }
    setLoading(false);
  };

  const getDynamicParam = (selectvalue, e, tradeMarkInfo, date) => {
    console.log(selectvalue, e, "console");
    let params = {
      name: "",
      start_date: date.start_date,
      end_date: date.end_date,
      status: tradeMarkInfo?.status?.toString().replace(/\,/g, "#"),
      filing_mode: tradeMarkInfo?.filling_mode?.toString().replace(/\,/g, "#"),
      tm_category: tradeMarkInfo?.tm_category?.toString().replace(/\,/g, "#"),
      trade_mark_type: tradeMarkInfo?.trademark_info
        ?.toString()
        .replace(/\,/g, "#"),
      class_type: "",
      trademark_number: "",
      proprietor_name: "",
      attorney_name: "",
      page: pageApi,
      limit: "10",
      user_id: localStorage.getItem("trade_user_id"),
    };

    if (selectvalue == "Word Mark") {
      params = {
        name: e,

        start_date: date.start_date,
        end_date: date.end_date,
        status: tradeMarkInfo?.status?.toString().replace(/\,/g, "#"),
        filing_mode: tradeMarkInfo?.filling_mode
          ?.toString()
          .replace(/\,/g, "#"),
        tm_category: tradeMarkInfo?.tm_category?.toString().replace(/\,/g, "#"),
        trade_mark_type: tradeMarkInfo?.trademark_info
          ?.toString()
          .replace(/\,/g, "#"),
        class_type: "",
        trademark_number: "",
        proprietor_name: "",
        attorney_name: "",
        page: pageApi,
        limit: "10",
        user_id: localStorage.getItem("trade_user_id"),
      };
    }
    if (selectvalue == "Class Type") {
      params = {
        name: "",

        start_date: date.start_date,
        end_date: date.end_date,
        status: tradeMarkInfo?.status?.toString().replace(/\,/g, "#"),
        filing_mode: tradeMarkInfo?.filling_mode
          ?.toString()
          .replace(/\,/g, "#"),
        tm_category: tradeMarkInfo?.tm_category?.toString().replace(/\,/g, "#"),
        trade_mark_type: tradeMarkInfo?.trademark_info
          ?.toString()
          .replace(/\,/g, "#"),
        class_type: e,
        trademark_number: "",
        proprietor_name: "",
        attorney_name: "",
        page: pageApi,
        limit: "10",
        user_id: localStorage.getItem("trade_user_id"),
      };
    }
    if (selectvalue == "Proprietor Name") {
      params = {
        name: "",

        start_date: date.start_date,
        end_date: date.end_date,
        status: tradeMarkInfo?.status?.toString().replace(/\,/g, "#"),
        filing_mode: tradeMarkInfo?.filling_mode
          ?.toString()
          .replace(/\,/g, "#"),
        tm_category: tradeMarkInfo?.tm_category?.toString().replace(/\,/g, "#"),
        trade_mark_type: tradeMarkInfo?.trademark_info
          ?.toString()
          .replace(/\,/g, "#"),
        class_type: "",
        trademark_number: "",
        proprietor_name: e,
        attorney_name: "",
        page: pageApi,
        limit: "10",
        user_id: localStorage.getItem("trade_user_id"),
      };
    }
    if (selectvalue == "Attorney Name") {
      params = {
        name: "",

        start_date: date.start_date,
        end_date: date.end_date,
        status: tradeMarkInfo?.status?.toString().replace(/\,/g, "#"),
        filing_mode: tradeMarkInfo?.filling_mode
          ?.toString()
          .replace(/\,/g, "#"),
        tm_category: tradeMarkInfo?.tm_category?.toString().replace(/\,/g, "#"),
        trade_mark_type: tradeMarkInfo?.trademark_info
          ?.toString()
          .replace(/\,/g, "#"),
        class_type: "",
        trademark_number: "",
        proprietor_name: "",
        attorney_name: e,
        page: pageApi,
        limit: "10",
        user_id: localStorage.getItem("trade_user_id"),
      };
    }
    if (selectvalue == "Trademark Number") {
      params = {
        name: "",

        start_date: date.start_date,
        end_date: date.end_date,
        status: tradeMarkInfo?.status?.toString().replace(/\,/g, "#"),
        filing_mode: tradeMarkInfo?.filling_mode
          ?.toString()
          .replace(/\,/g, "#"),
        tm_category: tradeMarkInfo?.tm_category?.toString().replace(/\,/g, "#"),
        trade_mark_type: tradeMarkInfo?.trademark_info
          ?.toString()
          .replace(/\,/g, "#"),
        class_type: "",
        trademark_number: e,
        proprietor_name: "",
        attorney_name: "",
        page: pageApi,
        limit: "10",
        user_id: localStorage.getItem("trade_user_id"),
      };
    }

    return params;
  };

  const sendData = async () => {
    setLoading(true);
    setSuggestions([]);

    console.log(
      getDynamicParam(selectedOption, searchedValue, tradeMarkInfo, date),
      "dynamicparams"
    );
    console.log(searchInputRef, "searchInputRef");
    try {
      const params = {
        name: "",
        start_date: date.start_date,
        end_date: date.end_date,
        status: tradeMarkInfo?.status?.toString().replace(/\,/g, "#"),
        filing_mode: tradeMarkInfo?.filling_mode
          ?.toString()
          .replace(/\,/g, "#"),
        tm_category: tradeMarkInfo?.tm_category?.toString().replace(/\,/g, "#"),
        trade_mark_type: tradeMarkInfo?.trademark_info
          ?.toString()
          .replace(/\,/g, "#"),
        class_type: "",
        trademark_number: "",
        proprietor_name: "",
        attorney_name: "",
        page: pageApi,
        limit: "10",
        user_id: localStorage.getItem("trade_user_id"),
      };
      const response = await axios
        .get(`${API_BASE_URL}/search`, {
          params: getDynamicParam(
            selectedOption,
            searchedValue,
            tradeMarkInfo,
            date
          ),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
          },
        })
        .then((res) => {
          console.log(res.data, "resiii");
          console.log(BASE_URL, "resii");
          setExcelLink(res?.data?.excel_link);
          setSearcedData(res?.data?.result);
          const totalPageCount = Math.ceil(res?.data?.total / 10);
          setPageSize(totalPageCount);
          console.log(totalPageCount, "total");
        });
    } catch (error) {
      console.log(error);
      // console.log(tradeMarkInfo.tm_category.toString().replace(/\,/g, '#'), tradeMarkInfo.filling_mode.toString().replace(/\,/g, '#'))
    }
    setLoading(false);
  };

  const selectChange = (e) => {
    console.log(e.value, "e");
    setSearchedvalue("");
    setSelectedOption(e.value);
  };

  const onSearchData = async (e) => {
    // console.log(selectedOption,"selecttdopttions")
    setLoad(true);
    setSearchedvalue(e.target.value);
    // setPageApi(1);
    console.log(e);
    var params = {
      word_mark: "",
      class_type: "",
      proprietor_name: "",
      attorney_name: "",
      trademark_number: "",
    };

    if (selectedOption == "Word Mark") {
      console.log(selectedOption, "selectdd");
      params = {
        word_mark: e.target.value,
        class_type: "",
        proprietor_name: "",
        attorney_name: "",
        trademark_number: "",
      };
    }

    if (selectedOption == "Class Type") {
      console.log(selectedOption, "selectdd");
      // console.log(e.target.value)
      params = {
        word_mark: "",
        class_type: e.target.value,
        proprietor_name: "",
        attorney_name: "",
        trademark_number: "",
      };
    }
    if (selectedOption == "Proprietor Name") {
      params = {
        word_mark: "",
        class_type: "",
        proprietor_name: e.target.value,
        attorney_name: "",
        trademark_number: "",
      };
    }
    if (selectedOption == "Attorney Name") {
      params = {
        word_mark: "",
        class_type: "",
        proprietor_name: "",
        attorney_name: e.target.value,
        trademark_number: "",
      };
    }
    if (selectedOption == "Trademark Number") {
      params = {
        word_mark: "",
        class_type: "",
        proprietor_name: "",
        attorney_name: "",
        trademark_number: e.target.value,
      };
    }
    if (e.target.value !== "") {
      try {
        setLoad(true);
        await axios
          .get(`${API_BASE_URL}/auto-suggestion`, {
            params: params,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("cms_token")}`,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log(res.data, "resiii");
            setSuggestions(res?.data?.data);
            console.log(params, "paas");
            setLoad(false);
          });
      } catch (error) {
        console.log(error);
        setLoad(false);
        // console.log(tradeMarkInfo.tm_category.toString().replace(/\,/g, '#'), tradeMarkInfo.filling_mode.toString().replace(/\,/g, '#'))
      }
    } else {
      setSuggestions([]);
      setLoad(false);
    }
  };

  const enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      //Do stuff in here
      setPageApi(1);
      sendData();
    }
  };

  return (
    <div id="trade-data" className="main-content ">
      <div className="page-content">
        <div className="container-fluid">
          
              <div class="app-main__outer">
                <div class="app-main__inner">
                  <div class="app-page-title">
                    <div class="d-flex">
                      <div class="col-md-2 ps-0 pe-1">
                        <Select
                          placeholder="Word Mark"
                          defaultValue={selectedOption}
                          onChange={(e) => selectChange(e)}
                          options={sampledata}
                          isSearchable={false}
                          isOptionDisabled={(option) => !option.disabled}
                        />
                      </div>
                      <div
                        class="col-md-5 ps-0"
                        style={{ zIndex: 1, position: "relative" }}
                      >
                        <input
                          type="text"
                          onChange={onSearchData}
                          value={searchedValue}
                          onKeyDown={enterPressed}
                          class="form-control"
                          placeholder="Search here....."
                        />
                        {load && (
                          <i class="fa fa-spinner fa-spin spin-class"></i>
                        )}
                        {isVisible &&
                          (!suggestions?.length ? null : (
                            <>
                              <div
                                class="outer-div "
                                ref={wrapperRef}
                                style={{ maxHeight: "165px", overflow: "auto" }}
                              >
                                {suggestions.map((item) => {
                                  return (
                                    <div
                                      className="mail"
                                      style={{
                                        border: "1px solid",
                                        backgroundColor: "#ffffff",
                                        maxHeight: 100,
                                      }}
                                      key={item.id}
                                    >
                                      <div
                                        className="from"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Link
                                          to={`/detail?id=${item?.trade_mark}`}
                                        >
                                          {item?.name}
                                        </Link>
                                        {/* <img
                                    src={`${item?.logo}`}
                                    class="img-fluid "
                                    alt="logo"
                                    height={25}
                                    width={25}
                                  /> */}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          ))}

                        {/* <input type="text" onChange={onSearchData} value={searchedValue} class="form-control" placeholder="Search here....." /> */}
                      </div>
                      <div class="col-md-3 ps-0">
                        <button
                          type="button"
                          onClick={() => {
                            setPageApi(1);
                            sendData();
                          }}
                          class="btn btn-primary search"
                        >
                          {/* {load && <i class="fa fa-spinner fa-spin"></i>} */}
                          Search <i class="fa fa-search ms-1"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-xl-9">
                      <div
                        class="card d-flex alingn-center justify-content-center"
                        style={{ minHeight: 400 }}
                      >
                        {loading ? (
                          <div class=" ">
                            <Loader />
                          </div>
                        ) : searchedData?.length === 0 ? (
                          <div className="text-center">
                            <h2 style={{ textAlign: "center", marginTop: 20 }}>
                              Search Data
                            </h2>
                            <img
                              src={`${IMG_URl}/img/no_data.jpg`}
                              class="img-fluid company-logo"
                              alt="logo"
                              width={450}
                            />
                          </div>
                        ) : (
                          <>
                            <div class="top-card">
                              <CSVLink
                                className="btn btn-outline-primary btn-sm"
                                filename="my-file.csv"
                                data={csvData}
                              >
                                {/* <a
                          // type="button"
                          class="btn btn-outline-primary btn-sm"
                          href={excelLink}
                        > */}
                                Export All
                                {/* </a> */}
                              </CSVLink>
                            </div>
                            <div class="card-body">
                              {searchedData?.map((item) => {
                                return (
                                  <>
                                    <div class="information">
                                      <h4 className="ms-3">
                                        <Link
                                          target="_blank"
                                          to={`/detail?id=${item?.trade_mark}`}
                                        >
                                          {item?.name}
                                        </Link>
                                      </h4>
                                      {item?.logo_status ? (
                                        <>
                                          <img
                                            src={`${item?.logo}`}
                                            class="img-fluid company-logo"
                                            alt="logo"
                                            height={110}
                                          />
                                        </>
                                      ) : // <>
                                      //   <img
                                      //     src={`${IMG_URl}/img/not-found.png`}
                                      //     class="img-fluid company-logo"
                                      //     alt="logo"
                                      //     height={110}
                                      //   />
                                      // </>
                                      null}
                                      {/* <img
                                      src={`${item?.logo}` }
                                      class="img-fluid company-logo"
                                      alt='logo'

                                    /> */}
                                      <div class="info-inner">
                                        <div class="col-md-3 light-color">
                                          Application Number :
                                        </div>
                                        <div class="col-md-9">
                                          {item?.trade_mark}
                                        </div>
                                      </div>
                                      <div class="info-inner">
                                        <div class="col-md-3 light-color">
                                          Class :
                                        </div>
                                        <div class="col-md-9">
                                          {item?.class}
                                        </div>
                                      </div>
                                      <div class="info-inner">
                                        <div class="col-md-3 light-color">
                                          Status :
                                        </div>
                                        <div class="col-md-9">
                                          {item?.status}
                                        </div>
                                      </div>
                                      <div class="info-inner">
                                        <div class="col-md-3 light-color">
                                          Application Date :
                                        </div>
                                        <div class="col-md-9">
                                          {item?.date_of_application}
                                        </div>
                                      </div>
                                      {/* <div class="info-inner">
                                      <div class="col-md-3 light-color">
                                        Class Detial :
                                      </div>
                                      <div class="col-md-9">
                                        Checkboxes can utilize the
                                        :indeterminate pseudo class when
                                        manually set via JavaScript (there is no
                                        available HTML attribute for specifying
                                        it).
                                      </div>
                                    </div> */}
                                    </div>
                                  </>
                                );
                              })}
                              {!loading && pageSize !== 1 && (
                                <Pagination
                                  count={pageSize}
                                  page={pageApi}
                                  onChange={(e, value) => {
                                    console.log(value, "vslue");
                                    setPageApi(value);
                                  }}
                                  color="primary"
                                />
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6 col-xl-3">
                      <div class="card">
                        <div class="card-header bg-dark text-white">
                          <p class="mb-0">Filter by Trade Mark</p>
                        </div>
                        <div class="card-body">
                          {tradeMarkList?.map((item, index) => {
                            return (
                              <>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name="trademark_info"
                                    value={
                                      item.status ? item?.search_field : null
                                    }
                                    id={`trademark-checkbox-${index}`}
                                    onChange={handleTradeMarkChange}
                                    disabled={!item?.status}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    {item?.search_field}
                                  </label>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                      <div class="card mt-3">
                        <div class="card-header bg-dark text-white">
                          <p class="mb-0">Filter by Date</p>
                        </div>
                        <div class="card-body">
                          <div>
                            <label>Start Date</label>
                            <input
                              className="form-control"
                              style={{ height: "29px" }}
                              type="date"
                              name="start_date"
                              onChange={onDateChange}
                              ref={startDateInputRef}
                            />
                          </div>
                          <div className="mt-2">
                            <label>End Date</label>
                            <input
                              style={{ height: "29px" }}
                              className="form-control"
                              type="date"
                              name="end_date"
                              onChange={onDateChange}
                              ref={endDateInputRef}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="card mt-3">
                        <div class="card-header bg-dark text-white">
                          <p class="mb-0">Filter by Status</p>
                        </div>
                        <div class="card-body">
                          {statusList?.map((item, index) => {
                            return (
                              <>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value={
                                      item.status ? item?.search_field : null
                                    }
                                    name="status"
                                    id={`trademark-checkbox-${index}`}
                                    onChange={handleStatusChange}
                                    disabled={!item.status}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    {item?.search_field}
                                  </label>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                      <div class="card mt-3">
                        <div class="card-header bg-dark text-white">
                          <p class="mb-0">Filter by Filling Mode</p>
                        </div>
                        <div class="card-body">
                          {fillingModeList?.map((item, index) => {
                            return (
                              <>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name="filling_mode"
                                    value={
                                      item?.status ? item?.search_field : null
                                    }
                                    id={`filling-checkbox-${index}`}
                                    onChange={handleFillingChange}
                                    disabled={!item.status}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    {item?.search_field}
                                  </label>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                      <div class="card mt-3">
                        <div class="card-header bg-dark text-white">
                          <p class="mb-0">Filter by Tm Category</p>
                        </div>
                        <div class="card-body">
                          {tmCategoryList?.map((item, index) => {
                            return (
                              <>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name="tm_category"
                                    value={
                                      item?.status ? item?.search_field : null
                                    }
                                    id={`tm_category-checkbox-${index}`}
                                    onChange={handleTmCategoryChange}
                                    disabled={!item.status}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    {item?.search_field}
                                  </label>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </div>
  );
};

export default Manage_Search;
