import React, { useState, useEffect, useRef } from "react";
import Loader from "../Loader/loader";
import moment from "moment/moment";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";

const Profile = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    password:"",
  
  });
  const {
    name,
    email,
    mobile,
    password
  } = formData;
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
const [userId, setUserId] = useState('');
  const [nameError, setNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");

  const [plans, setPlans] = useState([]);

  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  
  let hasError = false;

  useEffect(() => {
    let id = JSON.parse(localStorage.getItem('trade_user_data'))
  console.log(id,"id")
  setUserId(id?.id)
    setToken(localStorage.getItem("cms_token"));
        getUserDetail(id?.id);
    
  }, []);

  const getUserDetail = async (id) => {
    setIsLoading(true);
    const res = await axios.get(`${API_BASE_URL}/get-cms-user-details`, {
      params: { id: id },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(res.data, 'res') 
    if (res.data.status == 200) {
      console.log(res.data.data, "res.data.result.page_name");
      setFormData({ 
        ...formData,
        name: res.data.data.name,
        email: res.data.data.email,
        mobile: res.data.data.mobile,
       password:res.data.data.password,
      });
      setIsLoading(false);
    }
  };

 
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData, "form");

    if (!formData.name) {
      setNameError("Please enter name");
      hasError = true;
    } else {
      setNameError("");
    }
    if (!formData.mobile) {
      setMobileError("Please enter mobile ");
      hasError = true;
    } else {
      setMobileError("");
    }
    if (!formData.password) {
        setPasswordError("Please enter Password");
        hasError = true;
      } else {
        setPasswordError("");
      }
     
    if (!hasError) {
      let form ={
        id: userId,
        name: name,
        mobile: mobile, 
        password:password,
      }
      axios
        .post(
          `${API_BASE_URL}/update-cms-user-details`,form,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            toast.success("Updated successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.msg)
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    console.log(event.target, "mame");
    console.log(name, value, "mame");
    console.log(formData, "valueeeeeeee");

    if (name === "subscription_end_date") {
      setFormData({ ...formData, [name]: moment(value,'yyyy-mm-dd')});
    }
  };

  const selectStyles = (errorFieldName) => ({
    control: (provided) => ({
      ...provided,
      borderColor: errors[errorFieldName] ? "#dc3545" : provided.borderColor,
      boxShadow: errors[errorFieldName]
        ? "0 0 0 0.2rem rgba(220, 53, 69, 0.25)"
        : provided.boxShadow,
      minHeight: 22,
      height: 33,
    }),
  });

  const handleSelect = (fieldName, selectedOption) => {
    // setSelectedOption(selectedOption);
    console.log(selectedOption, "opton");
    setFormData({ ...formData, [fieldName]: selectedOption.value });
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));

    if(fieldName == 'subscription_type'){
        if(selectedOption.value == 'free_trial'){
            setFormData({...formData, plan_type:'free_trial', plan_id:'1',subscription_end_date:moment().add(5, 'd').format('YYYY-MM-DD'),[fieldName]: selectedOption.value})
        }
    }
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Profile </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/cms/manageseopage"}>Profile</Link>
                      </li>
                      {/* <li className="breadcrumb-item active">
                        Add Customer
                      </li> */}
                    </ol>
                  </div>
                </div>
                
              </div>
            </div>
            {isLoading ? (
                      <Loader />
                    ) : (
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Information</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Name
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="name"
                                  type="text"
                                  value={name}
                                  className={
                                    nameError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter page url..."
                                  onChange={handleInputChange}
                                />
                                {nameError && (
                                  <div className="text-danger">
                                    {nameError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Email
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="email"
                                  type="text"
                                  value={email}
                                  disabled={true}
                                  className={
                                    emailError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Email..."
                                //   onChange={handleInputChange}
                                />
                                {/* {emailError && (
                                  <div className="text-danger">
                                    {emailError}
                                  </div>
                                )} */}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Password
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="password"
                                  type="text"
                                  value={password}
                                  className={
                                    emailError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter Password..."
                                  onChange={handleInputChange}
                                />
                                {passwordError && (
                                  <div className="text-danger">
                                    {passwordError}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-group row mb-4">
                              <label
                                for="taskname"
                                className="col-form-label col-lg-2"
                              >
                                Mobile
                              </label>
                              <div className="col-lg-10">
                                <input
                                  id="taskname"
                                  name="mobile"
                                  type="text"
                                  value={mobile}
                                  className={
                                    mobileError
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                  placeholder="Enter meta keywords..."
                                  onChange={handleInputChange}
                                />
                                {mobileError && (
                                  <div className="text-danger">
                                    {mobileError}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10">
                            <button type="submit" className="btn btn-primary">
                              Update Admin
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
