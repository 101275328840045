import React, { useState } from "react";
import Modal from "react-modal";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(255, 255, 255, 0.75)",
  },
};

const EnquiryModal = ({
  token,
  modalIsOpen2,
  setIsOpen2,
  id,
  setStatusId,
  statusCode,
  setStatusCode,
  setModalStatus,
  modalStatus,
}) => {
  const [message, setMessage] = useState("");

  function closeModal() {
    setIsOpen2(false);
    // navigate("/");
  }

  const handleConfirm = (id,statusCode) => {
    let data = {
      id: id,
      status: statusCode,
      remarks: message, // Send the user's message
    };
    axios
      .post(`${API_BASE_URL}/is-positive-enquiry`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
            if(statusCode === 1){
              toast.success("Enquiry Added To Positive List successfully");
            }else{
              toast.success("Enquiry Added To Negative List successfully");
            }
          setIsOpen2(false);
          setStatusId();
          setStatusCode();
          setModalStatus(!modalStatus);
        }
      })
      .catch((error) => {
        setStatusId();
        setStatusCode();
        setIsOpen2(false);
        setModalStatus(!modalStatus);
        console.error("Error sending message:", error);
      });
  };

  return (
    <div className="buy-plan">
      <Modal
        isOpen={modalIsOpen2}
        // onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        className="buy-plan"
      >
        <div className="row p-0" style={{ zIndex: "999" }} id="apply-modal">
          <div className=" mt-1">
            <button
              type="button"
              className="btn-close"
              onClick={closeModal}
            ></button>
          </div>

          <div className=" mt-2">
            <div>
              <textarea
                placeholder="Enter your message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{
                  width: "90%",
                  height: "100px",
                  border: "1px solid #000",
                  borderRadius: "10px",
                  padding: "10px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  resize: "none",
                }}
              />
              <div className="d-flex justify-content-center align-items-center position-absolute bottom-0 pb-3">
                <button
                  type="button"
                  className="btn btn-submit w-50"
                  onClick={() => handleConfirm(id,statusCode)}
                >
                 {id} Add Money
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <button onClick={handleConfirm} disabled={isLoading}>
              {isLoading ? "Processing..." : "Confirm"}
            </button>
            <button onClick={() => setShowModal(false)}>Cancel</button> */}
      </Modal>
    </div>
  );
};

export default EnquiryModal;
